import gql from 'graphql-tag';
import { graphql } from 'react-apollo';
import { GET_ROLE_LIST } from '../../../../configuration/user/userRole/API';

const GET_DETAILS = gql`
  query Incentive($incentiveId: Int!) {
    incentive(id: $incentiveId) {
      id
      year
      month
      role_id
      role
      created_at
      updated_at
      measures {
        id
        incentive_id
        title
        created_at
        updated_at
        ranges {
          id
          measure_id
          incentive_id
          min_value
          min_value_operator
          max_value
          max_value_operator
          incentive_amount
          min_value_unit
          max_value_unit
          created_at
          updated_at
        }
      }
    }
  }
`;
const CREATE_INCENTIVE = graphql(
  gql`
    mutation CreateIncentive($input: IncentiveInput!) {
      createIncentive(input: $input) {
        id
        year
        month
        role_id
        role
        created_at
        updated_at
        measures {
          id
          incentive_id
          title
          created_at
          updated_at
          ranges {
            id
            measure_id
            incentive_id
            min_value
            min_value_operator
            max_value
            max_value_operator
            incentive_amount
            min_value_unit
            max_value_unit
            created_at
            updated_at
          }
        }
      }
    }
  `,
  {
    props: ({ mutate }: any) => ({
      createIncentive: (variables: any) =>
        mutate({
          variables,
        }),
    }),
  },
);

const UPDATE_INCENTIVE = graphql(
  gql`
    mutation UpdateIncentive($updateIncentiveId: Int!, $input: IncentiveUpdateInput!) {
      updateIncentive(id: $updateIncentiveId, input: $input) {
        id
        year
        month
        role_id
        role
        created_at
        updated_at
        measures {
          id
          incentive_id
          title
          created_at
          updated_at
          ranges {
            id
            measure_id
            incentive_id
            min_value
            min_value_operator
            max_value
            max_value_operator
            incentive_amount
            min_value_unit
            max_value_unit
            created_at
            updated_at
          }
        }
      }
    }
  `,
  {
    props: ({ mutate }: any) => ({
      updateIncentive: (variables: any) =>
        mutate({
          variables,
        }),
    }),
  },
);

const query = {
  getUserRole: GET_ROLE_LIST,
  getIncentiveDetails: GET_DETAILS,
};

const mutation = { createIncentive: CREATE_INCENTIVE, updateIncentive: UPDATE_INCENTIVE };

export { query, mutation, CREATE_INCENTIVE, UPDATE_INCENTIVE };
