import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Row } from 'react-flexbox-grid';
import { Input } from '../../../../../components';
import { CheckBox, menuAction } from '../../../../../v4/components';
import { Col } from '../../../../../components/Grid';
import { dataProps } from '../../../../../data/enums/Component';
import { fixedFloatAndCommas } from '../../../../../utils/conversion';
import { EVENT_OPERATION } from '../../../../../data/enums/EventOperation';
import withErrorAndLoadingScreen from '../../../../../utils/composition/withLoading';
import { normalPresentor } from '../../../../../utils/date';
import Popover from '../../../../../components/Popover/Popover';
import { ImageColumn } from '../../../../../utils/tableUtils';
import withImageSlider from '../../../../../utils/composition/withImageSlider';

const propTypes = {
  data: dataProps.value,
  onCheckBoxClick: PropTypes.func,
  onIconClick: PropTypes.func,
  onButtonClick: PropTypes.func,
  skuLines: PropTypes.instanceOf(Array),
  breadCrumb: PropTypes.instanceOf(Array),
  checkedList: PropTypes.instanceOf(Array),
  checkBoxStatus: PropTypes.func,
  totalOrders: PropTypes.number,
  onPrimaryCheckBoxClick: PropTypes.func,
  onSecondaryCheckBoxClick: PropTypes.func,
  handleSliderOpen: PropTypes.func,
  priceDetails: PropTypes.instanceOf(Object),
  viewType: PropTypes.string,
  enableErrorDisplay: PropTypes.bool,
  refsObj: PropTypes.instanceOf(Object),
};

const defaultProps = {
  checkedList: [],
  breadCrumb: [],
  onIconClick: () => null,
  onButtonClick: () => null,
  onCheckBoxClick: () => null,
  data: dataProps.defaultValue,
  skuLines: [],
  checkBoxStatus: () => null,
  totalOrders: 0,
  onPrimaryCheckBoxClick: () => null,
  onSecondaryCheckBoxClick: () => null,
  handleSliderOpen: () => null,
  viewType: '',
  priceDetails: {},
  enableErrorDisplay: false,
  refsObj: {},
};

const menuConfigList = [
  {
    title: 'Edit',
    icon: 'pencil',
    permissionDerivedBy: 'update',
    type: EVENT_OPERATION.UPDATE,
  },
];

const TableView = ({ ...props }) => {
  const {
    isOldBill,
    skuLines,
    permission,
    onIconClick,
    checkedList,
    checkBoxStatus,
    totalOrders,
    handleSliderOpen,
    onPrimaryCheckBoxClick,
    onSecondaryCheckBoxClick,
  } = props;
  return (
    <TableViewStyled>
      {checkedList.length > 0 && (
        <div className="selected-status">
          <div className="content-center">
            <div>
              <CheckBox checked disabled />
              <p className="text">
                <span>{checkedList.length}</span>
                of
                <span>{totalOrders}</span>
                items selected
              </p>
            </div>
          </div>
        </div>
      )}
      <div className="table-wrap srn-details">
        <table>
          <thead className={skuLines?.filter(a => a?.isBlocked)?.length > 0 ? 'audit-blocked' : ''}>
            <tr className={permission.update ? '' : 'disabled'}>
              {skuLines.length && (
                <th>
                  <CheckBox
                    checked={totalOrders === checkedList.length && totalOrders !== 0}
                    onCheckBoxClick={e => onPrimaryCheckBoxClick(e)}
                    disabled={skuLines?.filter(a => a?.isBlocked)?.length > 0}
                  />
                </th>
              )}
              <th>S.No.</th>
              <th>SKU</th>
              <th>Batch</th>
              <th>Stock Type</th>
              <th>MFG Date</th>
              <th>EXP Date</th>
              <th className="text-right">Quantity</th>
              <th className="text-right">Return Quantity</th>
              <th className="text-right">Rate</th>
              <th className="text-right">Amount</th>
              <th className="text-right">Discount</th>
              <th className="text-right">Net Amount</th>
              {isOldBill && <th />}
            </tr>
          </thead>
          <tbody>
            {skuLines.map((order, key) => (
              <tr key={order.id} className={permission.update ? '' : 'disabled'}>
                <td>
                  <CheckBox
                    checked={checkBoxStatus(order.id)}
                    disabled
                    onCheckBoxClick={e => onSecondaryCheckBoxClick(order, e.currentTarget.checked)}
                  />
                </td>
                <td>{key + 1}</td>
                <td>{order.SKU && order.SKU.title}</td>
                <td>{order.SKUBatch && order.SKUBatch.batchName}</td>
                <td>{order.stockType}</td>
                <td className="text-center">
                  {order.SKUBatch
                    ? order.SKUBatch.manufactureDate
                      ? normalPresentor(order.SKUBatch.manufactureDate) === 'Invalid date'
                        ? '-'
                        : normalPresentor(order.SKUBatch.manufactureDate)
                      : null
                    : null}
                </td>
                <td className="text-center">
                  {order.SKUBatch
                    ? order.SKUBatch.expiryDate
                      ? normalPresentor(order.SKUBatch.expiryDate) === 'Invalid date'
                        ? '-'
                        : normalPresentor(order.SKUBatch.expiryDate)
                      : null
                    : null}
                </td>

                <td className="text-right">{order.quantity}</td>
                <td className="text-right" key={`pop-over-${order.id}`} onClick={event => event.stopPropagation()}>
                  {order.returnQuantity}
                </td>
                <td className="text-right">{order.amountDetails && order.amountDetails.rate}</td>
                <td className="text-right">
                  {fixedFloatAndCommas(
                    (order.amountDetails &&
                      (order.amountDetails.amount || order.amountDetails.rate * order.returnQuantity || 0)) ||
                      0,
                  )}
                </td>
                <td className="text-right">
                  {fixedFloatAndCommas(
                    (order.amountDetails &&
                      Number(order.amountDetails.promotionDiscount) + Number(order.amountDetails.topUpDiscount)) ||
                      0,
                  )}
                </td>
                <td className="text-right">{fixedFloatAndCommas(order.amountDetails.subTotal)}</td>

                {isOldBill && (
                  <td className="text-right simple-popup-actions" onClick={e => e.stopPropagation()}>
                    {menuAction(menuConfigList, { menuIcon: 'ellipsis-v' }, onIconClick, order, { update: true })}
                  </td>
                )}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </TableViewStyled>
  );
};

TableView.propTypes = propTypes;

TableView.defaultProps = defaultProps;

const TableViewWithImageSlider = withImageSlider(TableView);

const TableViewWithErrorAndLoading = withErrorAndLoadingScreen(TableViewWithImageSlider);

const TotalView = ({ ...props }) => {
  const { refsObj, priceDetails, onInputChange, isOldBill } = props;

  return (
    <>
      <div className="grn-sku-update">
        <div className="grn-sku-update-inner">
          <Row>
            <Col md={6}>
              <span>Sub Total</span>
            </Col>
            <Col md={6}>
              <div>{fixedFloatAndCommas(priceDetails.subTotal)}</div>
            </Col>
            <Col md={6} className="mt-5">
              <span>Bill Discount</span>
            </Col>
            <Col md={6}>
              <Input
                name="billDiscount"
                type="number"
                enableValidation
                rule="isFloat"
                ref={ref => (refsObj.billDiscount = ref)}
                argument={{
                  min: 0,
                  max: (priceDetails.subTotal || 0) - (priceDetails.tradeDiscount || 0),
                }}
                value={priceDetails.billDiscount || 0}
                onChange={event => onInputChange(event)}
                disabled={!isOldBill}
              />
            </Col>
            <Col md={6} className="mt-5">
              <span>Trade Discount</span>
            </Col>
            <Col md={6}>
              <Input
                name="tradeDiscount"
                type="number"
                enableValidation
                rule="isFloat"
                argument={{
                  min: 0,
                  max: (priceDetails.subTotal || 0) - (priceDetails.billDiscount || 0),
                }}
                ref={ref => (refsObj.tradeDiscount = ref)}
                value={priceDetails.tradeDiscount || 0}
                onChange={event => onInputChange(event)}
                disabled={!isOldBill}
              />
            </Col>
            <Col md={6}>
              <span>Taxable Amount</span>
            </Col>
            <Col md={6}>
              <div> {fixedFloatAndCommas(priceDetails.taxableAmount)} </div>
            </Col>
            <Col md={6}>
              <span>VAT</span>
            </Col>
            <Col md={6}>
              <div>{fixedFloatAndCommas(priceDetails.taxAmount)}</div>
            </Col>
          </Row>
        </div>
      </div>
      <div className="grn-sku-update-total">
        <span>Total</span>
        <div>{fixedFloatAndCommas(priceDetails.netAmount)}</div>
      </div>
    </>
  );
};

export { TableViewWithErrorAndLoading as TableView, TotalView };

TotalView.propTypes = propTypes;

TotalView.defaultProps = defaultProps;

export default TableViewWithErrorAndLoading;

import styled from 'styled-components';

export const TableViewStyled = styled.div`
  .audit-blocked {
    &:after {
      content: 'Audit is still pending for some SKUs of this bill.';
      top: 100%;
      left: 5%;
    }
  }
`;
