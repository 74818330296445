import EVENT_OPERATION from '../../../../../data/enums/EventOperation';
import { CheckBox } from '../../../../../v4/components';
import Options from '../../../../../v4/components/Options';
import { tableHeaderWithCheckBoxSort } from '../../NewComponent/TableSort';

const labelMappings = {
  checkbox: "checkbox",
  id: 'id',
  userGroup: 'userGroup',
  month: 'month',
  totalIncentiveAmount: 'totalIncentiveAmount',
  options: 'options',
};

const labelConfig = [
  {
    id: 1,
    label: labelMappings.checkbox,
    title: "",
    show: true,
    align: "text-left w-60",
    classNames: "w-60",
  },
  {
    id: 1,
    label: labelMappings.userGroup,
    title: 'User Group',
    show: true,
    align: 'text-left',
  },
  {
    id: 2,
    label: labelMappings.month,
    title: 'Month',
    show: true,
    align: 'text-left',
  },
  {
    id: 3,
    label: labelMappings.totalIncentiveAmount,
    title: 'Total Incentive Amount',
    show: true,
    align: 'text-left',
    classNames: "text-right"
  },
  {
    id: 7,
    label: labelMappings.options,
    title: '',
    show: true,
    align: 'text-left',
  },
];

export const TableHeader = ({ dataListLength, checkedListLength, primaryCheckboxHandler, permission, handleTableSorting, sort }: any) =>
  tableHeaderWithCheckBoxSort({
    dataListLength,
    checkedListLength,
    primaryCheckboxHandler,
    handleTableSorting,
    permission: permission?.update,
    sort,
    labelConfig,
    labelMappings,
  });

export const returnTableBody = (secondaryCheckboxHandler: any, checkedList: any, data: any, permission: any, handleIconClick: any, showOptionButton: any) => {
  const table: { [key: string]: any } = {};
  table[labelMappings.checkbox] = permission?.delete && (
    <td
      key={`user-${data.id}-${labelMappings.checkbox}`}
      onClick={(e) => e.stopPropagation()}
      className="w-60"
    >
      <CheckBox
        checked={checkedList?.includes?.(data?.id)}
        onCheckBoxClick={() => secondaryCheckboxHandler(data?.id)}
      />
    </td>
  );
  table[labelMappings.userGroup] = <td key={`${data.id}-${labelMappings.userGroup}`}> {data?.userGroup} </td>;
  table[labelMappings.month] = <td key={`${data.id}-${labelMappings.month}`}> {data?.month} </td>;
  table[labelMappings.totalIncentiveAmount] = (
    <td key={`${data.totalIncentiveAmount}-${labelMappings.totalIncentiveAmount}`} className="text-right">
      {' '}
      {data?.totalIncentive}{' '}
    </td>
  );

  table[labelMappings.options] = (
    <td
      key={`${data.id}-${labelMappings.options}`}
      className={`text-right simple-popup-actions ${permission?.update && showOptionButton ? "" : "disabled"
        }`}
      onClick={(e) => e.stopPropagation()}
    >
      <Options
        menuConfig={[
          {
            title: "Delete",
            icon: "trash",
            onClick: () => handleIconClick?.(EVENT_OPERATION.DELETE, data),
            permission: permission?.delete,
          },
        ]}
      />
    </td>
  );
  return table;
};

export const TableBody = ({ handleTableRowClick,
  secondaryCheckboxHandler,
  checkedList,
  data,
  permission,
  handleIconClick,
  showOptionButton }: any) => {
  const tableBody = returnTableBody(secondaryCheckboxHandler,
    checkedList,
    data,
    permission,
    handleIconClick,
    showOptionButton);
  return (
    <tr key={`user-account-${data.id}`} style={{ cursor: "pointer" }} onClick={() => {
      handleTableRowClick?.(data.id);
    }}>{labelConfig.map(label => (label.show ? tableBody[label.label] : null))}</tr>
  );
};
