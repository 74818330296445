import gql from 'graphql-tag';
import { graphql } from 'react-apollo';

const GET_INCENTIVES = gql`
  query Incentives($filter: FilterInput, $limit: Int, $offset: Int) {
    incentives(filter: $filter, limit: $limit, offset: $offset) {
      count
      rows {
        id
        year
        month
        role_id
        role
        created_at
        updated_at
        measures {
          id
          incentive_id
          title
          created_at
          updated_at
          ranges {
            id
            measure_id
            incentive_id
            min_value
            min_value_operator
            max_value
            max_value_operator
            incentive_amount
            min_value_unit
            max_value_unit
            created_at
            updated_at
          }
        }
      }
    }
  }
`;

const UPDATE_USERS = graphql(
  gql`
    mutation Mutation($input: UserStatusInput) {
      updateUserStatus(input: $input) {
        active
        id
      }
    }
  `,
  {
    props: ({ mutate }: any) => ({
      updateUserStatus: (variables: any) =>
        mutate({
          variables,
        }),
    }),
  },
);

const DELETE_INCENTIVE = graphql(
  gql`
    mutation DeleteIncentive($ids: [Int!]) {
      deleteIncentive(ids: $ids)
    }
  `,
  {
    props: ({ mutate }: any) => ({
      deleteIncentive: (variables: any) =>
        mutate({
          variables,
        }),
    }),
  },
);

const DOWNLOAD_USER_REPORT = graphql(
  gql`
    mutation DownloadReport($input: fileDownloadInput!) {
      downloadReport(input: $input) {
        file {
          path
          name
        }
      }
    }
  `,
  {
    props: ({ mutate }) => ({
      downloadReport: (variables: any) =>
        mutate({
          variables,
        }),
    }),
  },
);

const query = {
  getIncentivesListApi: GET_INCENTIVES,
};

const mutation = {
  updateUserStatus: UPDATE_USERS,
  downloadReport: DOWNLOAD_USER_REPORT,
  deleteIncentive: DELETE_INCENTIVE,
};

export { UPDATE_USERS, DOWNLOAD_USER_REPORT, DELETE_INCENTIVE };

export { query, mutation };
