import React, { ChangeEvent } from "react";
import styled from "styled-components";
import { CheckBox, Icon } from '../../../../v4/components';

const findLabelObj = (sort: any[], label: any) => {
  const sortObj = sort?.find?.((a: any) => a?.sortBy === label);
  return sortObj;
};
interface sortParams {
  sortBy: string;
  order: string;
  dataType: string;
}
export const tableData = (
  label: any,
  sortParam: sortParams[] = [],
  handleTableSorting?: any
) => {
  let sort: any[] = [...sortParam];
  const getNewSortingOrder = (currentOrder: any) => {
    if (currentOrder === SORTING?.NO_SORT) {
      return SORTING?.ASC;
    }
    if (currentOrder === SORTING?.ASC) {
      return SORTING?.DESC;
    }
    return SORTING?.NO_SORT;
  };
  const handleSort = (label: any) => {
    const sortObj = findLabelObj(sort, label?.sortLabel);
    const currentOrder = sortObj?.order || SORTING.NO_SORT;
    const newSortingOrder = getNewSortingOrder(currentOrder);

    const newSort = {
      sortBy: label?.sortLabel,
      order: newSortingOrder,
      dataType: label?.dataType ?? "TEXT",
    };
    sort.length = 0;
    if (newSortingOrder !== SORTING.NO_SORT) {
      sort.push(newSort);
    }

    handleTableSorting(sort);
  };
  const sortObj = label?.sortable && findLabelObj(sort, label?.sortLabel);
  return (
    <>
      <th
        key={label.title}
        className={`${label.align || "text-left"} ${label.classNames ? label.classNames : ""
          } ${label?.sortable ? "cursor-pointer" : ""}`}
        colSpan={label?.colSpan}
        onClick={() => label.sortable && handleSort(label)}
      >
        <THeadStyled>
          {label.sup ? (
            <>
              {`${label.title}`} (ft<sup>{label.sup}</sup>)
            </>
          ) : (
            label.title
          )}
          <span className="sorting-icon">
            {label.sortable &&
              (sortObj?.sortBy === label?.sortLabel &&
                sortObj?.order === "ASC" ? (
                <>
                  <Icon
                    iconName="caret-top"
                    iconWidth="10px"
                    iconHeight="10px"
                    className="caret-top"
                  />
                  <Icon
                    iconName="caret-bottom"
                    iconWidth="10px"
                    iconHeight="10px"
                    disabled
                  />
                </>
              ) : sortObj?.sortBy === label?.sortLabel &&
                sortObj?.order === "DESC" ? (
                <>
                  <Icon
                    iconName="caret-top"
                    iconWidth="10px"
                    iconHeight="10px"
                    disabled
                  />
                  <Icon
                    iconName="caret-bottom"
                    iconWidth="10px"
                    iconHeight="10px"
                    className="caret-bottom"
                  />
                </>
              ) : (
                <>
                  <Icon
                    iconName="caret-top"
                    iconWidth="10px"
                    iconHeight="10px"
                    disabled
                  />
                  <Icon
                    iconName="caret-bottom"
                    iconWidth="10px"
                    iconHeight="10px"
                    className="caret-bottom"
                    disabled
                  />
                </>
              ))}
          </span>
        </THeadStyled>
      </th>
    </>
  );
};

export const tableHeaderWithCheckBoxSort = ({
  dataListLength,
  checkedListLength,
  primaryCheckboxHandler,
  labelConfig,
  labelMappings,
  permission,
  handleTableSorting,
  sort,
}: any) => {
  return (
    <thead>
      <tr>
        {labelConfig?.map((label) =>
          label.show
            ? label.label === (labelMappings.checkbox || "checkbox")
              ? dataListLength > 0 &&
              permission && (
                <th
                  key={label.id}
                  className={`${label?.classNames} checkbox`}
                  colSpan={label?.colSpan}
                >
                  <CheckBox
                    checked={dataListLength === checkedListLength}
                    onCheckBoxClick={(e) => primaryCheckboxHandler(e)}
                  />
                </th>
              )
              : tableData(label, sort, handleTableSorting)
            : null
        )}
      </tr>
    </thead>
  );
};
export const THeadStyled = styled.div`
.sorting-icon{
  display: inline-flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 10px;
    gap: 0px;
    position: relative;
    top: -4px;
    padding-left:5px;
    &>span{
      height:6px;
      .disabled{
        fill:#6b6c7e !important;
      }
      .caret {
      &-top {
        fill: rgb(14, 105, 255);
      }
      &-bottom {
        fill: rgb(14, 105, 255);
      }
    }
}
`;
export const SORTING = {
  ASC: "ASC",
  DESC: "DESC",
  NO_SORT: "NO_SORT",
};
