import {
  GRN,
  PRN,
  ORDER_FULFILMENT,
  STOCK_LEDGER,
  TARGET_ACHIEVEMENT,
  TERRITORY,
  TOWN,
  MERCHANDISE,
  SRN,
  BU,
  SBD,
  FOCUSED_SKU,
  OPENING_BALANCE,
  CALL_ROSTER,
  ROUTE,
  PROMOTION,
  OUTLET,
  SUB_D,
  STOCK_ADJUSTMENT,
  ROUTE_SEGMENTATION,
} from '../../data/enums/Route';
// import { JWT } from '../../environment';

const config = [
  {
    id: 1,
    title: 'Dashboard',
    icon: 'dashboard',
    children: [],
  },
  {
    id: 2,
    title: 'Sales',
    icon: 'sales',
    children: [
      {
        title: 'Order Fulfilment',
        icon: 'abc',
        url: `/${ORDER_FULFILMENT}`,
      },

      {
        title: 'Target and Achievement',
        icon: 'abc',
        url: `/${TARGET_ACHIEVEMENT}`,
      },
      // {
      //   title: 'Pick List',
      //   icon: 'abc',
      //   url: '',
      // },
      // {
      //   title: 'Trips',
      //   icon: 'abc',
      //   url: '',
      // },
      // {
      //   title: 'Returns',
      //   icon: 'abc',
      //   url: '',
      // },
      // {
      //   title: 'Collections',
      //   icon: 'abc',
      //   url: '',
      // },
      {
        title: 'Outlets',
        icon: 'abc',
        url: `/${OUTLET}`,
      },
      // {
      //   title: 'Route & Outlet',
      //   icon: 'route-outlet',
      //   children: [
      //     {
      //       id: 11,
      //       title: 'Route',
      //       url: '/sales/route',
      //     },
      //     {
      //       id: 11,
      //       title: 'Outlet',
      //       url: '/sales/outlet',
      //     },
      //   ],
      // },
      /** {
        title: 'Fundamental Reports',
        icon: 'fundamental',
        children: [
          {
            id: 11,
            title: 'Brand Coverage Report',
            url: '/brand-coverage',
          },
          {
            id: 11,
            title: 'Sales Report',
            url: '/sales-report',
          },
          {
            id: 11,
            title: 'DSE Productivity',
            url: '/dse-productivity',
          },
          {
            id: 11,
            title: 'Coverage Report',
            url: '/coverage-report',
          },
          {
            id: 11,
            title: 'Merchandising Report',
            url: '/merchandising-report',
          },
        ],
      }, */
    ],
  },
  {
    id: 3,
    title: 'Inventory',
    icon: 'inventory',
    children: [
      {
        title: 'Stock Ledger',
        icon: 'stock-ledger',
        url: `/${STOCK_LEDGER}`,
      },
      {
        title: 'GRN',
        icon: 'grn',
        url: `/${GRN}`,
      },
      {
        title: 'SRN',
        icon: 'srn',
        url: `/${SRN}`,
      },
      {
        title: 'PRN',
        icon: 'prn',
        url: `/${PRN}`,
      },
      // {
      //   title: 'Damages & Expiry',
      //   icon: 'damages&expiry',
      //   url: '',
      // },
      {
        title: 'Stock Adjustment',
        icon: 'stock-adjustment',
        url: `/${STOCK_ADJUSTMENT}`,
      },
      {
        title: 'Opening Balance',
        icon: 'srn',
        url: `/${OPENING_BALANCE}`,
      },
    ],
  },
  {
    id: 4,
    title: 'Sales Force',
    icon: 'sales-force',
    children: [
      {
        title: 'Leave Management',
        icon: 'abc',
        url: '/sales-force/leave-management',
      },
      // {
      //   title: 'Sales Team Hierarcchy',
      //   icon: 'abc',
      //   url: '',
      // },
      {
        title: 'Promotion',
        icon: 'promotion',
        url: `/${PROMOTION}`,
      },
      {
        title: 'Roster',
        icon: 'roster',
        url: `/${CALL_ROSTER}`,
      },
      {
        title: 'Routes',
        icon: 'abc',
        url: `/${ROUTE}`,
      },
      {
        title: 'Route Segmentation',
        icon: 'abc',
        url: `/${ROUTE_SEGMENTATION}`,
      },
      {
        title: 'Merchandising Devices',
        icon: 'abc',
        url: `/${MERCHANDISE}`,
      },
      // {
      //   title: 'Product Files',
      //   icon: 'abc',
      //   url: '',
      // },
    ],
  },
  {
    id: 5,
    title: 'Reports',
    icon: 'reports',
    children: [
      {
        title: 'Daily Sales Report',
        icon: 'abc',
        url: '/reports/dsr',
      },
      {
        title: 'Fundamentals',
        icon: 'fundamentals',
        children: [
          {
            id: 15,
            title: 'SBD',
            url: '/reports/sbd',
          },
          {
            id: 25,
            title: 'Coverage',
            url: '/reports/coverage',
          },
          {
            id: 35,
            title: 'Productivity',
            url: '/reports/productivity',
          },
          {
            id: 45,
            title: 'Effort Days Lost',
            url: '/reports/effort-days-lost',
          },
        ],
      },
      {
        title: 'Product Sales Report',
        icon: 'abc',
        url: '/reports/psr',
      },
      {
        title: 'GTM scorecard',
        icon: 'abc',
        url: '/reports/score-card',
      },
      {
        title: 'Availability',
        icon: 'abc',
        url: '/reports/availability',
      },
      {
        title: 'Replenishment',
        icon: 'abc',
        url: '/reports/replenishment',
      },
    ],
  },
  {
    id: 6,
    title: 'Configuration',
    icon: 'configuration',
    children: [
      {
        title: 'Sub D',
        icon: 'abc',
        url: '/configuration/sub-d',
      },
      {
        title: 'Catalog & SKU',
        icon: 'abc',
        children: [
          {
            id: 11,
            title: 'SKU',
            url: '/configuration/sku',
          },
          {
            id: 12,
            title: 'Catalog',
            url: '/configuration/catalog',
          },
        ],
      },
      {
        title: 'Focused SKU',
        icon: 'abc',
        url: `/${FOCUSED_SKU}`,
      },
      {
        title: 'SBD',
        icon: 'abc',
        url: `/${SBD}`,
      },
      {
        title: 'Users',
        icon: 'user',
        children: [
          {
            id: 11,
            title: 'User Role',
            url: '/configuration/user/user-role',
          },
          {
            id: 13,
            title: 'User Account',
            url: '/configuration/user/user-account',
            /* children: [
              {
                id: 11,
                title: 'UA 1',
                url: '/configuration/user/user-account',
              },
              {
                id: 12,
                title: 'UA 2',
                url: '/configuration/user/user-account',
              },
              {
                id: 13,
                title: 'UA 3',
                url: '/configuration/user/user-account',
              },
            ], */
          },
        ],
      },
      {
        title: 'Universe',
        icon: 'user',
        children: [
          {
            id: 15,
            title: 'BU',
            url: `/${BU}`,
          },
          {
            id: 11,
            title: 'Town',
            url: `/${TOWN}`,
          },
          {
            id: 12,
            title: 'Territory',
            url: `/${TERRITORY}`,
          },
          {
            id: 13,
            title: 'Channel/Category',
            url: '/configuration/channel',
          },
        ],
      },
    ],
  },
];

// sessionStorage.setItem(JWT.SESSION_STORAGE.APP_STORAGE.PAGE, JSON.stringify(page));

export { config as navigationConfig };

