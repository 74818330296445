import { SBD } from '../../../../data/enums/Route';
import { breadCrumb as breadCrumbConfig } from '../config';

const getbreadCrumb = ({ title = 'SBD' }) => [
  ...breadCrumbConfig,
  {
    id: 1,
    title: `${title}`,
    path: `/${SBD}`,
    active: true,
  },
];

export { getbreadCrumb };
