import { ChangeEvent, useEffect, useState, WheelEvent } from "react";
import { Icon } from '../../../../v4/components';
import styled from 'styled-components';
import Colors from '../../../../v4/utils/theme/color';


interface InputProps {
  type: string;
  value: number | string | undefined;
  rule?: string;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  placeholder: string;
  allowMultiZeroBeforeNumber?: boolean;
  decimalWithPrecedingZero?: boolean;
  enableValidation?: boolean;
  decimalPrecisionType?: string;
  enableErrorDisplay?: boolean;
  errorMessage?: string | undefined | null;
  name: string;
  iconName?: string;
  status?: string;
  labelContent?: string;
  autoComplete?: string;
  displayMessage?: string;
  decimalOnAppendString?: boolean;
  disabled?: boolean;
  argument?: { max: number };
  required?: boolean;
  onBlur?: any;
  onFocus?: any;
  isError?: any;
  isValid?: boolean;
  step?: any;
  autoFocus?: boolean;
  pattern?: any;
  className?: string;
  requiredIcon?: boolean;
  decimalPrecisionSetting?: any;
  min?: number;
  max?: number;
  constantValue?: number;
  validationValue?: boolean;
  commonValidation?: boolean;
  onKeyPress?: any;
  id?: string;
}

export const Input = (props: InputProps): JSX.Element => {
  const {
    type,
    value = "",
    rule = "",
    placeholder = "Placeholder",
    enableErrorDisplay = false,
    enableValidation = true,
    errorMessage = "Must not be empty",
    onChange = () => null,
    name = "",
    iconName,
    status,
    labelContent,
    autoComplete = "off",
    displayMessage = true,
    decimalOnAppendString,
    disabled = false,
    argument = "",
    required,
    isError,
    onBlur,
    onFocus,
    isValid,
    className = "",
    requiredIcon,
    decimalPrecisionType,
    decimalPrecisionSetting,
    min = 0,
    max = Infinity,
    constantValue,
    validationValue,
    commonValidation,
    onKeyPress,
    id = "input",
    ...rest
  } = props;
  const [nextValue, setNextValue] = useState(value);
  // const min = type === 'number' ? { min: '0' } : {};
  // const max = type === 'number' ? { max: argument?.max } : {};
  const derivedValue = decimalOnAppendString ? nextValue : value;

  const getFormattedValue = (
    event: any,
    allowMultiZeroBeforeNumber: boolean | undefined,
    decimalWithPrecedingZero: boolean | undefined,
    decimalPrecisionType: string | undefined
  ) => {
    // tslint:disable-next-line: no-shadowed-variable
    const { type, value } = event;
    if (type === "number") {
      if (allowMultiZeroBeforeNumber) {
        return value;
      }
      if (rule === "isFloat") {
        const v = value.replace(/^0+(?=\d)/, "");
        if (decimalWithPrecedingZero) {
          return v.match(regex.FLOAT_LENGTH_REGEX) ? fixedFloat(v) : v;
        }

        return fixedFloat(v);
      }
      if (decimalWithPrecedingZero) {
        return value.replace(/^0+(?=\d)/, "");
      }
      if (decimalPrecisionType) {
        const userInput = true;

        return decimalPrecisionSetting(
          value,
          decimalPrecisionType,
          false,
          null,
          userInput
        );
      }

      return Number(value.replace(/^0+(?=\d)/, ""));
    }

    return value;
  };

  useEffect(() => {
    const newValue = value === null ? " " : value;
    setNextValue(newValue);
  }, [value]);

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const {
      allowMultiZeroBeforeNumber,
      decimalWithPrecedingZero,
      decimalPrecisionType,
    } = props;
    const formattedValue = getFormattedValue(
      event.target,
      allowMultiZeroBeforeNumber,
      decimalWithPrecedingZero,
      decimalPrecisionType
    );
    if ((constantValue && constantValue) || constantValue === 0 || constantValue === null) {
      onChange(event);
      return;
    } else {
      event.target.value = formattedValue;
    }

    // state value changes but not in UI, so this solves it
    const parentLevelValidation =
      commonValidation || Number(event.target.value) > Number(validationValue);

    if (Number(validationValue)) {
      if (parentLevelValidation) {
        setNextValue(validationValue?.toString());
      }
    } else {
      setNextValue(formattedValue);
    }
    onChange(event);
  };

  return (
    <>
      <div
        className={`${isValid ? "form-input error-input" : "form-input"} ${isError ? "error-msg" : ""
          } form-input-${labelContent ? labelContent?.toLowerCase() : ""
          } ${className}`}
      >
        {labelContent !== undefined ? (
          <LabelStyled>
            {labelContent} {requiredIcon && <span className="required">*</span>}
          </LabelStyled>
        ) : (
          ""
        )}
        <TextStyled
          placeholder={placeholder}
          onChange={(e) => handleChange(e)}
          value={nextValue}
          type={type}
          name={name}
          id={id}
          // max={rest.max}
          pattern={rest.pattern}
          onKeyDown={(e) =>
            type === "number" &&
            ["e", "E", "+", "-"].includes(e.key) &&
            e.preventDefault()
          }
          status={status || ""}
          min={min}
          max={max}
          disabled={disabled}
          errorBorder={(isError && !displayMessage) || false}
          autoComplete={autoComplete}
          onBlur={onBlur}
          onFocus={onFocus}
          required={required}
          onKeyPress={onKeyPress}
          {...rest}
        />

        {isError && displayMessage && (
          <MessageStyled className="form-error" status={status || "danger"}>
            {iconName !== undefined ? (
              <Icon name={iconName} iconName={iconName} />
            ) : (
              ""
            )}
            <span className="error-message">
              {errorMessage && (
                <Icon name="exclamation-full" iconName="exclamation-full" />
              )}
              {errorMessage}
            </span>
          </MessageStyled>
        )}
      </div>
    </>
  );
};

export default Input;

const fixedFloat = (num: string | number, digits = 2) => {
  if (num) {
    return Number(Number(num).toFixed(digits));
  }
};
const regex = {
  FLOAT_LENGTH_REGEX:
    "(^(?:-?\\d+).\\d{4,})" /** digit after decimal equal or greater than 4* */,
};


interface MessageStyledProps {
  status: string;
}

interface StyledProps extends MessageStyledProps {
  errorBorder: boolean;
}

const TextStyled = styled.input<StyledProps>`
  background-clip: border-box;
  border: 1px solid #e7e7ed;
  min-width: 0;
  padding: 7px 16px;
  display: block;
  width: 100%;
  font-size: 1.6rem;
  line-height: 1.5;
  color: #030913;
  background: #f1f2f5;
  border-radius: 4px;
  box-shadow: none;
  overflow: visible;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  ${(props) =>
    props.status === "success" && {
      borderColor: Colors.secGreen.SG500,
      backgroundColor: Colors.grey.G050,
      color: Colors.grey.G1900,
    }}

  ${(props) =>
    props.status === "info" && {
      borderColor: Colors.primary.P500,
      backgroundColor: Colors.grey.G050,
      color: Colors.grey.G1900,
    }}

    ${(props) =>
    props.status === "warning" && {
      borderColor: Colors.secYellow.SY500,
      backgroundColor: Colors.grey.G050,
      color: Colors.grey.G1900,
    }}

    ${(props) =>
    props.status === "danger" && {
      borderColor: Colors.secRed.SR500,
      backgroundColor: Colors.grey.G050,
      color: Colors.grey.G1900,
    }}

    ${(props) =>
    props.errorBorder && {
      backgroundColor: Colors.secRed.SR050,
      borderColor: Colors.secRed.SR300,
    }}

    &:focus {
    color: #272833;
    background-color: #f0f5ff;
    border-color: #80acff;
    outline: 0;
  }

  &:not([type="range"]) {
    -webkit-appearance: none;
  }

  ${(props) =>
    props.disabled && {
      pointerEvents: "none",
      color: "#272833",
    }}
`;
const LabelStyled = styled.label`
  display: inline-block;
  margin-bottom: 4px;
  color: #272833;
  font-size: 14px;
  font-weight: 600;

  span.required {
    color: red;
  }
`;

const MessageStyled = styled.span<MessageStyledProps>`
  margin-top: 5px;
  display: inline-block;
  color: #8993a4;
  font-size: 14px;
  font-weight: 600;

  .error-message {
    color: #da1414;
    font-weight: 500;
    svg {
      color: #da1414;
      margin-right: 4px;
    }
  }

  ${(props) =>
    props.status === "success" && {
      color: Colors.secGreen.SG500,
    }}

  ${(props) =>
    props.status === "info" && {
      color: Colors.primary.P500,
    }}

    ${(props) =>
    props.status === "danger" && {
      color: Colors.secRed.SR500,
    }}

    ${(props) =>
    props.status === "warning" && {
      color: Colors.secYellow.SY500,
    }}
`;

export { TextStyled, LabelStyled, MessageStyled };

