import React, { ChangeEvent, useEffect } from "react";
import { Button, Icon } from '../../../../v4/components';

interface BulkProps {
  data: any;
  receiveType?: string | number;
  render: (
    checkedDataIndex: number[],
    handlePrimaryCheckboxClick: (
      e: ChangeEvent<HTMLInputElement> | React.MouseEvent | any,
      type?: string
    ) => void,
    handleSecondaryCheckboxClick: (id: number) => void,
    showOptionButton: boolean
  ) => void;
  handleFormSubmit: (
    type: string,
    checkedData: any,
    handleModalCloseOnDialogSubmit?: any
  ) => void;
  buttonToRender: {
    id?: number;
    name?: string;
    button?: {
      name: string;
      identity: string;
      iconName?: string;
      disableDefaultClassName?: boolean;
      buttonProps?: { [key: string]: boolean | string };
    }[];
    selectAll: boolean;
  };
  showConfirmDialog?: boolean;
  handleDialogClose?: any;
  dialogPopupText?: string;
  dialogPopupTextClassname?: string;
  dialogSubmitButtonName?: string;
  dialogTitle?: string;
  showBulk?: boolean;
  parentDivClassName?: string;
  actionButtonClassName?: string;
  resetBulkByProp?: any;
  checkParam?: string;
  dataCount?: any;
  getCheckedDataList?: (data: any) => void;
  enableCheckboxClickByDataParam?: string;
}

const initialCustomDialogData = {
  showDialog: false,
};

const initiaButtonIndentity = { identity: "", name: "" };

const BulkComponent = (props: BulkProps) => {
  const {
    data,
    receiveType,
    render,
    handleFormSubmit,
    buttonToRender,
    showConfirmDialog: showConfirmDialogFromProps,
    handleDialogClose,
    dialogPopupText,
    dialogPopupTextClassname,
    dialogSubmitButtonName = "Ok",
    dialogTitle = "Confirmation",
    showBulk = true,
    parentDivClassName,
    actionButtonClassName,
    resetBulkByProp,
    checkParam,
    dataCount,
    getCheckedDataList,
    enableCheckboxClickByDataParam,
  } = props;

  const [checkedDataIndex, setCheckedDataIndex] = React.useState<number[]>([]);
  const [checkedData, setCheckedData] = React.useState<any>([]);
  const [showOptionButton, setShowOptionButton] = React.useState<boolean>(true);
  const [customDialogData, setcustomDialogData] = React.useState(
    initialCustomDialogData
  );
  const [buttonIdentity, setButtonIdentity] = React.useState(
    initiaButtonIndentity
  );
  const [selectAll, setSelectAll] = React.useState<boolean>(false);
  const formConfig = {
    buttonName: dialogSubmitButtonName,
    ["title"]: dialogTitle,
    validationRequired: true,
  };

  const handleModalCloseOnDialogSubmit = () => {
    setCheckedDataIndex([]);
    setCheckedData([]);
    if (handleDialogClose) {
      handleDialogClose();
    }
  };

  const checkBoxHandler = () => {
    setCheckedDataIndex([]);
    setCheckedData([]);
  };

  const handleDialogClosed = () => {
    setcustomDialogData(initialCustomDialogData);
    setButtonIdentity(initiaButtonIndentity);
    handleDialogClose();
  };

  const onDialogSubmit = () => {
    handleFormSubmit(
      buttonIdentity.identity,
      checkedData,
      handleModalCloseOnDialogSubmit
    );
  };

  useEffect(() => {
    if (checkedData.length <= 0) {
      setcustomDialogData(initialCustomDialogData);
    }
    getCheckedDataList && getCheckedDataList(checkedData);
  }, [checkedData.length]);

  useEffect(() => {
    if (checkedDataIndex.length >= 1) {
      setShowOptionButton(false);
    } else {
      setShowOptionButton(true);
    }
  }, [checkedDataIndex.length]);

  const handlePrimaryCheckboxClick = (
    e: ChangeEvent<HTMLInputElement> | React.MouseEvent | any,
    type?: string
  ) => {
    const indexofAllData = extractObjectFromList(
      data,
      checkParam,
      enableCheckboxClickByDataParam
    );
    const filterDataIfCheckBoxEnableExists = data.filter((l) =>
      enableCheckboxClickByDataParam
        ? l[enableCheckboxClickByDataParam] === true
        : l
    );

    if (type === "select_all") {
      setCheckedDataIndex(indexofAllData);
      setCheckedData(filterDataIfCheckBoxEnableExists);
    } else if (type === "deselect_all") {
      setCheckedDataIndex([]);
      setCheckedData([]);
    } else {
      if (e.target.checked) {
        if (
          checkedDataIndex.length !== filterDataIfCheckBoxEnableExists.length
        ) {
          setCheckedDataIndex(indexofAllData);
          setCheckedData(filterDataIfCheckBoxEnableExists);
        }
      } else {
        setCheckedDataIndex([]);
        setCheckedData([]);
      }
    }
  };

  const handleSecondaryCheckboxClick = (id: number) => {
    if (checkedDataIndex.includes(id)) {
      const checkDataCopy = [...checkedData];
      const fiterCheckedDataIndex = checkedDataIndex.filter(
        (i: number) => i !== id
      );
      const filteredCheckedData = checkDataCopy.filter((i, idx) => i.id !== id);
      setCheckedDataIndex(fiterCheckedDataIndex);
      setCheckedData(filteredCheckedData);
    } else {
      const attrb = checkParam ? checkParam : "id";

      const dataFilterIfCheckboxEnableKeyExists = data.filter((l) =>
        enableCheckboxClickByDataParam
          ? l[enableCheckboxClickByDataParam] === true
          : l
      );

      const filterDataIndexIfCheckBoxKeyExists = data.filter((l) =>
        enableCheckboxClickByDataParam
          ? l[enableCheckboxClickByDataParam] === true && l.id === id
          : l[attrb] === id
      );

      const filtered = dataFilterIfCheckboxEnableKeyExists.filter(
        (i, idx) => i?.[attrb] === id
      )[0];

      if (filterDataIndexIfCheckBoxKeyExists.length > 0) {
        setCheckedDataIndex([...checkedDataIndex, id]);
      }

      if (filtered && Object.keys(filtered).length > 0) {
        setCheckedData([...checkedData, filtered]);
      }
    }
  };

  const actionButtonClickHandler = (type: string, btnName: string) => {
    if (showConfirmDialogFromProps) {
      setcustomDialogData({ showDialog: true });
      setButtonIdentity({ identity: type, name: btnName });
    } else {
      handleFormSubmit(type, checkedData, handleModalCloseOnDialogSubmit);
    }
  };

  const handleBulkVisibility = () => {
    setCheckedDataIndex([]);
    setCheckedData([]);
  };

  useEffect(() => {
    setCheckedDataIndex([]);
    setCheckedData([]);
  }, [receiveType, resetBulkByProp, dataCount]);

  return (
    <>
      {checkedDataIndex.length >= 1 && showBulk && (
        <BulkContainer className={`bulk-component ${parentDivClassName || ""}`}>
          <SelectionContainer>
            <Button
              iconName="checkbox-minus"
              className="checkbox-minus"
              onClick={checkBoxHandler}
            />

            <SelectedAlert>{`${checkedDataIndex?.length} of ${dataCount || data?.length
              } selected`}</SelectedAlert>
            {buttonToRender.selectAll ? (
              !selectAll && checkedDataIndex.length !== data?.length ? (
                <Button
                  title="Select All"
                  className="select_button"
                  onClick={(e: React.MouseEvent) => {
                    setSelectAll(true);
                    handlePrimaryCheckboxClick(e, "select_all");
                  }}
                />
              ) : (
                <Button
                  title="Deselect All"
                  className="select_button"
                  onClick={(e: React.MouseEvent) => {
                    setSelectAll(false);
                    handlePrimaryCheckboxClick(e, "deselect_all");
                  }}
                />
              )
            ) : (
              ""
            )}
          </SelectionContainer>

          <ButtonContainer>
            {buttonToRender?.button?.map((btn) => {
              const { buttonProps } = btn;
              return (
                <Button
                  title={btn.name || ""}
                  className={`${btn.disableDefaultClassName ? "" : "action_button"
                    }  ${btn.name ? btn.name.replace(/\s/g, "_").toLowerCase() : ""
                    } ${actionButtonClassName || ""}`}
                  onClick={() =>
                    actionButtonClickHandler(btn.identity, btn.name)
                  }
                  key={btn.name}
                  {...buttonProps}
                />
              );
            })}
            {checkedDataIndex.length >= 1 && (
              <Icon
                iconName="times"
                className="cross_btn"
                onClick={handleBulkVisibility}
              />
            )}
          </ButtonContainer>
          {showConfirmDialogFromProps && customDialogData.showDialog ? (
            <DialogFormWrapper
              type={EVENT_OPERATION.BULK_COMPONENT}
              handleFormSubmit={onDialogSubmit}
              formConfig={formConfig}
              onDialogCancel={handleDialogClosed}
              activeStatus
              submitType={EVENT_OPERATION.BULK_COMPONENT}
              renderDialog={({ dialogData }: any) => (
                <>
                  <span className={dialogPopupTextClassname || ""}>
                    {" "}
                    {`${dialogPopupText}`}
                  </span>
                </>
              )}
            />
          ) : (
            ""
          )}
        </BulkContainer>
      )}
      {render(
        checkedDataIndex,
        handlePrimaryCheckboxClick,
        handleSecondaryCheckboxClick,
        showOptionButton
      )}
    </>
  );
};

export default BulkComponent;

export const extractObjectFromList = (
  list: any[],
  attribute: string = "id",
  enableCheckboxClickByDataParam?: string
) =>
  list
    .filter((l) =>
      enableCheckboxClickByDataParam
        ? l[enableCheckboxClickByDataParam] === true
        : l
    )
    .map((item) => item[attribute]);



import styled from "styled-components";
import { EVENT_OPERATION } from '../../../../v4/constants/EventOperation';
import { DialogFormWrapper } from '../../../common';

const BulkContainer = styled.div`
  --primary-l3: #f0f5ff;
  --primary-l1: #80acff;
  --primary-default: #0b5fff;
  display: flex;
  background: var(--primary-l3);
  justify-content: space-between;
  align-items: center;
  padding: 8px 48px;
  padding-top: 8px;
  height: 48px;
  border-bottom: 1px solid var(--primary-l1);

  .checkbox-minus {
    border: none;
    padding: unset;
    margin-right: 0;
    border-radius: 2px;
    width: 16px;
    background: transparent;
  }

  .cross_btn {
    background: transparent;
    color: var(--primary-default);
    margin-left: 6px;
    cursor: pointer;
  }

  .print {
    color: var(--primary-default);
    width: 16px;
    height: 16px;
  }

  .times {
    color: var(--primary-default);
    width: 14px;
    height: 14px;
  }

  .action_button {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 150%;
    color: var(--primary-default);
    padding: 8px;
    border: 1px solid var(--primary-default);
    border-radius: 4px;
    margin: 0;
    background: transparent;
    height: 32px;
    display: flex;
    align-items: center;
  }
  .select_button {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 150%;
    color: var(--primary-default);
    background: transparent;
    padding: 0;
  }
  .d-flex {
    display: flex;
    align-items: center;
    gap: 16px;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

const SelectedAlert = styled.span`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  color: var(--dark);
`;

const SelectionContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`;

export { ButtonContainer, BulkContainer, SelectedAlert, SelectionContainer };
