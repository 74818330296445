import { getTodaysDate } from '../../../../../utils/date';
import { FORM_CONFIG } from '../../../../../data/enums/config';
import { refGenerator } from '../../../../../utils/refGenerator';
import { EVENT_OPERATION } from '../../../../../data/enums/EventOperation';
import { calculateRelativeDiscount } from '../../../../inventory/purchaseReturn/create/table/config';
import { VAT_AMOUNT } from '../../../../../data/enums/GeneralConstants';
import { calculatePromotionItemTotal } from '../../../orderProcessing/received/detail/config';

const title = 'GRN';

const ERROR = {
  NO_ORDER_SELECTED: 'No order selected',
  NO_ORDER_AVAILABLE: 'No order available',
  NO_NEGATIVE: 'The total bill amount cannot be negative. Please check the entries!',
};

const SRN_TYPE = {
  PARTIAL: 'PARTIAL',
  COMPLETE: 'COMPLETE',
};
const DISCOUNT_ID = {
  DISCOUNT_RS: 1,
  DISCOUNT_PER: 2,
};
const discountTypeList = [
  { id: DISCOUNT_ID.DISCOUNT_RS, title: 'In Rs', label: 'Discount (In Rs)' },
  { id: DISCOUNT_ID.DISCOUNT_PER, title: 'In %', label: 'Discount (In %)' },
];
const STOCK_TYPE = {
  DAMAGED: { title: 'Damaged', value: 'DAMAGED' },
  EXPIRED: { title: 'Expired', value: 'EXPIRED' },
};
const STOCK_TYPE_LIST = [STOCK_TYPE.DAMAGED, STOCK_TYPE.EXPIRED];

const priceMapper = element => ({
  rate: element.rate ? element.rate || 0 : element.rlp || 0,
  grossAmount: element.grossAmount || 0,
  discountTypeId: element.discountTypeId || 1,
  discountValue: element.discountValue || 0,
  discountAmount: element.discountAmount || 0,
  taxAmount: element.taxAmount || 0,
  netAmount: element.netAmount || 0,
  taxableAmount: element.taxableAmount || 0,
  billDiscount: element.billDiscount || 0,
  tradeDiscount: element.tradeDiscount || 0,
  promotionDiscount: element.promotionDiscount || 0,
  topUpDiscount: element.topUpDiscount || 0,
  subTotal: element.subTotal || 0,
});

const formMapper = element => ({
  id: element.id || '',
  skuId: element.skuId || null,
  skuBatchId: element.skuBatchId || null,
  batchName: element.batchName || '',
  expiryDate: element.SKUBatch ? element.SKUBatch.expiryDate : getTodaysDate(),
  manufactureDate: element.SKUBatch ? element.SKUBatch.manufactureDate : getTodaysDate(),
  updatedQuantity: element.updatedQuantity || 0,
  quantity: element.quantity || 0,
  returnQuantity: element.returnQuantity || 0,
  amountDetails: element.amountDetails || priceMapper({}),
  updatedAmountDetails: priceMapper(element.updatedAmountDetails || {}) || priceMapper({}),
  stockType: element.stockType || '',
  promotionId: element.promotionId || null,
  rateDetails: element.rateDetails || {},
  distributorId: element.distributorId || null,
  priceDetails: {
    rate: element.priceDetails ? Number(element.priceDetails.rate) : 0,
    amount: element.priceDetails ? element.priceDetails.amount : 0,
    discount: element.priceDetails ? Number(element.priceDetails.discount) : 0,
    netAmount: element.priceDetails ? element.priceDetails.netAmount : 0,
  },
  per_unit_rate: element.per_unit_rate ? element.per_unit_rate : 0,
  freeSKU: element.freeSKU,
  parentOrderId: element.parentOrderId,
});

const requiredList = ['skuId', 'skuBatchId', 'returnQuantity', 'rate', 'discount', 'stockType'];

const formConfig = {
  [EVENT_OPERATION.CREATE]: {
    [FORM_CONFIG.TITLE]: 'Add SKU',
    [FORM_CONFIG.VALIDATION_REQUIRED]: true,
    [FORM_CONFIG.MAPPER]: () => formMapper({}),
    [FORM_CONFIG.REFS_OBJ]: refGenerator([...requiredList]),
  },
  [EVENT_OPERATION.UPDATE]: {
    [FORM_CONFIG.TITLE]: 'Update SKU',
    [FORM_CONFIG.VALIDATION_REQUIRED]: true,
    [FORM_CONFIG.MAPPER]: element => formMapper(element),
    [FORM_CONFIG.REFS_OBJ]: refGenerator([...requiredList]),
  },
  [EVENT_OPERATION.DELETE]: {
    [FORM_CONFIG.VALIDATION_REQUIRED]: false,
    [FORM_CONFIG.MAPPER]: (element = {}) => ({
      id: element.id || '',
      promotionId: element.promotionId || null,
    }),
  },
  [EVENT_OPERATION.READ]: {
    [FORM_CONFIG.MAPPER]: data => {
      const total = calculatePromotionItemTotal(data.Lines || []) || {};

      return { ...data, total };
    },
    [FORM_CONFIG.TITLE]: 'Promotion Details',
  },
};

const totalViewRefs = refGenerator(['billDiscount', 'tradeDiscount']);

const getUpdatedLineAmountDetails = (element, totalAmountObj, field = 'updatedAmountDetails') => {
  const subTotal =
    (element.returnQuantity || 0) * (element[field].rate || 0) -
    (element[field].promotionDiscount || 0) -
    (element[field].topUpDiscount || 0);

  const billDiscount = calculateRelativeDiscount(totalAmountObj.subTotal, totalAmountObj.billDiscount, subTotal) || 0;
  const tradeDiscount = calculateRelativeDiscount(totalAmountObj.subTotal, totalAmountObj.tradeDiscount, subTotal) || 0;
  const discountAmount =
    (element[field].promotionDiscount || 0 + element[field].topUpDiscount) + billDiscount + tradeDiscount;
  const taxableAmount = subTotal - billDiscount - tradeDiscount;
  const taxAmount = taxableAmount * VAT_AMOUNT;
  const netAmount = taxableAmount + taxAmount;

  return {
    netAmount,
    billDiscount,
    tradeDiscount,
    taxableAmount,
    taxAmount,
    discountAmount,
    subTotal,
  };
};

const updateSkuLine = (element, sku, skuBatch, totalAmountObj) => {
  const lineAmountDetails = getUpdatedLineAmountDetails(element, totalAmountObj);
  const amount = element.returnQuantity * element.updatedAmountDetails.rate;

  return {
    ...element,
    updatedAmountDetails: {
      ...element.updatedAmountDetails,
      amount,
      netAmount: lineAmountDetails.netAmount,
      billDiscount: lineAmountDetails.billDiscount,
      tradeDiscount: lineAmountDetails.tradeDiscount,
      grossAmount: amount * (1 + (VAT_AMOUNT || 0)),
      subTotal: lineAmountDetails.subTotal,
      taxableAmount: lineAmountDetails.taxableAmount,
      taxAmount: lineAmountDetails.taxAmount,
      discountAmount: lineAmountDetails.discountAmount,
    },
    SKU: {
      id: sku.id,
      title: sku.title,
    },
    SKUBatch: {
      id: skuBatch.id,
      batchName: skuBatch.batchName,
      manufactureDate: element.manufactureDate,
      expiryDate: element.expiryDate,
    },
    priceDetails: {
      ...element.priceDetails,
      amount: element.quantity * Number(element.updatedAmountDetails.rate),
      netAmount: element.quantity * Number(element.updatedAmountDetails.rate) - element.updatedAmountDetails.discount,
    },
  };
};

const prepareSkuLinesForValidation = order => {
  const { amount, discountValue, discountTypeId, ...amountDetails } = order.updatedAmountDetails;
  return {
    orderId: order.id,
    skuBatchRateId: order.skuBatchId,
    skuId: order.skuId,
    distributorId: order.distributorId,
    rateDetails: order.rateDetails,
    quantity: order.quantity,
    returnQuantity: order.returnQuantity,
    promotionId: order.promotionId,
    amountDetails,
    freeSKU: order.freeSKU,
    parentOrderId: order.parentOrderId,
  };
};

const updateRate = (state, stateUpdater, selectedBatch) => {
  state.updatedAmountDetails.rate = selectedBatch.priceDetails.rlp;
  stateUpdater(state);
};

export {
  ERROR,
  formConfig,
  title,
  totalViewRefs,
  SRN_TYPE,
  STOCK_TYPE,
  STOCK_TYPE_LIST,
  updateRate,
  updateSkuLine,
  priceMapper,
  getUpdatedLineAmountDetails,
  formMapper as salesLinesMapper,
  prepareSkuLinesForValidation,
  discountTypeList,
  DISCOUNT_ID,
};
