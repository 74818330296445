import { useState, useEffect } from 'react';
import { commonApiFunc } from '../../List/DataMapper/helperFunction';
import { ALERT_TYPE } from '../../../../../data/enums/AlertType';
import { getUserGroupListMapper } from '../DataMapper/DetailsDataMapper';

export const useIncentiveList = ({ callApi, props }: any) => {
  const { getUserRole, displayAlert } = props;
  const [userGroupList, setUserGroupList] = useState([]);

  const handleSuccess = ({ res, param }: any) => {
    if (res) {
      if (param === 'userGroupList') {
        const list = res?.data?.roles?.rows?.map(({ name, id }) => ({ id, title: name }));
        setUserGroupList(list);
        return;
      }
    }
  };

  const getUserGroupList = async () => {
    await commonApiFunc({
      payload: {
        param: 'userGroupList',
      },
      func: {
        api: getUserRole,
        success: handleSuccess,
        error: handleError,
        mapper: getUserGroupListMapper,
      },
    });
  };
  const handleError = ({ err, param }: any) => {
    displayAlert(ALERT_TYPE.DANGER, err);
  };
  useEffect(() => {
    userGroupList?.length === 0 && getUserGroupList();
  }, [callApi]);
  return { userGroupList };
};
