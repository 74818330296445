import { configuration as configurationRoute } from '../../../data/enums/Route';

const breadCrumb = [configurationRoute];

export { breadCrumb };

export const getSBDDynamicTitleFromSetting = () => {
  const settings = JSON?.parse?.(localStorage?.getItem?.('rosia-bu-settings'));
  const SBDDynamicTitle = settings?.distributionMetricLabel;
  return SBDDynamicTitle ?? 'SBD';
};
