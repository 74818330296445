import styled from 'styled-components';

export const DetailsStyled = styled.div`
  .bulk_delete,
  .table_delete{
    max-width:388px !important;
  }
  .table_update,
  .table_create{
    .evolve-dialog__body{
      padding:0;
    }
    &.modal-inner{
    max-width:865px;
  }
  }

  .w-80{
    width:80px;
    max-width:80px;
  }
  .icon{
  cursor:pointer;
  }
  .mr-16{
    margin-right:16px;
  }
  .outlined{
    border:1px solid #80ACFF;
    color:#0B5FFF;
    &:focus,
    &:hover{
      background-color:transparent;
    }
    .icon{
      fill:#0B5FFF;
    }
  }
  .bor-r-1{
    border-right:1px solid #E7E7ED;
  }
  .bor-l-1{
    border-left:1px solid #E7E7ED;
  }
  .table-wrap>table{
    tr{
      height:40px;
    }
    tbody>tr{
    td{
      border-bottom:0 !important;
    }
    &.last-child{
      td{
      border-bottom:1px solid #e7e7ed !important;
    }
    }
}
}


`;
