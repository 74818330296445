import { sales as salesRoute, collection as collectionRoute } from '../../../../data/enums/Route';
import { FILTER } from '../../../../data/enums/filter';
import { getFilterConfig } from '../../../../utils/filter';

const title = 'Online QR';

const breadCrumbConfig = [salesRoute, collectionRoute];

const ONLINE_QR_TABS = {
  RECONCILED: 'reconciled',
};

const filterConfig = getFilterConfig([FILTER.SUB_D, FILTER.ROUTE, FILTER.DSE, FILTER.AMOUNT]);

export { title, breadCrumbConfig, ONLINE_QR_TABS, filterConfig };
