import styled from 'styled-components';
import { CheckBox, Icon } from '../../../../../v4/components';
import { rangeValueCompareListFrom, rangeValueCompareListTo, rangeValueTypeList, TABLE_CONFIG } from './config';
import { fixedFloatAndCommas } from '../../../../../utils/conversion';

export const TableConfig = ({ data, handleIconClick, bulkCompo, mode }: any) => {
  const { selectedItems,
    isAllSelected,
    handleRowSelect,
    handleBulkDelete,
    handleSelectAll } = bulkCompo || {}
  const { isRead } = mode || {};
  return (
    <>
      {selectedItems?.length > 0 && (
        <GroupedStyled className="grouped">
          <div className="left">
            <div onClick={handleBulkDelete} className={selectedItems.length === 0 && "disabled"}>
              <Icon iconName="checkbox-minus" />
            </div>
            <span className="data-length">{` ${selectedItems?.length} of ${data?.length} Selected`}</span>
          </div>
          <div onClick={handleBulkDelete} className={selectedItems.length === 0 && "disabled"}>
            <Icon iconName="trash" />
          </div>
        </GroupedStyled>
      )}
      <div className="table-wrap">
        <table>
          <thead>
            <tr>
              {data?.length > 0 && !isRead && (
                <th className="w-80">
                  <CheckBox checked={isAllSelected} onCheckBoxClick={handleSelectAll} />
                </th>
              )}
              <th>Measure/KPI</th>
              <th>Achievement Range / Slabs</th>
              <th className="text-right">Incentive Amount</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {data?.map?.((group, groupIndex) =>
              group.items.map((item, itemIndex) => (
                <tr
                  key={`${groupIndex}-${itemIndex}`}
                  className={`${itemIndex === 0 ? "first-child" : ""} ${itemIndex === group.items.length - 1 ? "last-child" : ""
                    }`}
                >
                  {data?.length > 0 && !isRead && (
                    <td className="w-80">
                      {itemIndex === 0 && (
                        <CheckBox
                          checked={selectedItems.some(
                            selected => selected.groupId === group.groupId && selected.itemId === item.itemId,
                          )}
                          onCheckBoxClick={() => handleRowSelect(group.groupId, item.itemId)}
                        />
                      )}
                    </td>
                  )}
                  <td>{itemIndex === 0 ? item?.measure?.title : ''}</td>
                  <td>{getRange(item)}</td>
                  <td className="text-right">{fixedFloatAndCommas(item?.amount)}</td>
                  <td className="text-right">
                    {itemIndex === 0 && !isRead && (
                      <>
                        <span
                          onClick={() => handleIconClick?.({ type: TABLE_CONFIG.DELETE, data: item })}
                          className="mr-16"
                        >
                          <Icon iconName="trash" />
                        </span>
                        <span
                          onClick={() => handleIconClick?.({ type: TABLE_CONFIG.UPDATE, data: item })}
                        >
                          <Icon iconName="pencil" />
                        </span>
                      </>
                    )}
                  </td>
                </tr>
              )),
            )}
          </tbody>
        </table>
      </div>
    </>
  );
};

export const getRange = (item: any) => {
  const { rangeFromValue, rangeFromValueType, rangeToValue, rangeToValueType, rangeToValueCompare } = item ?? {};
  const typeLabelFrom = findInList(rangeValueTypeList, rangeFromValueType, 'label');
  const typeLabelTo = findInList(rangeValueTypeList, rangeToValueType, 'label');
  const compareFrom = findInList([...rangeValueCompareListFrom, ...rangeValueCompareListTo], rangeToValueCompare, 'title');
  if (rangeFromValue === rangeToValue) {
    return `${compareFrom} ${typeLabelFrom == "Rs" ? typeLabelFrom : ""} ${rangeFromValue}  ${typeLabelTo == "%" ? typeLabelTo : ""}`;
  }
  return `${typeLabelFrom == "Rs" ? typeLabelFrom : ""} ${rangeFromValue} ${typeLabelFrom == "%" ? typeLabelFrom : ""} - ${typeLabelTo == "Rs" ? typeLabelTo : ""} ${rangeToValue} ${typeLabelTo == "%" ? typeLabelTo : ""}`;
};
const findInList = (list: any[], value: any, key: string) =>
  list?.find(ite => ite?.value === value)?.[key];

export const GroupedStyled = styled.div`
  &.grouped{
    --primary-l3: #f0f5ff;
    --primary-l1: #80acff;
    --primary-default: #0b5fff;
    display: flex;
    background: var(--primary-l3);
    -webkit-box-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    align-items: center;
    padding: 8px 48px;
    height: 48px;
    border-bottom: 1px solid var(--primary-l1);
    .left{
      display: flex;
    align-items: center;
    }
    .data-length{
      font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 150%;
    color: #222222;
    padding:36px;
    }
}

  
`
