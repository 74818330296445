import { groupByAlias } from '../Schema/helperFuntion';
import { getMeasureList } from '../View/config';

export const summaryDataServerToClientMapper = (data: any) => {
  return {
    userGroup: { id: data?.role_id, title: data?.role },
    month: { month: +data?.month, year: +data?.year },
  };
};

export const resDataServerToClientMapper = ({ ...data }) => {
  const res = getTableListToClient(data);
  const transformedDataUpdate = {
    summaryObj: summaryDataServerToClientMapper(data),
    tableList: res?.map?.(item => groupByAlias(item)),
  };
  return transformedDataUpdate;
};

const getTableListToClient = data => {
  const tableList = data?.measures?.map?.(measure => ({
    id: measure?.id,
    measure: {
      id: measure?.id,
      title: getMeasureList()?.find(ite => ite?.value == measure?.title)?.title ?? measure?.title,
      value: measure?.title,
    },
    items: measure?.ranges?.map?.(range => ({
      itemId: range?.id,
      amount: range?.incentive_amount,
      rangeToValue: range?.max_value,
      rangeToValueCompare: range?.max_value_operator,
      rangeToValueType: range?.max_value_unit,
      rangeFromValue: range?.min_value,
      rangeFromValueCompare: range?.min_value_operator,
      rangeFromValueType: range?.min_value_unit,
    })),
  }));
  return tableList;
};

export const createToServerMapper = (payloadIn: any) => {
  const { summaryObj, tempList } = payloadIn ?? {};
  const { month, userGroup } = summaryObj ?? {};
  const payloadOut = {
    input: {
      month: +`${month?.month}`,
      year: +`${month?.year}`,
      role_id: userGroup?.id,
      measures: getMeasureListToServer(tempList),
    },
  };
  return payloadOut;
};
export const getMeasureListToServer = (list: any) => {
  const res = list?.map(out => ({
    title: out?.measure?.value,
    ...(!!out?.id && { id: out?.id }),
    ranges: out?.items?.map(it => ({
      incentive_amount: it?.amount,
      ...(!!it?.itemId && { id: it?.itemId }),
      max_value: it?.rangeToValue,
      max_value_operator: it?.rangeToValueCompare,
      max_value_unit: it?.rangeToValueType,
      min_value: it?.rangeFromValue,
      min_value_operator: it?.rangeFromValueCompare,
      min_value_unit: it?.rangeFromValueType,
    })),
  }));
  return res;
};

export const updateToServerMapper = (payloadIn: any) => {
  const { summaryObj, tempList, id } = payloadIn ?? {};
  const { month, userGroup } = summaryObj ?? {};
  const payloadOut = {
    updateIncentiveId: id,
    input: {
      month: +`${month?.month}`,
      year: +`${month?.year}`,
      role_id: userGroup?.id,
      measures: getMeasureListToServer(tempList),
    },
  };
  return payloadOut;
};

export const getDetailsServerToClientMapper = payload => {
  return {
    incentiveId: payload?.id,
  };
};

export const getUserGroupListMapper = (payloadIn: any) => {
  const {} = payloadIn;
  const payloadOut = {};
  return payloadOut;
};
