import { useEffect, useState } from 'react';
import { ALERT_TYPE } from '../../../../../data/enums/AlertType';
import { SELECT_OPTIONS } from '../../../../../v4/constants/pagination';
import {
  incentiveClientToServerMapper,
  incentiveDelete,
  incentiveServerToClientMapper,
} from '../DataMapper/IncentiveDataMapper';
import { defaultDate, initialDialog } from '../View/config';
import { useFilterController } from './useFilterController';
import { dataGET } from '../View/data';
import { INCENTIVE, INCENTIVE_CREATE, INCENTIVE_DETAILS } from '../../../../../data/enums/Route';
import history from '../../../../../utils/history';
import { commonApiFunc } from '../DataMapper/helperFunction';

export const useIncentiveController = ({ ...props }) => {
  const [load, setLoad] = useState(false);
  const [data, setData] = useState<any>({ rows: [], count: 0 });
  const [dialog, setDialog] = useState(initialDialog);

  const {
    filters,
    handleSearchInputChange,
    handlePageChange,
    handleDateRangeChange,
    handleTableSorting,
    handleFilterChange,
  } = useFilterController({ domain: INCENTIVE, defaultDate });

  const permissions = JSON.parse(localStorage.getItem('-rosia-permission'));
  const permission = permissions?.find(a => a?.program === 'Incentive')?.permission;

  const { filter, filterMenu, queryString, pagination, sort, date } = filters || {};

  const { getIncentivesListApi, displayAlert, deleteIncentive } = props;

  const handleIconClick = (type, element) => {
    setDialog({ type, element });
  };

  const onCloseDialog = () => {
    setDialog(initialDialog);
    setLoad(false);
  };

  const onDialogSubmit = async (type: any, data: any) => {
    setLoad(true);
    await commonApiFunc({
      payload: [dialog?.element?.id],
      func: {
        api: deleteIncentive,
        success: handleSuccess,
        error: handleError,
        mapper: incentiveDelete,
      },
    });
    onCloseDialog();
  };

  const handleSuccess = ({ res, param }: any) => {
    if (res) {
      if (param === 'incentiveList') {
        const list = incentiveServerToClientMapper(res?.data);
        setData(list);
        setLoad(false);
        return;
      }
      getIncentive();
      displayAlert(ALERT_TYPE.SUCCESS, `Incentive ${dialog?.type?.toLocaleLowerCase()}d successfully`);
      onCloseDialog();
    }
  };
  const getIncentive = async () => {
    setLoad(true);
    await commonApiFunc({
      payload: {
        ...filters,
        param: 'incentiveList',
      },
      func: {
        api: getIncentivesListApi,
        success: handleSuccess,
        error: handleError,
        mapper: incentiveClientToServerMapper,
      },
    });
  };
  const handleError = ({ err, param }: any) => {
    setLoad(false);
    displayAlert(ALERT_TYPE.DANGER, err);
  };
  useEffect(() => {
    getIncentive();
    // setData(incentiveServerToClientMapper(dataGET));
  }, [queryString, filter, date]);

  const handleTableRowClick = (id: number) => {
    history.push(`/${INCENTIVE_DETAILS}/${id}`);
  };

  const handleCreate = () => {
    history.push(`/${INCENTIVE_CREATE}`);
  };
  const handleBulkDelete = async (values: any, data: any, handleModalCloseOnDialogSubmit?: any) => {
    const ids = data?.map((item: any) => item?.id);
    setLoad(true);
    await commonApiFunc({
      payload: ids,
      func: {
        api: deleteIncentive,
        success: handleSuccess,
        error: handleError,
        mapper: incentiveDelete,
      },
    });
    handleModalCloseOnDialogSubmit();
  };
  const nameClass = data?.count > SELECT_OPTIONS[0].value - 1 && 'pagination_exist';
  return {
    filterMenu,
    filters,
    handleSearchInputChange,
    handleFilterChange,
    handleTableSorting,
    handleDateRangeChange,
    load,
    handleIconClick,
    dialog,
    onCloseDialog,
    onDialogSubmit,
    handlePageChange,
    data,
    nameClass,
    permission,
    sort,
    pagination,
    handleTableRowClick,
    handleCreate,
    handleBulkDelete,
  };
};
