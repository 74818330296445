import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';

import Form from './Form';
import { DialogFormWrapper } from '../../../../common';
import { formConfig, crudRequestConfig } from './config';
import Confirmation from '../../../../common/DialogConfirmation';
import withAlert from '../../../../../utils/composition/withAlert';
import { isConfirmationType } from '../../../../common/HelperFunctions';
import { EVENT_OPERATION } from '../../../../../data/enums/EventOperation';
import { handleFormSubmit, responseInterpreter } from '../../../../../utils/crudResponseProcessor';
import styled from 'styled-components';
import { getSBDDynamicTitleFromSetting } from '../../config';

const propTypes = {
  type: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  updateSBD: PropTypes.func.isRequired,
  createSBD: PropTypes.func.isRequired,
  element: PropTypes.instanceOf(Object),
  serverResponseWaiting: PropTypes.bool,
  toggleState: PropTypes.func.isRequired,
  replicateSbd: PropTypes.func.isRequired,
  onApiFailure: PropTypes.func.isRequired,
};

const defaultProps = {
  element: {},
  serverResponseWaiting: false,
  type: EVENT_OPERATION.CREATE,
};

class SbdDialog extends Component {
  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  constructor(props) {
    super(props);
    const serverCall = {
      [EVENT_OPERATION.CREATE]: props.createSBD,
      [EVENT_OPERATION.UPDATE]: props.updateSBD,
      [EVENT_OPERATION.REPLICATE]: props.replicateSbd,
      [EVENT_OPERATION.DELETE]: props.toggleState,
    };
    this.onCRUDSuccess = responseInterpreter(this.handleSuccessResponse);
    this.onFormSubmit = handleFormSubmit(this.onCRUDSuccess, props.onApiFailure, crudRequestConfig, serverCall);
  }

  handleSuccessResponse = (type, response) => {
    const { onClose, onSubmit } = this.props;
    onClose();
    onSubmit(type, response);
  };

  render() {
    const { type, element, onClose, serverResponseWaiting } = this.props;

    return (
      <DialogFormWrapper
        formConfig={formConfig[type]}
        dialogElement={element}
        onDialogSubmit={this.onFormSubmit}
        onDialogCancel={onClose}
        formTitle={
          ![EVENT_OPERATION.DELETE, EVENT_OPERATION.BULK_DELETE]?.includes(type) ? getSBDDynamicTitleFromSetting() : ''
        }
        type={type}
        disableDialogClose
        renderDialog={({ refsObj, dialogData, handleInputChange, enableErrorDisplay, handleDropDownChange }) => (
          <DialogStyle>
            {(type === EVENT_OPERATION.UPDATE ||
              type === EVENT_OPERATION.CREATE ||
              type === EVENT_OPERATION.REPLICATE) && (
              <Form
                show
                type={type}
                refsObj={refsObj}
                data={dialogData}
                loading={serverResponseWaiting}
                handleInputChange={handleInputChange}
                enableErrorDisplay={enableErrorDisplay}
                handleDropDownChange={handleDropDownChange}
              />
            )}
            {isConfirmationType(type) && Confirmation(type)}
          </DialogStyle>
        )}
      />
    );
  }
}

SbdDialog.propTypes = propTypes;

SbdDialog.defaultProps = defaultProps;

export default withAlert()(SbdDialog);

const DialogStyle = styled.div`
  .filter-dropdown-card {
    top: -365px;
  }
`;
