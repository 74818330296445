import moment from 'moment';
import { fixedFloatAndCommas } from '../../../../../utils/conversion';

export const incentiveServerToClientMapper = data => {
  const mappedData = data?.incentives?.rows?.map((item, index) => {
    return {
      userGroup: item?.role ?? '-',
      id: item?.id ?? '-',
      month: item?.year && item?.month ? moment(`${item?.year}-${item?.month}`, 'YYYY-MM').format('MMMM YYYY') : '-',
      totalIncentive: fixedFloatAndCommas(getIncentiveAmount(item)) ?? '-',
    };
  });
  return { rows: mappedData, count: data?.incentives?.count ?? 0 };
};

export const incentiveClientToServerMapper = (payloadIn: any) => {
  const { filter: filterList = [], pagination, queryString = '', date, status, sort } = payloadIn;
  const payloadOut = {
    offset: pagination?.offset,
    limit: pagination?.limit,
    ...(status && { status }),
    filter: {
      filters: [...filterList],
      dateRange: date,
      queryString,
      ...(sort?.length > 0 && { sort }),
    },
  };
  return payloadOut;
};
export const incentiveDelete = (payloadIn: any) => {
  return { ids: payloadIn };
};

// Function to get the total incentive amount from ranges
const getRangeIncentiveAmount = (ranges: any[]): number => {
  const res = ranges?.reduce?.((total, range) => total + (range.incentive_amount || 0), 0);
  return res;
};

// Function to get the total incentive amount from measures
const getMeasureIncentiveAmount = (measures: any[]): number => {
  const res = measures?.reduce((total, measure) => {
    return total + getRangeIncentiveAmount?.(measure?.ranges);
  }, 0);
  return res ?? 0;
};

// Main function to get the incentive amount from the data object
const getIncentiveAmount = (data: any): number => {
  return getMeasureIncentiveAmount?.(data?.measures);
};
