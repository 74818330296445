import React from 'react';
import PropTypes from 'prop-types';
import { Input, Form } from '../../../../../components';
import withLoading from '../../../../../utils/composition/withLoading';
import EVENT_OPERATION from '../../../../../data/enums/EventOperation';
import { dateFormat } from './config';
import { MonthPicker, SingleDatePicker } from '../../../../../v4/components';
import { get } from '../../config';

const propTypes = {
  data: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    description: PropTypes.string,
  }),
  handleInputChange: PropTypes.func,
  enableErrorDisplay: PropTypes.bool,
  refsObj: PropTypes.instanceOf(Object),
  type: PropTypes.string,
};

const defaultProps = {
  data: {
    name: '',
    description: '',
  },
  refsObj: {
    title: '',
  },
  enableErrorDisplay: true,
  handleInputChange: () => null,
  type: '',
};

const TargetAchievementForm = ({ ...props }) => {
  const { data, refsObj, handleInputChange, enableErrorDisplay, type, handleDateChange, details } = props;
  const startDate = dateFormat(details.start_date);
  const endDate = dateFormat(details.end_date);

  const permission = get('rosia-bu-settings');

  const filterPermission = (program, field) => {
    const filter = permission?.fundamental_targets?.find(a => a.targetType === program)?.parameters;
    return filter?.filter(m => m.alias === field)[0];
  };

  const ulpo = filterPermission('USERTARGET', 'ULPO');
  const tls = filterPermission('USERTARGET', 'TLS');
  const sbd = filterPermission('USERTARGET', 'SBD');
  const dgp = filterPermission('USERTARGET', 'DGP');
  const bill_cuts = filterPermission('USERTARGET', 'Bill Cuts');
  const productivity = filterPermission('USERTARGET', 'Productivity');
  const active_outlets = filterPermission('USERTARGET', 'Active Outlets');
  const perfect_call_target = filterPermission('USERTARGET', 'Perfect Call Target');

  return (
    <Form>
      <>
        <div className="month-flex">
          <div className="single-date-picker one disable">
            <label>Start Date</label>
            <SingleDatePicker date={startDate} onSelect={e => handleDateChange(e, 'duration')} disabled />
          </div>

          <div className="single-date-picker one">
            <label>End Date</label>
            <SingleDatePicker date={endDate} onSelect={e => handleDateChange(e, 'duration')} disabled />
          </div>
        </div>

        <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between' }}>
          {sbd?.status && (
            <div className="single-date-picker">
              <Input
                ref={ref => (refsObj.sbd = ref)}
                value={data.sbd}
                name="sbd"
                type="number"
                argument={{ min: 0 }}
                placeholder="SBD"
                enableErrorDisplay={enableErrorDisplay}
                enableValidation
                onChange={event => handleInputChange(event)}
                labelContent="SBD"
                disabled={type === EVENT_OPERATION.READ}
                decimalWithPrecedingZero
              />
            </div>
          )}

          {ulpo?.status && (
            <div className="single-date-picker">
              <Input
                ref={ref => (refsObj.user = ref)}
                value={data.ulpo}
                name="ulpo"
                type="number"
                argument={{ min: 0 }}
                placeholder="ULPO"
                enableErrorDisplay={enableErrorDisplay}
                enableValidation
                onChange={event => handleInputChange(event)}
                labelContent="ULPO"
                disabled={type === EVENT_OPERATION.READ}
                decimalWithPrecedingZero
              />
            </div>
          )}

          {tls?.status && (
            <div className="single-date-picker">
              <Input
                ref={ref => (refsObj.tls = ref)}
                value={data.tls}
                name="tls"
                type="text"
                argument={{ min: 0 }}
                placeholder="TLS"
                enableErrorDisplay={enableErrorDisplay}
                enableValidation
                onChange={event => handleInputChange(event)}
                labelContent="TLS"
                disabled={type === EVENT_OPERATION.READ}
                decimalWithPrecedingZero
              />
            </div>
          )}

          {productivity?.status && (
            <div className="single-date-picker">
              <Input
                ref={ref => (refsObj.productivity = ref)}
                value={data.productivity}
                name="productivity"
                type="number"
                argument={{ min: 0 }}
                placeholder="Productivity"
                enableErrorDisplay={enableErrorDisplay}
                enableValidation
                onChange={event => handleInputChange(event)}
                labelContent="Productivity"
                disabled={type === EVENT_OPERATION.READ}
                decimalWithPrecedingZero
              />
            </div>
          )}

          {active_outlets?.status && (
            <div className="single-date-picker">
              <Input
                ref={ref => (refsObj.active_outlets = ref)}
                value={data.active_outlets}
                name="active_outlets"
                type="number"
                argument={{ min: 0 }}
                placeholder="Active Outlets"
                enableErrorDisplay={enableErrorDisplay}
                enableValidation
                onChange={event => handleInputChange(event)}
                labelContent="Active Outlets"
                disabled={type === EVENT_OPERATION.READ}
                decimalWithPrecedingZero
              />
            </div>
          )}
          {dgp?.status && (
            <div className="single-date-picker">
              <Input
                ref={ref => (refsObj.dgp = ref)}
                value={data.dgp}
                name="dgp"
                type="number"
                argument={{ min: 0 }}
                placeholder="DGP"
                enableErrorDisplay={enableErrorDisplay}
                enableValidation
                onChange={event => handleInputChange(event)}
                labelContent="DGP"
                disabled={type === EVENT_OPERATION.READ}
                decimalWithPrecedingZero
              />
            </div>
          )}

          {bill_cuts?.status && (
            <div className="single-date-picker">
              <Input
                ref={ref => (refsObj.bill_cuts = ref)}
                value={data.bill_cuts}
                name="bill_cuts"
                type="number"
                argument={{ min: 0 }}
                placeholder="Bill Cuts"
                enableErrorDisplay={enableErrorDisplay}
                enableValidation
                onChange={event => handleInputChange(event)}
                labelContent="Bill Cuts"
                disabled={type === EVENT_OPERATION.READ}
                decimalWithPrecedingZero
              />
            </div>
          )}
          {perfect_call_target?.status && (
            <div className="single-date-picker">
              <Input
                ref={ref => (refsObj.perfect_call_target = ref)}
                value={data.perfect_call_target}
                name="perfect_call_target"
                type="number"
                argument={{ min: 0 }}
                placeholder="Perfect Call Target"
                enableErrorDisplay={enableErrorDisplay}
                enableValidation
                onChange={event => handleInputChange(event)}
                labelContent="Perfect Call Target"
                disabled={type === EVENT_OPERATION.READ}
                decimalWithPrecedingZero
              />
            </div>
          )}
        </div>
      </>
    </Form>
  );
};

TargetAchievementForm.propTypes = propTypes;

TargetAchievementForm.defaultProps = defaultProps;

const TargetAchievementFormWithErrorAndLoading = withLoading(TargetAchievementForm);

export default TargetAchievementFormWithErrorAndLoading;
