import gql from 'graphql-tag';
import { graphql } from 'react-apollo';
import { GET_CATALOG_LIST } from '../catalog/API';

const BU_FRAGMENT = gql`
  fragment BUFragment on Settings {
    currency
    targetDimension
    targetDimensionMetric
    web_order
    fiscalYear {
      start
      end
    }
    unitsOfMeasurement {
      title
      label
    }
    dateSettings {
      format
      calendar
    }
    orderTakingProcess {
      catalogGroupTitle: catalog_grouping_title
      catalogGroupId: catalog_grouping_id
      catalogGroupPagination: catalog_grouping_pagination
      skuGroupTitle: sku_grouping_title
      skuGroupId: sku_grouping_id
    }
    distributionMetricLabel
    focusedSkuType {
      general
      smart
    }
    orderForMultipleDistributors
    targetDimension
    geoFencing
    pfd
    multipleDseInRoute
    payment
    srn
    orderStatus {
      received
      invoiced
      dispatched
      delivered
    }
    targets {
      salesTeamWise
      subDWise
      brandWise
      automatedSalesTarget
    }
    skuAssortment {
      channel
      distributorGroup
    }
    sbd
    focusedSKU
    routeSegmentation
    sapIntegration
    smsIntegration
    smsforOrderIntegration
    callRoster
    ico {
      sku
      skuFamily
    }
    brand_wise_order {
      status
      skipped_phone_numbers
      distributor_ids
    }
    brandDistributionCoverageTarget
    rigoIntegration
    outletCoverageTarget
    stockMovementRule {
      skuTag
      stockRule {
        stockRuleType
        status
      }
    }
    fundamental_targets {
      targetType
      status
      parameters {
        title
        status
        alias
      }
    }
    orderTakingSequence {
      orderTakingSequence
      sequencing {
        alias
        title
        active
        sequence
      }
    }
  }
`;

const GET_BU_DATA = gql`
  query getBuData {
    settings: settings {
      ...BUFragment
    }
  }
  ${BU_FRAGMENT}
`;
const GET_SEQUENCE = gql`
  query callProcedureDetailList($type: callProcedureType!) {
    callProcedureDetails(type: $type) {
      rows {
        id
        refId
        sequence
        CallProcedure {
          id
          title
        }
        active
        type
      }
    }
  }
`;

const CREATE_BU = graphql(
  gql`
    mutation ($input: SettingsInput!) {
      storeSettings(input: $input)
    }
  `,
  {
    // name: 'loginWithServer',
    props: ({ mutate }) => ({
      createBU: variables =>
        mutate({
          variables,
        }),
    }),
  },
);

const CREATE_CALL_PROCEDURE = graphql(
  gql`
    mutation ($input: CallProcedureInput!) {
      createCallProcedure(input: $input) {
        message
      }
    }
  `,
  {
    // name: 'loginWithServer',
    props: ({ mutate }) => ({
      createCallProcedure: variables =>
        mutate({
          variables,
        }),
    }),
  },
);

const UPDATE_BU = graphql(
  gql`
    mutation ($input: SettingsInput!) {
      storeSettings(input: $input)
    }
  `,
  {
    // name: 'loginWithServer',
    props: ({ mutate }) => ({
      updateBU: variables =>
        mutate({
          variables,
        }),
    }),
  },
);

const RUN_GRN_JOB = graphql(
  gql`
    mutation Mutation {
      runGRNJob
    }
  `,
  {
    props: ({ mutate }) => ({
      runGrnJob: () => mutate({}),
    }),
  },
);

const GET_MASTER_DATA = gql`
  query getMasterData($type: String) {
    listMasterData(type: $type) {
      type
      list
    }
  }
`;

const query = {
  getBuData: GET_BU_DATA,
  callProcedureDetailList: GET_SEQUENCE,
  getMasterData: GET_MASTER_DATA,
  getCatalogList: GET_CATALOG_LIST,
};

const mutation = {
  createBU: CREATE_BU,
  updateBU: UPDATE_BU,
  createCallProcedure: CREATE_CALL_PROCEDURE,
  runGrnJob: RUN_GRN_JOB,
};

export { BU_FRAGMENT, CREATE_BU, UPDATE_BU, GET_BU_DATA, CREATE_CALL_PROCEDURE, RUN_GRN_JOB };

export { query, mutation };
