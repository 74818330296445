import { useEffect, useState } from 'react';
import { formatter, getCurrentDay } from '../../../../../utils/date';
import { getFromLocalStorage, storeInLocalStorage } from '../../../../common/HelperFunctions';
import { storageKeys } from '../../../../sales/orderProcessing/config';
import { filterConfigProvider } from '../../NewComponent/GlobalFilter';

export const useFilterController = ({
  domain,
  defaultDate,
}: {
  domain: string;
  activeTab?: number;
  defaultDate?: any;
}) => {
  const [filters, setFilters] = useState<any>(getFilterMerged(domain));

  useEffect(() => {
    const filterMerged = getFilterMerged(domain);
    setFilters({ ...filterMerged, queryString: '', date: defaultDate });
    updateDataInLocalStorage({ ...filterMerged, queryString: '', date: defaultDate });
  }, [domain]);

  const updateDataInLocalStorage = (data: any) => {
    storeInLocalStorage(storageKeys.FILTER, { ...data }, domain);
  };

  const handlePageChange = ({ offset, limit, page }: { limit: number; offset: number; page: number }) => {
    const filterMerged = { ...filters, pagination: { page, limit, offset } };
    setFilters({ ...filterMerged });
    updateDataInLocalStorage(filterMerged);
  };

  const handleDateRangeChange = (label: string, changeDate: { start: string; end: string }) => {
    const start = formatter(changeDate.start).format('DD MMM YYYY');
    const end = formatter(changeDate.end).format('DD MMM YYYY');
    try {
      const filterMerged = { ...filters, date: { start, end } };
      setFilters({ ...filterMerged });
      updateDataInLocalStorage(filterMerged);
    } catch (err) {}
  };
  const handleSearchInputChange = (queryString?: string | number) => {
    const filterMerged = {
      ...filters,
      queryString,
      pagination: DEFAULT_PAGINATION_OPTION,
    };
    setFilters({ ...filterMerged });
    updateDataInLocalStorage(filterMerged);
  };
  const handleFilterChange = async (list: any[], apiCall: boolean = true) => {
    const filterValue = filterProcessor(list);
    const filterData = filterValue?.filter?.((item: any) => item?.value?.length > 0) || [];
    try {
      const filterMerged = {
        ...filters,
        filter: filterData,
        filterMenu: list,
      };
      setFilters({ ...filterMerged });
      updateDataInLocalStorage(filterMerged);
    } catch (err) {}
  };
  const handleTableSorting = (sort: any[]) => {
    const filterMerged = { ...filters, sort };
    setFilters({ ...filterMerged });
    updateDataInLocalStorage(filterMerged);
  };
  return {
    handleSearchInputChange,
    handlePageChange,
    handleFilterChange,
    handleDateRangeChange,
    handleTableSorting,
    filters,
    setFilters,
  };
};

export const date = {
  start: getCurrentDay(),
  end: getCurrentDay(),
};

export const DEFAULT_PAGINATION_OPTION = {
  page: 1,
  limit: 50,
  offset: 0,
};

export const initialFilter = {
  queryString: '',
  pagination: DEFAULT_PAGINATION_OPTION,
  date: {},
  filterMenu: {},
  filter: [],
};

const getFilterMerged = (domain: any) => {
  const mergeLedgerStorageGet = getFromLocalStorage(`${domain}-${storageKeys.FILTER}`);

  const {
    filterMenu: existingFilterMenu,
    date: existingDate,
    filter: existingFilterData,
    queryString: existingSearchText,
    pagination: existingPagination,
    sort: existingSort,
  } = mergeLedgerStorageGet || {};
  const defaultFilterMenu = structuredClone({
    ...filterConfigProvider(domain)?.menu,
  });

  const filterMenu = existingFilterData?.length > 0 ? existingFilterMenu : defaultFilterMenu;
  const date = existingDate?.start ? existingDate : { start: getCurrentDay(), end: getCurrentDay() };

  const filterData = existingFilterData || [];
  const queryString = existingSearchText?.length > 0 ? existingSearchText : '';
  const pagination = existingPagination?.page ? existingPagination : DEFAULT_PAGINATION_OPTION;
  const sort = existingPagination?.sort?.length > 0 ? existingSort : [];

  const filterMerged = {
    date,
    filter: filterData,
    filterMenu,
    queryString,
    sort,
    pagination,
  };

  return filterMerged;
};

export const filterProcessor = (values: any): string | null | any => {
  if (!values) {
    return null;
  }

  const filterData = Object.keys(values).map(
    key =>
      // remove 'userOption' default value
      key !== 'userOption' && {
        column: `${values[key].value}`,
        ...(values[key]?.isDateFilter
          ? {
              dateFilter: values[key].dateFilter || '',
            }
          : {
              value: values[key].selectedIdList.length ? values[key].selectedIdList : '',
            }),
        ...(values[key]?.filterType && {
          filterType: values[key].filterType || '',
        }),
      },
  );

  return filterData.filter(data => data);
};
