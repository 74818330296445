import React, { useEffect, useState } from 'react';
import { PanelHeader, PanelStyled } from '../../../common/configuration';
import { BreadCrumb, Button, Download, Icon } from '../../../../v4/components';
import { getBreadCrumb } from './config';
// AVERAGE_BASE
import View from './View';
import { EVENT_OPERATION } from '../../../../v4/constants/EventOperation';
import { DetailStyled } from './DetailStyled';
import { refGenerator } from '../../../../utils';
import { refValidator } from '../../../../utils/refGenerator';
import { getCurrentMonth, getCurrentYear } from '../../../../utils/date';
import { ALERT_TYPE } from '../../../../v4/constants/AlertType';
import withAlert from '../../../../utils/composition/withAlert';
import { SBD_TARGET } from '../../../../data/enums/Route';
import * as downloadService from '../../../base/download.service';
import history from '../../../../utils/history';
import { DOWNLOAD_DOMAIN } from '../../../../data/enums/GraphQL';
import { getPermissionForSbdTargets } from '../../../base/permission';
import { getSBDDynamicTitleFromSetting } from '../../../configuration/sbd/config';

const TargetDistributionDetail = props => {
  const {
    serverResponseWaiting,
    match,
    displayAlert,
    createAutomatedSbdTarget,
    updateAutomatedSbdTarget,
    getAutomatedSbdTargetDetails,
    calculateAutomatedSbdTarget,
    downloadReport,
  } = props;

  const id = parseInt(match.params.id, 10);

  const permission = getPermissionForSbdTargets();

  const [details, setDetails] = useState({
    duration: {
      year: getCurrentYear(),
      month: getCurrentMonth() + 1,
    },
    target: '',
    // averageBase:AVERAGE_BASE.SAME_QUARTER,
    monthName: '',
    id: null,
    list: [],
  });
  const [crudMode, setcrudMode] = useState(id && id !== '0' ? EVENT_OPERATION.READ : EVENT_OPERATION.CREATE);

  const [enableValidation, setenableValidation] = useState(false);
  const [calculateClicked, setcalculateClicked] = useState(false);

  const read = crudMode === EVENT_OPERATION.READ;
  const update = crudMode === EVENT_OPERATION.UPDATE;
  const create = crudMode === EVENT_OPERATION.CREATE;
  const [firstTime, setfirstTime] = useState(true);
  const handleEditIconClick = () => {
    setcrudMode(EVENT_OPERATION.UPDATE);
  };

  const handleCancelClick = () => {
    if (create) {
      setDetails({
        duration: {
          year: 2023,
          month: getCurrentMonth() + 1,
        },
        target: '',
        // averageBase: AVERAGE_BASE.SAME_QUARTER,
        monthName: '',
        list: [],
      });
      setcalculateClicked(false);
    } else {
      setcrudMode(EVENT_OPERATION.READ);
      getData();
    }
  };

  const validation = refGenerator(['target']);

  const handleSaveClick = () => {
    const formValidation = refValidator(validation);
    if (!formValidation) {
      setenableValidation(true);
    } else {
      if (create) {
        createAutomatedSbdTarget(
          {
            automatedSalesTargetId: details?.id,
          },
          {
            handleSuccess: response => {
              displayAlert(ALERT_TYPE.SUCCESS, 'SBD Target Created Successfully');
              setTimeout(() => {
                history.push(`/${SBD_TARGET}`);
              }, 1200);
            },
            handleError: error => {
              displayAlert(ALERT_TYPE.DANGER, error);
            },
          },
        );
      } else {
        const formatedInput = {
          month: details?.duration?.month,
          year: details?.duration?.year,
          national_target: Number(details?.target),
          id: details?.id,
        };
        updateAutomatedSbdTarget(
          {
            input: formatedInput,
          },
          {
            handleSuccess: response => {
              displayAlert(ALERT_TYPE.SUCCESS, 'SBD Target Updated Successfully');
              setTimeout(() => {
                history.push(`/${SBD_TARGET}`);
              }, 1200);
            },
            handleError: error => {
              displayAlert(ALERT_TYPE.DANGER, error);
            },
          },
        );
      }
    }
  };

  const getSumedResult = list => {
    const sumedArray = Object.values(
      list?.reduce((acc, item) => {
        const categoryId = item.categoryId;

        if (acc[categoryId]) {
          acc[categoryId].outletTarget += item.outletTarget;
        } else {
          acc[categoryId] = { ...item };
        }

        return acc;
      }, {}),
    );
    return sumedArray;
  };

  const handleCalculateTarget = () => {
    const formValidation = refValidator(validation);
    if (!formValidation) {
      setenableValidation(true);
    } else {
      const formatedInput = {
        month: details?.duration?.month,
        year: details?.duration?.year,
        national_target: Number(details?.target),
        create_target: !!(create && firstTime),
      };
      calculateAutomatedSbdTarget(
        {
          input: formatedInput,
        },
        {
          handleSuccess: response => {
            const result = response?.data?.calculateAutomatedSbdTarget;
            if (result) {
              setDetails({
                ...details,
                list: getSumedResult(result?.detail),
                id: result?.automatedSbdTargetId,
              });
              setcalculateClicked(true);
              setfirstTime(false);
            }
          },
          handleError: error => {
            displayAlert(ALERT_TYPE.DANGER, error);
          },
        },
      );
    }
  };
  const handleDateChange = (event, firstParam = '', paramList = []) => {
    details[firstParam] = event;
    setDetails({ ...details, [firstParam]: event });
    setcalculateClicked(false);
    setfirstTime(true);
  };

  const handleInputChange = e => {
    setDetails({ ...details, [e.target.name]: e.target.value });
    setcalculateClicked(false);
  };

  const handleDownloadClick = domain => {
    downloadReport(
      {
        input: {
          domain,
          reportType: 'csv',
          filter: {
            filters: [
              {
                column: 'id',
                value: id?.toString(),
              },
            ],
          },
        },
      },
      {
        handleSuccess: response => {
          downloadService.resolver(response.data.downloadReport.file);
          displayAlert(ALERT_TYPE.SUCCESS, 'Successfully downloaded file');
        },
        handleError: () => {
          displayAlert(ALERT_TYPE.CUSTOM_DANGER, 'Cannot download file');
        },
      },
    );
  };

  const getData = () => {
    getAutomatedSbdTargetDetails(
      {
        getAutomatedSbdTargetDetailsId: id,
      },
      {
        handleSuccess: res => {
          if (res) {
            const result = res?.data?.getAutomatedSbdTargetDetails;
            if (result) {
              const sbdDetails = result?.automated_sbd_target_details?.map(a => ({
                outletTarget: parseFloat(a?.outlet_target),
                categoryId: a?.category?.id,
                category: a?.category?.title,
              }));
              const formatedDetail = {
                duration: {
                  year: result?.year,
                  month: result?.month,
                },
                target: result?.national_target,
                monthName: result?.monthName,
                id: result?.id,
                list: getSumedResult(sbdDetails),
              };
              setDetails(formatedDetail);
            }
          }
        },
        handleError: error => {
          displayAlert(ALERT_TYPE.DANGER, error);
        },
      },
    );
  };

  useEffect(() => {
    if (id) {
      getData();
    }
  }, [id]);

  return (
    <DetailStyled>
      <div className="section-header">
        <PanelStyled>
          <BreadCrumb list={getBreadCrumb({ title: getSBDDynamicTitleFromSetting() })} />
          <PanelHeader>
            <div className="flex items-center justify-between m-0 flex-1">
              <h2>
                {create
                  ? 'Create SBD Target'
                  : read
                  ? `${details?.monthName}, ${details?.duration?.year}`
                  : `Edit ${details?.monthName}, ${details?.duration?.year}` || ''}
              </h2>
              {!read && calculateClicked && (
                <div>
                  <Button small disabled={serverResponseWaiting} secondary onClick={() => handleCancelClick()}>
                    <span>Cancel</span>
                  </Button>
                  <Button primary disabled={serverResponseWaiting} small onClick={() => handleSaveClick()}>
                    <span>Save</span>
                  </Button>
                </div>
              )}
              {read ? (
                <div className="flex-display">
                  <Download handleDownloadClick={() => handleDownloadClick(DOWNLOAD_DOMAIN.SBD_TARGET)} />
                  <Button secondary iconBtnSmall onClick={handleEditIconClick} className="ml-16">
                    <Icon iconName="pencil" />
                  </Button>
                </div>
              ) : (
                ''
              )}
            </div>
          </PanelHeader>
        </PanelStyled>
      </div>
      <div className="section-content">
        <div className="create-ui">
          <View
            read={read}
            update={update}
            data={details}
            handleCalculateTarget={handleCalculateTarget}
            handleDateChange={handleDateChange}
            handleInputChange={handleInputChange}
            refsObj={validation}
            enableErrorDisplay={enableValidation}
            loading={serverResponseWaiting}
            calculateClicked={calculateClicked}
            permission={permission}
            id={id}
          />
        </div>
      </div>
    </DetailStyled>
  );
};

export default withAlert()(TargetDistributionDetail);
