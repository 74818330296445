import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { ALERT_TYPE } from '../../../../../data/enums/AlertType';

export const useFormController = ({ customSchema, defaultValues, displayAlert, msg }: any) => {
  const {
    handleSubmit: baseHandleSubmit,
    watch,
    control,
    reset,
    setValue,
    clearErrors,
    trigger,
    getValues,
    formState: { errors },
  } = useForm<any>({
    defaultValues: { ...defaultValues },
    mode: 'onChange',
    resolver: customSchema && yupResolver(customSchema),
  });
  const handleSubmit = (onValid: any, onInvalid?: any) =>
    baseHandleSubmit(
      data => {
        onValid(data);
      },
      errors => {
        msg?.length > 0 && displayAlert(ALERT_TYPE.WARNING, msg);
        if (onInvalid) {
          onInvalid(errors);
        }
      },
    );

  return {
    handleSubmit,
    watch,
    control: control ?? null,
    reset,
    errors,
    setValue,
    clearErrors,
    trigger,
    getValues,
  };
};
