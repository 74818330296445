import { SRN } from '../../../../data/enums/Route';
import { breadCrumbConfig } from '../config';
import { totalPriceMapper } from '../create/config';
import { EVENT_OPERATION } from '../../../../data/enums/EventOperation';
import { MESSAGE_EVENT_OPERATION } from '../../../../data/enums/SuccessMessage';
import { getTodaysDate } from '../../../../utils/date';
import LEDGERS from '../../../../data/enums/Ledgers';
import { PAYMENT_MODE } from '../../orderProcessing/received/salesInvoice/customerDetail/config';
import { VAT_AMOUNT } from '../../../../data/enums/GeneralConstants';

const breadCrumb = [
  ...breadCrumbConfig,
  {
    id: 1,
    title: 'Returns',
    path: `/${SRN}`,
    active: true,
  },
];

const orderFormatter = (orders = []) => {
  const updatedOrders = orders.map(item => ({
    ...item,
    returnQuantity: item.quantity,
  }));

  return updatedOrders;
};

const dataMapper = (data = {}) => ({
  id: data.id || null,
  srnInvoiceNumber: data.SRNInvoiceNumber || '',
  lines: data.SRNDetails || [],
  salesInvoiceNumber: data.invoiceNumber || '',
  srnAmount: data.srnAmount,
  Distributor: data.Distributor || { serviceUsed: {} },
  cashStatus: data.paymentMode === LEDGERS.CASH.title,
  reason: data.reason || '',
  paymentMode:
    data?.paymentMode === PAYMENT_MODE?.CREDIT_ROSIA ? PAYMENT_MODE?.CREDIT_BILLING : data?.paymentMode ?? null,
  srnDate: data.srnDate || getTodaysDate(),
  date: data.srnDate || getTodaysDate(),
  isOldBill: data.isOldBill,
  inboundPending: data.inboundPending,
  Customer: {
    id: data.Customer ? data.Customer.id : null,
    name: data.Customer ? data.Customer.name : '',
    address: data.Customer ? data.Customer.address : '',
    panNumber: data.Customer ? data.Customer.panNumber : '',
  },
  RetailOutlet: {
    id: data.RetailOutlet ? data.RetailOutlet.id : '',
    title: data.RetailOutlet ? data.RetailOutlet.title : '',
    Customers: data.RetailOutlet ? data.RetailOutlet.Customers : [],
  },
  orders: orderFormatter(data.SRNDetails) || [],
  billDiscountDetails: [],
  amount: totalPriceMapper(data.srnAmount ? data.srnAmount || {} : {}),
  invoiceDate: data?.Invoice?.invoiceDate || null,
});

// const formMapper = (element, distributorId) => {
//   const orderDetails = element.Orders
//     ? orderFormatter(element.Orders) : { orders: [], totalValidOrders: 0 };

//   return {
//     ...summaryDetailsMapper(element, distributorId),
//     uuid: element.uuid || '',
//     orders: orderDetails.orders || [],
//     totalValidOrders: orderDetails.totalValidOrders || 0,
//     amount: totalPriceMapper(element.amountDetails ? element.amountDetails || {} : {}),
//     billDiscountDetails: element.billDiscountDetail ? element.billDiscountDetail || [] : [],
//     approveId: element.approveId || null,
//   };
// };
const calculateRelativeDiscount = (referenceAmount = 1, referenceDiscount, amount) =>
  (amount * referenceDiscount) / referenceAmount;
const getFormattedSkuLines = (list, oldBillStatus, totalList) =>
  list.map(item => {
    const { amount, discountValue, discountTypeId, ...srnLineAmount } = item.amountDetails;
    const taxableAmt =
      srnLineAmount.subTotal -
      srnLineAmount.promotionDiscount -
      srnLineAmount.topUpDiscount -
      calculateRelativeDiscount(totalList.subTotal, Number(totalList.billDiscount), srnLineAmount.subTotal) -
      calculateRelativeDiscount(totalList.subTotal, Number(totalList.tradeDiscount), srnLineAmount.subTotal);
    return {
      id: item.id,
      orderId: oldBillStatus ? null : item.orderId,
      skuId: item.skuId,
      skuBatchId: item.skuBatchId,
      batchName: item.SKUBatch.batchName,
      stockType: item.stockType,
      returnQuantity: item.returnQuantity,
      quantity: item.quantity,
      srnLineAmount: {
        ...srnLineAmount,
        billDiscount: calculateRelativeDiscount(
          totalList.subTotal,
          Number(totalList.billDiscount),
          srnLineAmount.subTotal,
        ),
        tradeDiscount: calculateRelativeDiscount(
          totalList.subTotal,
          Number(totalList.tradeDiscount),
          srnLineAmount.subTotal,
        ),
        taxAmount: 0.13 * taxableAmt,
        taxableAmount: taxableAmt,
        grossAmount: srnLineAmount.rate * item.returnQuantity * (1 + (VAT_AMOUNT || 0)),
        netAmount: taxableAmt + 0.13 * taxableAmt,
        rate: Number(srnLineAmount.rate),
        discountAmount: Number(srnLineAmount.discountAmount),
        promotionDiscount: Number(srnLineAmount.promotionDiscount),
      },
    };
  });

const customerId = data => {
  const isBillingUser = JSON.parse(localStorage.getItem('isBillingUser'));
  const { details } = data;
  const paymentMode = details?.paymentMode || PAYMENT_MODE.CASH;
  if (!isBillingUser) {
    return details?.Customer?.id || null;
  }
  if ([PAYMENT_MODE.CASH, PAYMENT_MODE.COD].includes(paymentMode)) {
    return details?.Customer?.id || LEDGERS?.[paymentMode]?.ledger_id;
  }
  return details?.Customer?.id;
};

const getPaymentMode = data => {
  const isBillingUser = JSON.parse(localStorage.getItem('isBillingUser'));
  const { CREDIT, CREDIT_ROSIA, CREDIT_BILLING } = PAYMENT_MODE;
  const { details } = data;
  if (isBillingUser) {
    return details?.paymentMode === CREDIT_BILLING ? CREDIT_ROSIA : details?.paymentMode ?? CREDIT;
  }
  return details?.cashStatus === true ? LEDGERS.CASH.title : CREDIT;
};

const getPayload = data => {
  return {
    invoiceNumber: data.details.salesInvoiceNumber,
    srnInvoiceNumber: data.details.srnInvoiceNumber,
    reason: data.details.reason,
    distributorId: data.details.Distributor.id,
    retailOutletId: data.details.RetailOutlet.id,
    customerId: customerId(data),
    customerName: data?.details?.Customer?.name ?? '',
    isOldBill: data.isOldBill,
    paymentMode: getPaymentMode(data),
    srnLines: getFormattedSkuLines(data.orders, data.oldBillStatus, data.amount),
    srnDate: data.details.srnDate,
    srnAmount: {
      ...data.amount,
      discountAmount: Number(data.amount.discountAmount),
      promotionDiscount: Number(data.amount.promotionDiscount),
      billDiscount: Number(data.amount.billDiscount),
      tradeDiscount: Number(data.amount.tradeDiscount),
    },
    type: data.srnType,
    lineId: data.details.lineId,
  };
};

const crudSuccess = {
  [EVENT_OPERATION.APPROVE]: {
    objectMapper: data => ({
      id: data.id,
      input: getPayload(data),
    }),
    responseName: 'approveSalesReturn',
    message: `SRN ${MESSAGE_EVENT_OPERATION.APPROVE}`,
  },
  [EVENT_OPERATION.CANCEL]: {
    objectMapper: data => ({ id: data.id }),
    responseName: 'cancelSalesReturn',
    message: ' SRN rejected successfully!',
  },
};
export { breadCrumb, crudSuccess as crudRequestConfig, dataMapper };
