import { FILTER } from '../../../../data/enums/filter';
import { INCENTIVE } from '../../../../data/enums/Route';
import { apolloClient, errorProcessor } from '../../../../data/services/common';
import { getFilterConfig } from '../../../../utils/filter';
import { GET_ROLE_LIST } from '../../../configuration/user/userRole/API';


export const filterConfigProvider = (route: string) => {
  switch (route) {
    case `${INCENTIVE}`:
      return getFilterConfig([FILTER.USER_GROUP]);
    default:
      return getFilterConfig([]);
  }
};
export const mapFilterData = (
  data: any[],
  idLabel = 'id',
  titleLabel = 'title'
) => {
  const filterOptions = data.map((object) => ({
    id: object[idLabel],
    title: object[titleLabel],
  }));
  return { data: { rows: filterOptions } };
};


export const getUserRoleList = async () =>
  apolloClient
    .query({
      query: GET_ROLE_LIST,
      variables: {
      },
    })
    .then((res) => {
      const roleList = res?.data?.roles?.rows || [];
      return roleList;
    })
    .catch((err) => errorProcessor(err));
