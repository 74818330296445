import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { BreadCrumb, Badge, Button } from '../../../../v4/components';
import { PanelHeader, PanelStyled } from '../../../common/configuration';
import { getbreadCrumb } from './config';
import CustomSelect from '../../../../components/CustomSelect';
import withErrorAndLoadingScreen from '../../../../utils/composition/withLoading';
import SBDDetailStyled from './SBDDetailStyled';
import { EVENT_OPERATION } from '../../../../data/enums/EventOperation';
import BasicDetail from './BasicDetail';
import SBDDistribution from './SBDDistribution';
import { getSBDDynamicTitleFromSetting } from '../config';

const propTypes = {
  onIconClick: PropTypes.func.isRequired,
  menu: PropTypes.objectOf({
    channelList: PropTypes.arrayOf({
      id: PropTypes.number,
      title: PropTypes.string,
      Channel: PropTypes.objectOf(Object),
    }).isRequired,
  }).isRequired,
  onChannelSelect: PropTypes.func.isRequired,
  channelId: PropTypes.number.isRequired,
  categoryList: PropTypes.arrayOf(Object).isRequired,
  brandList: PropTypes.arrayOf([
    {
      id: PropTypes.number,
      title: PropTypes.string,
      SKUs: PropTypes.arrayOf({
        id: PropTypes.number,
        title: PropTypes.string,
      }),
    },
  ]).isRequired,
  showBrands: PropTypes.bool.isRequired,
  onCategorySelect: PropTypes.func.isRequired,
  skuList: PropTypes.arrayOf([]).isRequired,
  getSkuDetails: PropTypes.func.isRequired,
  categoryId: PropTypes.number.isRequired,
  brandId: PropTypes.number.isRequired,
  handleSBDSubmit: PropTypes.func.isRequired,
  skus: PropTypes.arrayOf({
    id: PropTypes.number,
    title: PropTypes.string,
  }).isRequired,
  basicDetail: PropTypes.objectOf({
    id: PropTypes.number,
    title: PropTypes.string,
    start: PropTypes.start,
    end: PropTypes.end,
    SbdDetails: PropTypes.arrayOf(Array),
  }).isRequired,
  setCheckedSkuFamilies: PropTypes.func.isRequired,
  checkedSkuFamilies: PropTypes.arrayOf(Object).isRequired,
  onCreateGroup: PropTypes.func.isRequired,
  sbdDetails: PropTypes.objectOf({
    Brand: PropTypes.object,
    Channel: PropTypes.object,
    Category: PropTypes.object,
    SkuGroup: PropTypes.object,
  }).isRequired,
  skuGroupList: PropTypes.arrayOf({
    id: PropTypes.number,
    count: PropTypes.number,
    skus: PropTypes.array,
  }).isRequired,
  totalSkuFamilies: PropTypes.arrayOf({
    id: PropTypes.number,
    title: PropTypes.string,
  }).isRequired,
  loading: PropTypes.bool.isRequired,
};

const SBDDetailView = props => {
  const {
    onIconClick,
    menu,
    onChannelSelect,
    channelId,
    categoryList,
    brandList,
    showBrands,
    onCategorySelect,
    skuList,
    getSkuDetails,
    categoryId,
    brandId,
    handleSBDSubmit,
    skus,
    basicDetail,
    setCheckedSkuFamilies,
    checkedSkuFamilies,
    onCreateGroup,
    sbdDetails,
    skuGroupList,
    totalSkuFamilies,
    loading,
    getSKUFilteredByFamilyId,
    permission,
    sbdId,
  } = props;

  const getCounts = (param, paramId) => paramId !== 0 && sbdDetails[param];

  const channelSBDCount =
    getCounts('Channel', channelId) &&
    getCounts('Channel', channelId).find(d => d.id === channelId) &&
    getCounts('Channel', channelId).find(d => d.id === channelId).count;
  const categorySBDCount = getCounts('Category', channelId);
  const brandSBDCount = getCounts('Brand', channelId);

  const getSBDStatus = id => {
    const skuStatus =
      sbdDetails &&
      sbdDetails?.length !== 0 &&
      sbdDetails?.SkuFamily?.map(groupId => groupId.skus).filter(d => d.includes(id))[0] &&
      sbdDetails?.SkuFamily?.map(groupId => groupId.skus)
        .filter(d => d.includes(id))[0]
        .includes(id);

    // sbdDetailId and sbdSKUCount manupulation
    if (skuStatus) {
      const findSkuFamily = sbdDetails.SkuFamily.map(groupId => groupId).filter(el => el.skus.includes(id))[0];
      const findSKUCount = sbdDetails.SkuGroup.filter(el => el.id === findSkuFamily.id)[0];
      const index = skuList.findIndex(data => data.id === findSkuFamily.skus[0]);

      if (index > -1) {
        skuList[index].sbdDetailsId = findSkuFamily.id;
        skuList[index].count = findSKUCount.count;
      }

      let filteredFindGroup = [];

      sbdDetails.SkuGroup.filter(el =>
        skuGroupList.forEach(({ id }) => {
          if (el.id === id) {
            filteredFindGroup.push(el);
          }
        }),
      );

      filteredFindGroup.forEach(group => {
        const indx = skuGroupList.findIndex(data => data.id === group.id);
        if (indx > -1) {
          skuGroupList[indx].skuCount = group?.count;
        }
      });
    }

    return skuStatus;
  };

  return (
    <Fragment>
      <div className="section-header">
        <PanelStyled>
          <BreadCrumb list={getbreadCrumb({ title: getSBDDynamicTitleFromSetting() })} />
          <PanelHeader>
            <div className="flex items-center justify-between m-0 flex-1">
              <h2>{basicDetail.title}</h2>
              <div className="flex m-0">
                {permission?.update && (
                  <Button
                    secondary
                    iconBtnSmall
                    onClick={() => onIconClick(EVENT_OPERATION.UPDATE)}
                    iconName="pencil"
                  />
                )}
                {permission?.delete && (
                  <Button secondary iconBtnSmall onClick={() => onIconClick(EVENT_OPERATION.DELETE)} iconName="trash" />
                )}
                {(skus.length !== 0 || channelSBDCount) && permission?.create && (
                  <Button
                    secondary
                    iconBtnSmall
                    onClick={() => onIconClick(EVENT_OPERATION.REPLICATE)}
                    iconName="copy"
                  />
                )}
              </div>
            </div>
          </PanelHeader>
        </PanelStyled>
      </div>
      <div className="section-content">
        <div className="create-ui">
          <div className="create-ui-inner tab-less-height">
            <SBDDetailStyled>
              <BasicDetail basicDetail={basicDetail} />
              <section className="sbd-bottom-wrap">
                <div className="first">
                  <CustomSelect
                    small
                    options={menu.channelList}
                    clearable={false}
                    className="custom-select"
                    placeholder="Select Channel"
                    getOptionLabel={({ id, title }) => (
                      <span>
                        {title}
                        {sbdDetails.Channel && sbdDetails.Channel.filter(d => d.id === id)[0] && (
                          <Badge light label={sbdDetails.Channel.filter(d => d.id === id)[0].count} />
                        )}
                      </span>
                    )}
                    getOptionValue={({ id }) => id}
                    value={channelId !== 0 ? menu.channelList.filter(d => d.id === channelId) : []}
                    onChange={event => onChannelSelect(event.id)}
                    isSearchable={false}
                  />
                  {channelId !== 0 && channelSBDCount && <Badge light label={channelSBDCount || 0} />}
                  {categoryList.length === 0 && (
                    <span className="info-text">←← Please select any channel to continue.</span>
                  )}
                </div>

                <div className="sbd-bottom-inner">
                  <div className="sbd-panel">
                    {categoryList.length !== 0 && (
                      <div className="sbd-panel-list">
                        <h3>Category</h3>
                        <ul>
                          {categoryList.map(d => (
                            <li
                              key={d.id}
                              onClick={() => onCategorySelect(d.id)}
                              className={d.id === categoryId ? 'active' : 'inActive'}
                            >
                              {d.title}{' '}
                              {categorySBDCount && categorySBDCount.find(catId => catId.id === d.id) && (
                                <Badge
                                  light
                                  label={
                                    categorySBDCount.find(cat => cat.id === d.id)
                                      ? categorySBDCount.find(cat => cat.id === d.id).count
                                      : 0
                                  }
                                />
                              )}
                            </li>
                          ))}
                        </ul>
                      </div>
                    )}
                    {categoryList.length !== 0 && !showBrands && (
                      <span className="info-text">←← Please select any category to continue.</span>
                    )}
                  </div>
                  <div className="sbd-panel">
                    {showBrands && (
                      <div className="sbd-panel-list">
                        <h3>Brands</h3>
                        <ul>
                          {brandList.map(d => (
                            <li
                              key={d.id}
                              onClick={() => getSkuDetails(d.id)}
                              className={`${d.id === brandId ? 'active' : 'inActive'} ${loading ? 'disabled-btn' : ''}`}
                            >
                              {d.title}
                              {brandSBDCount && brandSBDCount.find(brandId => brandId.id === d.id) && (
                                <Badge light label={brandSBDCount.find(brandId => brandId.id === d.id).count || 0} />
                              )}
                            </li>
                          ))}
                        </ul>
                      </div>
                    )}
                    {categoryList.length !== 0 && showBrands && totalSkuFamilies.length === 0 && (
                      <span className="info-text">←← Please select brand to continue</span>
                    )}
                  </div>
                  {totalSkuFamilies.length > 0 && brandId !== null && (
                    <SBDDistribution
                      skuList={skuList}
                      checkedSkuFamilies={checkedSkuFamilies}
                      onCreateGroup={onCreateGroup}
                      setCheckedSkuFamilies={setCheckedSkuFamilies}
                      getSBDStatus={getSBDStatus}
                      skus={skus}
                      handleSBDSubmit={handleSBDSubmit}
                      skuGroupList={skuGroupList}
                      totalSkuFamilies={totalSkuFamilies}
                      getSKUFilteredByFamilyId={getSKUFilteredByFamilyId}
                      permission={permission}
                      sbdId={sbdId}
                      loading={loading}
                    />
                  )}
                </div>
              </section>
            </SBDDetailStyled>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default withErrorAndLoadingScreen(SBDDetailView);
