import styled from 'styled-components';
import { Loading } from '../../../../../components';
import withAlert from '../../../../../utils/composition/withAlert';
import { Button } from '../../../../../v4/components';
import PageHeader from '../../../../base/PageHeader';
import { DialogFormWrapper } from '../../../../common';
import { PanelStyled } from '../../../../common/configuration';
import { useDetailsController } from '../Controllers/useDetails.controller';
import { breadCrumb, formConfig, TABLE_CONFIG } from './config';
import { DetailsStyled } from './Details.style';
import { Dialog } from './Dialog/Dialog';
import { SummaryView } from './Summary';
import { TableConfig } from './tableConfig';

const Details = ({ ...props }) => {
  const {
    dialog,
    handleDialogFormSubmit,
    formParam,
    formParam: { watch, errors },
    handleClose,
    tempList,
    summaryObj,
    mode,
    mode: { isUpdate, isCreate, isRead },
    handleCancelClick,
    handleEditIconClick,
    handleSave,
    load,
    handleIconClick,
    bulkCompo,
    list,
    permission,
    setSummaryObj,
  } = useDetailsController({ ...props });
  const { displayAlert } = props;
  return (
    <DetailsStyled>
      {load && <Loading title="Loading Details" />}

      {dialog?.type && (
        <DialogFormWrapper
          type={dialog.type}
          dialogElement={dialog.element}
          onDialogSubmit={handleDialogFormSubmit}
          formConfig={formConfig[dialog.type]}
          onDialogCancel={handleClose}
          activeStatus
          hookformBtnEnabled={![TABLE_CONFIG?.DELETE, TABLE_CONFIG?.BULK_DELETE]?.includes(dialog?.type)}
          renderDialog={() => (
            <>
              {[TABLE_CONFIG.CREATE, TABLE_CONFIG.UPDATE]?.includes(dialog.type) && (
                <Dialog
                  formParam={formParam}
                  dialog={dialog}
                  handleDialogFormSubmit={handleDialogFormSubmit}
                  tempList={tempList}
                  displayAlert={displayAlert}
                  summaryObj={summaryObj}
                />
              )}
              {dialog?.type === TABLE_CONFIG?.DELETE && (
                <>
                  <span>Are you sure you want to delete 1 record?</span>
                </>
              )}
              {dialog?.type === TABLE_CONFIG?.BULK_DELETE && (
                <>
                  <span>Are you sure you want to delete these record?</span>
                </>
              )}
            </>
          )}
        />
      )}
      <div className="section-header">
        <PanelStyled>
          <PageHeader
            breadCrumb={breadCrumb}
            config={{
              title: mode?.isCreate
                ? 'Create Incentive'
                : mode?.isUpdate
                  ? 'Edit Incentive'
                  : 'Incentive',
              download: false,
              add: permission?.create && !mode?.isRead,
              edit: permission?.update && mode?.isRead,
            }}
            handleCancelClick={handleCancelClick}
            crudMode={mode?.crudMode}
            handleEditIconClick={handleEditIconClick}
            hookformBtnEnabled
          />
        </PanelStyled>
      </div>
      <div className={` section-content custom-scroll `}>
        <SummaryView formParam={formParam} handleSave={handleSave} list={list} setSummaryObj={setSummaryObj} classes={`${mode?.isRead ? 'disabled' : ''}`} />
        <SubHeaderStyled className="sub-header">
          <span><b>{tempList?.length ?? 0} Items</b></span>
          {!isRead && <Button
            secondary
            small
            outlined
            iconName="plus"
            title="Add"
            onClick={() => handleIconClick({ type: TABLE_CONFIG.CREATE })}
            className="ml-16 outlined"
          >
          </Button>}
        </SubHeaderStyled>
        <TableConfig data={tempList} handleIconClick={handleIconClick} bulkCompo={bulkCompo} mode={mode} />
      </div>
    </DetailsStyled>
  );
};

export default withAlert()(Details);

export const SubHeaderStyled = styled.div`
    &.sub-header{
    display:flex;
    justify-content: space-between;
    padding: 8px 48px;
    align-items: center;
    background-color:#F7F8F9;
  }
`
