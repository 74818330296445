import * as yup from 'yup';

export const dialogSchema = yup.object().shape({
  measure: yup.object().required('Measure is required'),
  items: yup.array().of(
    yup.object({
      rangeFromValueType: yup.string().required('Required'),
      rangeFromValueCompare: yup.string().required('Required'),
      rangeFromValue: yup.number().required('Required'),
      rangeToValueType: yup.string().required('Required'),
      rangeToValueCompare: yup.string().required('Required'),
      rangeToValue: yup.number().required('Required'),
      amount: yup.number().required('Required'),
    }),
  ),
});
export const summarySchema = yup.object().shape({
  userGroup: yup.object().required('User Group is required'),
});
