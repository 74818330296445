import EVENT_OPERATION from '../../../../../data/enums/EventOperation';
import { USER_TARGET } from '../../../../../data/enums/Route';
import { breadCrumbConfig } from '../../config';
import { CONFIRMATION_CONFIG, FORM_CONFIG } from '../../../../../data/enums/config';
import { refGenerator } from '../../../../../utils';
import moment from 'moment';

const Title = '';

const requiredList = ['distributorId', 'month', 'target'];

const breadCrumb = [
  ...breadCrumbConfig,
  {
    id: 1,
    title: 'User Target',
    path: `/${USER_TARGET}`,
    active: true,
  },
];

const formConfig = {
  [EVENT_OPERATION.READ]: {
    [FORM_CONFIG.MAPPER]: element => ({
      user: element.user,
      ulpo: element.ulpo,
      tls: element.tls,
      productivity: element.productivity,
      active_outlets: element.active_outlets,
      dgp: element.dgp,
      sbd: element.sbd,
      bill_cuts: element.bill_cuts,
      perfect_call_target: element?.perfect_call_target
    }),
    [FORM_CONFIG.TITLE]: '',
    [EVENT_OPERATION.UPLOAD]: `${EVENT_OPERATION.READ} ${Title}`,
    [FORM_CONFIG.REFS_OBJ]: refGenerator(['ulpo', 'duration']),
    [FORM_CONFIG.VALIDATION_REQUIRED]: false,
  },
  [EVENT_OPERATION.UPDATE]: {
    [FORM_CONFIG.MAPPER]: element => ({
      user: element.user,
      ulpo: element.ulpo,
      tls: element.tls,
      productivity: element.productivity,
      active_outlets: element.active_outlets,
      dgp: element.dgp,
      sbd: element.sbd,
      bill_cuts: element.bill_cuts,
      perfect_call_target: element?.perfect_call_target
    }),
    [FORM_CONFIG.TITLE]: 'Edit',
    [EVENT_OPERATION.UPLOAD]: `${EVENT_OPERATION.UPDATE} ${Title}`,
    [FORM_CONFIG.REFS_OBJ]: refGenerator(['ulpo', 'duration']),
    [FORM_CONFIG.VALIDATION_REQUIRED]: false,
    buttonName: 'Update',
  },
  [EVENT_OPERATION.UPLOAD]: {
    [FORM_CONFIG.TITLE]: 'UPLOAD',
    [FORM_CONFIG.REFS_OBJ]: refGenerator(requiredList),
    [FORM_CONFIG.VALIDATION_REQUIRED]: true,
  },

  ...CONFIRMATION_CONFIG,
};

const dateFormat = _date => {
  const dateFormatter = moment(_date, 'YYYY/MM/DD');
  const formatDate = dateFormatter.format('ll');

  return formatDate;
};

export { breadCrumb, formConfig, dateFormat };
