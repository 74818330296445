import { compose } from 'recompose';
import { query } from './API';
import OnlineQR from './OnlineQR';
import withRequestTracker from '../../../../utils/composition/withRequestTracker';
import { DOWNLOAD_REPORT } from '../../../common/GqlAPI';

const composedOnlineQR = compose(
  DOWNLOAD_REPORT,
  withRequestTracker({
    query,
    mutation: {
      downloadReport: DOWNLOAD_REPORT,
    },
  }),
)(OnlineQR);

export default composedOnlineQR;
