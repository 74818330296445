import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import { PanelHeader } from '../common/configuration';
import * as dateUtil from '../../utils/date';
import Search from '../../components/Search';
import {
  BreadCrumb,
  Button,
  DatePicker,
  SingleDatePicker,
  Filter,
  ViewType,
  ColumnSelect,
  Download,
  menuAction,
  Icon,
} from '../../v4/components';
import { PERMISSION_OBJ } from '../../data/enums/Permission';
import { EVENT_OPERATION } from '../../v4/constants/EventOperation';

const defaultHeaderConfig = {
  title: '',
  search: false,
  date: false,
  upload: false,
  csvUpload: false,
  download: false,
  filter: false,
  viewType: false,
  hookformBtnEnabled: false,
};

const propTypes = {
  onIconClick: PropTypes.func,
  display: PropTypes.shape({
    searchBox: PropTypes.bool,
  }),
  config: PropTypes.shape({
    title: PropTypes.string,
    search: PropTypes.bool,
    date: PropTypes.bool,
    upload: PropTypes.bool,
    csvUpload: PropTypes.bool,
    download: PropTypes.bool,
    filter: PropTypes.bool,
    create: PropTypes.bool,
    add: PropTypes?.bool,
    edit: PropTypes?.bool,
  }),
  breadCrumb: PropTypes.instanceOf(Array),
  filter: PropTypes.shape({
    date: PropTypes.shape({
      start: PropTypes.string,
      end: PropTypes.string,
    }),
    menu: PropTypes.instanceOf(Object),
    onFilterChange: PropTypes.func,
  }),
  downloadConfig: PropTypes.shape({
    type: PropTypes.string,
    domain: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(Array)]),
    apiTitle: PropTypes.string,
  }),
  imgUrl: PropTypes.bool,
  viewTypeConfig: PropTypes.shape({
    value: PropTypes.any,
    list: PropTypes.instanceOf(Array),
  }),
  handleViewTypeChange: PropTypes.func,
  crudMode: PropTypes.string,
  hookformBtnEnabled: PropTypes.bool,
};

const defaultProps = {
  display: {
    searchBox: false,
  },
  config: {
    ...defaultHeaderConfig,
  },
  onIconClick: () => null,
  breadCrumb: [],
  filter: {
    refreshState: false,
    updateMenu: false,
    date: {
      start: dateUtil.getStartOfCurrentMonth(),
      end: dateUtil.getCurrentDay(),
    },
    menuList: {},
    onFilterChange: () => null,
  },
  downloadConfig: {
    type: '',
    domain: '',
    apiTitle: '',
  },
  imgUrl: false,
  handleViewTypeChange: () => null,
  viewTypeConfig: {
    value: '',
    list: [],
  },
};

const PageHeader = ({
  display, // searchBox, filter, menu
  handleSearchChange, // this.handleSearchChange,
  clearSearchText, // this.clearSearchText
  handleDownloadClick, // this.handleDownloadClick
  controlDisplay, // this.controlDisplay
  resetFilter,
  handleDateRangeChange,
  handleSingleDateRangeChange,
  handleUploadClick,
  handleCreateClick,
  config,
  breadCrumb,
  onIconClick,
  downloadConfig,
  filter,
  children,
  imgUrl,
  labelConfig,
  columnSelectChange,
  handleViewTypeChange,
  viewTypeConfig,
  createTitle,
  menuConfigList,
  handleMultiReplicateType,
  subId,
  queryParameters,
  crudMode,
  handleCancelClick,
  disableButton,
  handleEdit,
  handleSave,
  handleEditIconClick,
  hookformBtnEnabled,
}) => (
  <Fragment>
    {breadCrumb.length > 0 ? <BreadCrumb list={breadCrumb} /> : null}
    <PanelHeader>
      <h2 className={(config.title && config.title.length) > 49 ? 'long-title' : ' '} view={config.title}>
        {imgUrl && (
          <span>
            <img src="/image/icons/user.svg" alt="" />
          </span>
        )}
        {config.title}
      </h2>
      {
        <div className="filter-wrap">
          {config.viewType ? (
            <ViewType
              handleDropDownChange={handleViewTypeChange}
              list={viewTypeConfig.list}
              value={viewTypeConfig.value}
            />
          ) : null}
          {config.search ? (
            <Search handleSearchInput={handleSearchChange} searchText={queryParameters?.search} />
          ) : null}
          {config.columSelect ? (
            <ColumnSelect labelConfig={labelConfig} onColumnSelectChange={columnSelectChange} />
          ) : null}
          {config.date ? <DatePicker date={filter.date} onChange={handleDateRangeChange} /> : null}
          {config.singleDate ? (
            <SingleDatePicker date={filter.date.end} onChange={handleSingleDateRangeChange} />
          ) : null}
          {config.filter ? (
            <Filter
              updateMenu={filter.updateMenu || false}
              refreshState={filter.refreshState || false}
              metaData={filter.metaData || {}}
              menuList={filter.menuList || []}
              onFilterChange={filter.onFilterChange}
              getFilterData={filter.getFilterData}
              subId={subId}
            />
          ) : null}
          {(config.add || crudMode === EVENT_OPERATION.UPDATE) && (
            <>
              <div className="button-wrap">
                <Button small secondary title="Cancel" onClick={() => (handleCancelClick ? handleCancelClick() : {})} />
              </div>
              <div className="button-wrap">
                {hookformBtnEnabled ? (
                  <Button
                    type="submit"
                    form="hook-form"
                    primary
                    small
                    disabled={disableButton ? disableButton : false}
                    title="Save"
                  />
                ) : (
                  <Button
                    form="hook-form"
                    primary
                    small
                    title="Save"
                    disabled={disableButton ? disableButton : false}
                    onClick={() => {
                      if (config.add) {
                        return handleSave && handleSave();
                      } else if (crudMode === EVENT_OPERATION.UPDATE) {
                        return handleEdit && handleEdit();
                      }
                      return {};
                    }}
                  />
                )}
              </div>
            </>
          )}
          {config.edit && (
            <div>
              <Button
                secondary
                iconBtnSmall
                onClick={() => handleEditIconClick && handleEditIconClick()}
                className="ml-16"
              >
                <Icon iconName="pencil" />
              </Button>
            </div>
          )}
          {config.upload ? (
            <div>
              <Button
                secondary
                iconBtnSmall
                iconName="upload"
                onClick={() => handleUploadClick(EVENT_OPERATION.UPLOAD, {})}
              />
            </div>
          ) : null}

          {config.download ? (
            <Download downloadConfig={downloadConfig} handleDownloadClick={handleDownloadClick} />
          ) : null}

          {config.csvUpload
            ? menuAction(menuConfigList, { menuIcon: 'upload' }, handleUploadClick, {}, PERMISSION_OBJ, false, true)
            : null}
          {config.replicateAll ? (
            <div>
              <Button
                secondary
                iconBtnSmall
                iconName="copy"
                onClick={() =>
                  handleMultiReplicateType(EVENT_OPERATION.REPLICATE_ALL, {
                    startDate: dateUtil.getCurrentDay(),
                    endDate: dateUtil.getCurrentDay(),
                  })
                }
              />
            </div>
          ) : null}
          {config.create ? (
            <div>
              <Button
                primary
                iconName="plus"
                small
                title={createTitle ? createTitle : EVENT_OPERATION.CREATE.toLocaleLowerCase()}
                onClick={() => handleCreateClick(EVENT_OPERATION.CREATE)}
              />
            </div>
          ) : null}
          {config.pushMesssage ? (
            <div>
              <Button
                primary
                small
                rightIcon="send"
                title={'Send Message'.toLocaleLowerCase()}
                onClick={() => handleCreateClick(EVENT_OPERATION.CREATE)}
              />
            </div>
          ) : null}
          {children || ''}
        </div>
      }
    </PanelHeader>
  </Fragment>
);

PageHeader.propTypes = propTypes;

PageHeader.defaultProps = defaultProps;

export { defaultHeaderConfig };

export default PageHeader;
