import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import View from './View';
import { ALERT_TYPE } from '../../../data/enums/AlertType';
import withAlert from '../../../utils/composition/withAlert';
import * as arrayProcessor from '../../../utils/arrayProcessor';
import {
  defaultDateConfig,
  filter as filterConfig,
  ORDER_TABS,
  ORDER_TYPE,
  OrderFulFilmentContext,
  SERVER_ORDER_STATUS,
  skuStockValidation,
  storageKeys,
  tabMapper,
  MTD,
  getCreatePermission,
  zeroDayInventoryTabMapper,
  skudcStatusValidation,
} from './config';
import { LIMIT, PAGE } from '../../../data/enums/GeneralConstants';
import { apiFilterProcessor, getOffsetFromPageAndLimit } from '../../../utils/api';
import { getPermissionForOrderFulfilment } from '../../base/permission';
import {
  fetchFromLocalStorage,
  imageSliderListFormatter,
  isError,
  storeInLocalStorage,
  getSortValueAfterValueCheck,
} from '../../common/HelperFunctions';
import { generateFilterValue } from '../../../utils/filter';
import { clone } from '../../../utils/objectProcessor';
import { has, isObjectEmpty } from '../../../utils/objectPrototypes';
import { DOMAIN } from '../../../data/enums/config';
import * as queryService from '../../base/query.service';
import { getCurrentDay, getYesterdaysDate, simplePresentor } from '../../../utils/date';
import history from '../../../utils/history';
import { Loading } from '../../../v4/components';
import { ORDER_FULFILMENT_CREATE } from '../../../data/enums/Route';
import { getUser } from '../../../data/services';
import { USER_ROLE } from '../../../data/enums';

const propTypes = {
  serverResponseWaiting: PropTypes.bool,
  displayAlert: PropTypes.func.isRequired,
  getReceivedList: PropTypes.func.isRequired,
  getConfirmedList: PropTypes.func.isRequired,
  getInvoicedList: PropTypes.func.isRequired,
  getDeliveredList: PropTypes.func.isRequired,
  getDispatchedList: PropTypes.func.isRequired,
  getReceivedDetailList: PropTypes.func.isRequired,
  getInvoicedDetailList: PropTypes.func.isRequired,
  location: PropTypes.instanceOf(Object),
  getTabsTotal: PropTypes.func,
  // getStockReplacedList: PropTypes.func,
  history: PropTypes.instanceOf(Object).isRequired,
};

const defaultProps = {
  serverResponseWaiting: false,
  location: {},
  getTabsTotal: () => null,
  // getStockReplacedList: () => null,
};

class OrderProcessing extends Component {
  static getDerivedStateFromError() {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  constructor(props) {
    super(props);
    this.filterConfigMenu = clone(filterConfig.menu);
    const { branch_head, route, ...remaingingFilterConfig } = this.filterConfigMenu;
    const permittedUserRole = [USER_ROLE.SUPER_ADMIN, USER_ROLE.ADMIN, USER_ROLE.LEADERSHIP];
    const distributorZeroDayInventory = getUser().Distributor.length
      ? getUser().Distributor[0].zeroDayInventory
      : false;
    this.state = {
      searchedClicked: false,
      printDataLoading: false,
      received: {
        list: [],
        total: 0,
        limit: LIMIT,
        page: PAGE,
        filterFlag: false,
        initialCall: true,
      },
      invoiced: {
        list: [],
        total: 0,
        limit: LIMIT,
        page: PAGE,
        filterFlag: false,
        initialCall: true,
      },
      dispatched: {
        list: [],
        total: 0,
        limit: LIMIT,
        page: PAGE,
        filterFlag: false,
        initialCall: true,
      },
      delivered: {
        list: [],
        total: 0,
        limit: LIMIT,
        page: PAGE,
        filterFlag: false,
        initialCall: true,
      },
      confirmed: {
        list: [],
        total: 0,
        limit: LIMIT,
        page: PAGE,
        filterFlag: false,
        initialCall: true,
      },
      date: {
        start: getCurrentDay(),
        end: getCurrentDay(),
      },
      activeTab: 0,
      activeLine: {
        id: 0,
        sellerId: 0,
      },
      filter: {
        ...filterConfig.instance,
      },
      filterMenu: getCreatePermission() ? { ...remaingingFilterConfig } : this.filterConfigMenu || {},
      searchText: '',
      queryParameters: {
        sort: {},
      },
      zeroDayInventory: permittedUserRole?.includes(getUser().roleId) ? true : distributorZeroDayInventory,
      distributorZeroDayInventory: distributorZeroDayInventory,
    };
    this.permission = getPermissionForOrderFulfilment();
    this.persistFilterState = true;
    this.tabListGetterMapper = {
      [ORDER_TABS.RECEIVED]: this.getReceivedPaginatedList,
      [ORDER_TABS.CONFIRMED]: this.getConfirmedPaginatedList,
      [ORDER_TABS.INVOICED]: this.getInvoicedPaginatedList,
      [ORDER_TABS.DISPATCHED]: this.getDispatchedPaginatedList,
      [ORDER_TABS.DELIVERED]: this.getDeliveredPaginatedList,
    };
    this.baseService = new queryService.QueryClass(
      this.setQueryParameters,
      this.getQueryParameters,
      this.getDataOnFilterChange,
    );
  }
  componentDidMount() {
    const { distributorZeroDayInventory } = this.state;
    const { location } = this.props;
    if (location.state) {
      this.setState(
        {
          activeTab: location.state.activeTab === 4 ? 4 : 1,
          date: { ...MTD },
        },
        () => {
          this.getFilterStateFromStorage();
        },
      );
    } else {
      this.getFilterStateFromStorage();
    }

    if (distributorZeroDayInventory) {
      this.getPrimaryStockSAP();
    }
  }
  getData = () => {
    const { data } = this.state;

    return data;
  };

  setQueryParameters = (queryParams, callBack = () => null) =>
    this.setState({ queryParameters: queryParams }, callBack);

  getQueryParameters = () => {
    const { queryParameters } = this.state;

    return queryParameters;
  };

  handleFilterChange = filterValue => {
    this.setState({ filter: generateFilterValue(filterValue) || {}, filterMenu: filterValue }, () => {
      this.getTabsTotalCount();
      this.getDataOnFilterChange();
    });
  };

  handleDateRangeChange = (type, value) => {
    const { date } = this.state;
    date.start = value.start;
    date.end = value.end;
    this.setState({ date }, () => {
      this.getTabsTotalCount();
      this.getDataOnFilterChange();
    });
  };

  handleSearchChange = searchText => {
    const { searchedClicked } = this.state;
    this.setState({ searchText }, () => {
      this.getTabsTotalCount();
      this.getDataOnFilterChange();
    });
    this.setState({ searchedClicked: !searchedClicked });
  };

  getDataOnFilterChange = () => {
    const { received, invoiced, dispatched, delivered, activeTab, confirmed } = this.state;

    received.page = PAGE;
    received.filterFlag = true;
    invoiced.page = PAGE;
    invoiced.filterFlag = true;
    dispatched.page = PAGE;
    dispatched.filterFlag = true;
    delivered.page = PAGE;
    delivered.filterFlag = true;
    confirmed.page = PAGE;
    confirmed.filterFlag = true;

    this.setState(
      {
        received,
        invoiced,
        dispatched,
        delivered,
      },
      () => {
        this.fetchActiveTabPaginatedList(activeTab);
      },
    );
  };

  getFilterStateFromStorage = () => {
    const { activeTab } = this.state;
    const fetchFromLocal = fetchFromLocalStorage(storageKeys.FILTER, DOMAIN.SALES);
    const localTab = fetchFromLocalStorage('TABINDEX', DOMAIN.SALES);
    if (!fetchFromLocal) {
      this.getTabsTotalCount();
      return this.fetchActiveTabPaginatedList(activeTab);
    }
    this.setState(
      {
        filter: fetchFromLocal.filter,
        filterMenu: fetchFromLocal.filterMenu,
        date: activeTab === 4 || activeTab === 1 ? MTD : fetchFromLocal.date,
        searchText: fetchFromLocal.searchText,
        activeTab: localTab || activeTab,
      },
      () => {
        this.getTabsTotalCount();
        this.fetchActiveTabPaginatedList(activeTab);
      },
    );
  };

  checkAndUpdateToTodaysDate = (filter, field) => {
    const dateFilter = { ...filter };
    if (simplePresentor(dateFilter[field]) === simplePresentor(getYesterdaysDate())) {
      dateFilter[field] = new Date();
    }

    return dateFilter;
  };

  setFilterStateInStorage = () => {
    const { filter, filterMenu, date, searchText, activeTab } = this.state;
    storeInLocalStorage('TABINDEX', activeTab, DOMAIN.SALES);
    storeInLocalStorage(
      storageKeys.FILTER,
      {
        filter,
        filterMenu,
        date,
        searchText,
      },
      DOMAIN.SALES,
    );
  };

  fetchActiveTabPaginatedList = tab => {
    const { activeTab, zeroDayInventory } = this.state;
    this.setFilterStateInStorage();
    const type = zeroDayInventory ? zeroDayInventoryTabMapper[activeTab] : tabMapper[activeTab];
    this.tabListGetterMapper[type]();
  };

  checkIfPaginatedListCallApproved = stateTab => stateTab.initialCall || stateTab.filterFlag;

  getTabsTotalCount = () => {
    const { received, invoiced, dispatched, delivered, filter, date, searchText, confirmed } = this.state;
    const { getTabsTotal, displayAlert } = this.props;

    getTabsTotal(
      {
        filter: {
          filters: apiFilterProcessor(filter),
          dateRange: date,
          queryString: searchText,
        },
      },
      {
        handleSuccess: response => {
          const tabsTotalCount = response.data.allOrderCount || {};
          received.total = tabsTotalCount[ORDER_TABS.RECEIVED] || 0;
          invoiced.total = tabsTotalCount[ORDER_TABS.INVOICED] || 0;
          dispatched.total = tabsTotalCount[ORDER_TABS.DISPATCHED] || 0;
          delivered.total = tabsTotalCount[ORDER_TABS.DELIVERED] || 0;
          confirmed.total = tabsTotalCount[ORDER_TABS.CONFIRMED] || 0;
          this.setState({
            received,
            invoiced,
            dispatched,
            delivered,
            confirmed,
          });
        },
        handleError: error => {
          displayAlert(ALERT_TYPE.DANGER, error);
        },
      },
    );
  };

  getPrimaryStockSAP = () => {
    const { displayAlert, getPrimaryStockFromSAP } = this.props;
    getPrimaryStockFromSAP(
      {},
      {
        handleSuccess: res => {
          if (res.errors) {
            displayAlert(ALERT_TYPE.CUSTOM_DANGER, 'Error while loading stock from SAP. Please try again');
          } else {
            displayAlert(ALERT_TYPE.SUCCESS, 'Stock loaded from SAP successfully.');
          }
        },
        handleError: error => {
          displayAlert(ALERT_TYPE.DANGER, error);
        },
      },
    );
  };

  /** get paginated list here */
  getReceivedPaginatedList = () => {
    const { getReceivedList, displayAlert } = this.props;
    const { received, filter, date, searchText, queryParameters } = this.state;

    const offset = getOffsetFromPageAndLimit(received.page, received.limit);
    // if (this.checkIfPaginatedListCallApproved(received)) {
    getReceivedList(
      {
        offset,
        limit: received.limit,
        filter: {
          filters: apiFilterProcessor(filter),
          dateRange: date,
          queryString: searchText,
          sort: getSortValueAfterValueCheck(queryParameters.sort),
        },
      },
      {
        handleSuccess: response => {
          received.list = (response.data.receivedCalls && response.data.receivedCalls.rows) || [];
          // received.total = (response.data.receivedCalls && response.data.receivedCalls.count) || 0;
          received.filterFlag = false;
          received.initialCall = false;
          if (isError(response)) this.onAPIRequestFailure(response.errors[0]);
          this.setState(received);
        },
        handleError: error => {
          displayAlert(ALERT_TYPE.DANGER, error);
        },
      },
    );
    // }
  };

  getConfirmedPaginatedList = () => {
    const { getConfirmedList, displayAlert } = this.props;
    const { confirmed, filter, date, searchText, queryParameters } = this.state;

    const offset = getOffsetFromPageAndLimit(confirmed.page, confirmed.limit);
    getConfirmedList(
      {
        offset,
        limit: confirmed.limit,
        filter: {
          filters: apiFilterProcessor(filter),
          dateRange: date,
          queryString: searchText,
          sort: getSortValueAfterValueCheck(queryParameters.sort),
        },
      },
      {
        handleSuccess: response => {
          confirmed.list =
            (response.data.confirmedCalls && response.data.confirmedCalls.rows?.map(a => ({ ...a, ...a.Call }))) || [];
          confirmed.filterFlag = false;
          confirmed.initialCall = false;
          if (isError(response)) this.onAPIRequestFailure(response.errors[0]);
          this.setState(confirmed);
        },
        handleError: error => {
          displayAlert(ALERT_TYPE.DANGER, error);
        },
      },
    );
  };

  getInvoicedPaginatedList = () => {
    const { getInvoicedList, displayAlert } = this.props;
    const { invoiced, filter, date, searchText, queryParameters } = this.state;
    // all urls for search, filter and pagination
    const offset = getOffsetFromPageAndLimit(invoiced.page, invoiced.limit);
    if (this.checkIfPaginatedListCallApproved(invoiced)) {
      getInvoicedList(
        {
          offset,
          limit: invoiced.limit,
          filter: {
            filters: apiFilterProcessor(filter),
            dateRange: date,
            queryString: searchText,
            sort: getSortValueAfterValueCheck(queryParameters.sort),
          },
        },
        {
          handleSuccess: response => {
            invoiced.list = (response.data.invoices && response.data.invoices.rows) || [];
            // invoiced.total = (response.data.invoices && response.data.invoices.count) || 0;
            invoiced.filterFlag = false;
            invoiced.initialCall = false;
            this.setState(invoiced);
          },
          handleError: error => {
            displayAlert(ALERT_TYPE.DANGER, error);
          },
        },
      );
    }
  };

  getDispatchedPaginatedList = () => {
    const { getDispatchedList, displayAlert } = this.props;
    const { dispatched, filter, date, searchText, queryParameters } = this.state;
    // all urls for search, filter and pagination
    const offset = getOffsetFromPageAndLimit(dispatched.page, dispatched.limit);
    if (this.checkIfPaginatedListCallApproved(dispatched)) {
      getDispatchedList(
        {
          offset,
          limit: dispatched.limit,
          filter: {
            filters: apiFilterProcessor(filter),
            dateRange: date,
            queryString: searchText,
            sort: getSortValueAfterValueCheck(queryParameters.sort),
          },
        },
        {
          handleSuccess: response => {
            dispatched.list = (response.data.dispatchedInvoices && response.data.dispatchedInvoices.rows) || [];
            // dispatched.total = (response.data.dispatchedInvoices && response.data.dispatchedInvoices.count) || 0;
            dispatched.filterFlag = false;
            dispatched.initialCall = false;
            this.setState(dispatched);
          },
          handleError: error => {
            displayAlert(ALERT_TYPE.DANGER, error);
          },
        },
      );
    }
  };

  getDeliveredPaginatedList = () => {
    const { getDeliveredList, displayAlert } = this.props;
    const { delivered, filter, date, searchText, queryParameters } = this.state;
    // all urls for search, filter and pagination
    const offset = getOffsetFromPageAndLimit(delivered.page, delivered.limit);
    if (this.checkIfPaginatedListCallApproved(delivered)) {
      getDeliveredList(
        {
          offset,
          limit: delivered.limit,
          filter: {
            filters: apiFilterProcessor(filter),
            dateRange: date,
            queryString: searchText,
            sort: getSortValueAfterValueCheck(queryParameters.sort),
          },
        },
        {
          handleSuccess: response => {
            delivered.list = (response.data.deliveredInvoices && response.data.deliveredInvoices.rows) || [];
            // delivered.total = (response.data.deliveredInvoices && response.data.deliveredInvoices.count) || 0;
            delivered.filterFlag = false;
            delivered.initialCall = false;
            this.setState(delivered);
          },
          handleError: error => {
            displayAlert(ALERT_TYPE.DANGER, error);
          },
        },
      );
    }
  };

  getReceivedDetailList = (id, sellerId) => {
    const { getReceivedDetailList, displayAlert } = this.props;
    const { received } = this.state;
    // all urls for search, filter and pagination
    getReceivedDetailList(
      {
        callId: id,
        sellerId,
      },
      {
        handleSuccess: response => {
          const orders = response.data.callOrders ? response.data.callOrders : [];
          const updatedOrder = this.processCallOrders(orders);
          received.list = arrayProcessor.findAndUpdateItem(received.list, id, 'Orders', updatedOrder);
          this.setState(received);
        },
        handleError: error => {
          displayAlert(ALERT_TYPE.DANGER, error);
        },
      },
    );
  };

  getConfirmedDetailList = (id, refId, sellerId) => {
    const { getConfirmedDetailList, displayAlert } = this.props;
    const { confirmed } = this.state;
    // all urls for search, filter and pagination
    if (!this.checkIfDetailAlreadyExist(id, sellerId, confirmed.list)) {
      getConfirmedDetailList(
        {
          callId: id,
          refId,
          sellerId,
        },
        {
          handleSuccess: response => {
            const orders = response.data.confirmedOrders ? response.data.confirmedOrders : [];
            const updatedOrder = this.processCallOrders(orders);
            confirmed.list = arrayProcessor.findAndUpdateItem(confirmed.list, refId, 'Orders', updatedOrder, 'refId');
            this.setState(confirmed);
          },
          handleError: error => {
            displayAlert(ALERT_TYPE.DANGER, error);
          },
        },
      );
    }
  };

  getInvoicedDetailList = (id, sellerId, type, refId, orderType, restData) => {
    const { getInvoicedDetailList, displayAlert } = this.props;
    const { [type]: tab } = this.state;
    // all urls for search, filter and pagination
    if (!this.checkIfDetailAlreadyExist(id, sellerId, tab.list || [])) {
      if (orderType === ORDER_TYPE.REPLACEMENT) {
        this.fetchReplacedDetailList(refId).then(orders =>
          this.updateActiveLineOrders(id, [{ Lines: [...orders] }], type, ORDER_TYPE.REPLACEMENT),
        );
      } else {
        getInvoicedDetailList(
          {
            input: {
              id: type === ORDER_TABS.INVOICED ? id : refId,
              type: SERVER_ORDER_STATUS[type],
              ...((type === ORDER_TABS.DISPATCHED || type === ORDER_TABS.DELIVERED) && {
                dispatchNumber: restData?.dispatchNumber,
              }),
            },
          },
          {
            handleSuccess: response => {
              const orders = response.data.invoiceDetails ? response.data.invoiceDetails.Orders || [] : [];
              this.updateActiveLineOrders(id, orders, type);
            },
            handleError: error => {
              displayAlert(ALERT_TYPE.DANGER, error);
            },
          },
        );
      }
    }
  };

  getStockReplacedDetailList = (id, sellerId, type) => {
    // all urls for search, filter and pagination
    const { [type]: tab } = this.state;
    if (!this.checkIfDetailAlreadyExist(id, sellerId, tab.list || [])) {
      this.fetchReplacedDetailList(id).then(orders => this.updateActiveLineOrders(id, orders, type));
    }
  };

  fetchReplacedDetailList = async id => {
    const { getReplacedDetailList, displayAlert } = this.props;
    return new Promise((resolve, reject) => {
      getReplacedDetailList(
        {
          replacementId: id,
        },
        {
          handleSuccess: response => {
            const orders = (response.data.replacementOrderSummary && response.data.replacementOrderSummary) || [];
            const modifiedOrders = orders.map(sku => {
              sku.images = [...(imageSliderListFormatter(sku.images) || [])];

              return sku;
            });
            resolve(modifiedOrders);
          },
          handleError: error => {
            displayAlert(ALERT_TYPE.DANGER, error);
          },
        },
      );
    });
  };

  checkIfDetailAlreadyExist = (id, sellerId, list, reference = 'id') => {
    const targetedRow =
      list.find(item => item[reference] === id && (item.Distributor ? item.Distributor.id === sellerId : false)) || {};
    if (!isObjectEmpty(targetedRow)) {
      return has.call(targetedRow, 'Orders') ? targetedRow.Orders.length > 0 : false;
    }

    return false;
  };

  updateActiveLineOrders = (id, orders, tab) => {
    const { invoiced, dispatched, delivered, confirmed } = this.state;

    switch (tab) {
      case ORDER_TABS.INVOICED:
        invoiced.list = arrayProcessor.findAndUpdateItem(invoiced.list, id, 'Orders', orders) || [];
        this.setState({ invoiced });
        break;
      case ORDER_TABS.DISPATCHED:
        // eslint-disable-next-line no-case-declarations

        dispatched.list = arrayProcessor.findAndUpdateItem(dispatched.list, id, 'Orders', orders) || [];
        this.setState({ dispatched });
        break;
      case ORDER_TABS.DELIVERED:
        delivered.list = arrayProcessor.findAndUpdateItem(delivered.list, id, 'Orders', orders) || [];
        this.setState({ delivered });
        break;
      case ORDER_TABS.CONFIRMED:
        confirmed.list = arrayProcessor.findAndUpdateItem(confirmed.list, id, 'Orders', orders) || [];
        this.setState({ confirmed });
        break;
      default:
        break;
    }
  };

  updateActiveTabList = (tab, list, total) => {
    const { received, invoiced, dispatched, delivered, confirmed } = this.state;
    switch (tab) {
      case ORDER_TABS.RECEIVED:
        received.list = list;
        received.total = total;
        this.setState({ received });
        break;
      case ORDER_TABS.INVOICED:
        invoiced.list = list;
        invoiced.total = total;
        this.setState({ invoiced });
        break;
      case ORDER_TABS.DISPATCHED:
        dispatched.list = list;
        dispatched.total = total;
        this.setState({ dispatched });
        break;
      case ORDER_TABS.DELIVERED:
        delivered.list = list;
        delivered.total = total;
        this.setState({ delivered });
        break;
      case ORDER_TABS.CONFIRMED:
        confirmed.list = list;
        confirmed.total = total;
        this.setState({ confirmed });
        break;
      default:
        break;
    }
  };

  handleTabChange = tab => {
    const { activeLine } = this.state;
    activeLine.id = 0;
    activeLine.sellerId = 0;

    this.setState(
      {
        activeTab: tab,
        activeLine,
      },
      () => this.fetchActiveTabPaginatedList(tab),
    );
  };

  handlePassiveTabListChange = (id, tab, count) => {
    /** todo: fetch paginated list with invoiceId filter
     *  todo: update filter object with id,
     * */
    const { received, invoiced, dispatched, delivered, confirmed } = this.state;
    switch (tab) {
      case ORDER_TABS.RECEIVED:
        received.total += count;
        received.filterFlag = true;
        this.setState({ received }, () => this.getReceivedPaginatedList());
        break;
      case ORDER_TABS.INVOICED:
        invoiced.total += count;
        invoiced.filterFlag = true;
        this.setState({ invoiced }, () => this.getInvoicedPaginatedList());
        break;
      case ORDER_TABS.DISPATCHED:
        dispatched.total += count;
        dispatched.filterFlag = true;
        this.setState({ dispatched }, () => this.getDispatchedPaginatedList());
        break;
      case ORDER_TABS.DELIVERED:
        delivered.total += count;
        delivered.filterFlag = true;
        this.setState({ delivered }, () => this.getDeliveredPaginatedList());
        break;
      case ORDER_TABS.CONFIRMED:
        confirmed.total += count;
        confirmed.filterFlag = true;
        this.setState({ confirmed }, () => this.getConfirmedPaginatedList());
        break;
      default:
        break;
    }
  };

  handleCallRowClick = (id, sellerId, refId, orderType, restData = {}) => {
    const { activeTab, zeroDayInventory } = this.state;
    const type = zeroDayInventory ? zeroDayInventoryTabMapper[activeTab] : tabMapper[activeTab];

    switch (type) {
      case ORDER_TABS.RECEIVED:
        this.getReceivedDetailList(id, sellerId);
        break;
      case ORDER_TABS.INVOICED:
      case ORDER_TABS.DISPATCHED:
      case ORDER_TABS.DELIVERED:
        this.getInvoicedDetailList(id, sellerId, type, refId, orderType, restData);
        break;
      case ORDER_TABS.CONFIRMED:
        this.getConfirmedDetailList(id, refId, sellerId);
        break;
      default:
        break;
    }
    this.updateActiveLine(id, sellerId);
  };

  updateActiveLine = (id, sellerId) => {
    this.setState({
      activeLine: {
        id,
        sellerId,
      },
    });
  };

  handlePaginationChange = (type, pagination) => {
    const { received, invoiced, dispatched, delivered, confirmed } = this.state;
    switch (type) {
      case ORDER_TABS.RECEIVED:
        received.limit = pagination.limit;
        received.page = pagination.page;
        received.filterFlag = true;
        this.setState({ received }, () => this.getReceivedPaginatedList());
        break;
      case ORDER_TABS.INVOICED:
        invoiced.limit = pagination.limit;
        invoiced.page = pagination.page;
        invoiced.filterFlag = true;
        this.setState({ invoiced }, () => this.getInvoicedPaginatedList());
        break;
      case ORDER_TABS.DISPATCHED:
        dispatched.limit = pagination.limit;
        dispatched.page = pagination.page;
        dispatched.filterFlag = true;
        this.setState({ dispatched }, () => this.getDispatchedPaginatedList());
        break;
      case ORDER_TABS.DELIVERED:
        delivered.limit = pagination.limit;
        delivered.page = pagination.page;
        delivered.filterFlag = true;
        this.setState({ delivered }, () => this.getDeliveredPaginatedList());
        break;
      case ORDER_TABS.CONFIRMED:
        confirmed.limit = pagination.limit;
        confirmed.page = pagination.page;
        confirmed.filterFlag = true;
        this.setState({ confirmed }, () => this.getConfirmedPaginatedList());
        break;
      default:
        break;
    }
  };

  onAPIRequestFailure = error => {
    const { displayAlert } = this.props;
    displayAlert(ALERT_TYPE.DANGER, error);
  };

  /* onFilterChange = (field, value) => {
    if (field === 'date') {
      this.setState({ date: value }, () => this.fetchTabsPaginatedList());
    } else {
      this.setState({ filter: field }, () => this.fetchTabsPaginatedList());
    }
  }; */

  /** subscribedService => Obj of subscribed services  */
  fetchTabsPaginatedList = subscribedService => {
    this.getReceivedPaginatedList();
    this.getInvoicedPaginatedList();
    this.getDispatchedPaginatedList();
    this.getDeliveredPaginatedList();
  };

  stateGetter = (label = 'received') => this.state[label];

  stateSetter = (stateValue, label = 'received') => {
    this.setState({ [label]: stateValue });
  };

  checkIsBlocked = orders => (orders.find(item => item.isBlocked === true) ? true : false);

  processCallOrders = (orders, stockValidation = true) => {
    const updatedOrders = orders.map(item => {
      if (!item.promotionId) {
        return this.disintegrateNonPromotionOrder(item.Lines || [], stockValidation);
      }
      if (stockValidation) item.skuStatus = skuStockValidation(item.Lines);
      item.isBlocked = this.checkIsBlocked(item.Lines);
      item.dcStatus = skudcStatusValidation(item.Lines);
      return item;
    });

    return [].concat(...updatedOrders);
  };

  disintegrateNonPromotionOrder = (orders, stockValidation) => {
    const disintegratedNonPromotionOrder = orders.map(item => {
      const nonPromotionOrder = {
        id: null,
        promotionId: null,
        Lines: [item],
        skuStatus: skuStockValidation([item]),
        isBlocked: item.isBlocked,
        dcStatus: item.dcStatus,
      };
      if (stockValidation) nonPromotionOrder.skuStatus = skuStockValidation([item]);
      nonPromotionOrder.dcStatus = skudcStatusValidation([item]);

      return nonPromotionOrder;
    });

    return disintegratedNonPromotionOrder;
  };
  handleCreateClick = type => {
    history.push(`/${ORDER_FULFILMENT_CREATE}`);
  };

  printLoadingHandler = stateValue => {
    this.setState({
      printDataLoading: stateValue,
    });
  };

  handleCreateClick = type => {
    history.push(`/${ORDER_FULFILMENT_CREATE}`);
  };
  render() {
    const {
      date,
      filter,
      activeTab,
      received,
      invoiced,
      confirmed,
      delivered,
      dispatched,
      activeLine,
      filterMenu,
      searchText,
      searchedClicked,
      zeroDayInventory,
    } = this.state;
    const { history, displayAlert, serverResponseWaiting } = this.props;
    const createOrderPermission = getCreatePermission();
    return (
      <Fragment>
        <OrderFulFilmentContext.Provider value={{ permission: this.permission }}>
          <View
            date={date}
            history={history}
            stateSetter={this.stateSetter}
            stateGetter={this.stateGetter}
            activeTab={activeTab}
            activeLine={activeLine}
            received={received}
            invoiced={invoiced}
            confirmed={confirmed}
            delivered={delivered}
            dispatched={dispatched}
            displayAlert={displayAlert}
            loading={serverResponseWaiting}
            filter={filter}
            filterMenu={filterMenu}
            searchText={searchText}
            onDateRangeChange={this.handleDateRangeChange}
            onFilterChange={this.handleFilterChange}
            handleActiveTabListChange={this.updateActiveTabList}
            handlePassiveTabListChange={this.handlePassiveTabListChange}
            onTabChange={this.handleTabChange}
            onCallRowClick={this.handleCallRowClick}
            onPageChange={this.handlePaginationChange}
            onSearchInputChange={this.handleSearchChange}
            persistFilterState={this.persistFilterState}
            onHeaderClick={this.baseService.handleTableSorting}
            handleCreateClick={this.handleCreateClick}
            createOrderPermission={createOrderPermission}
            printLoadingHandler={this.printLoadingHandler}
            searchedClicked={searchedClicked}
            getTabsTotalCount={this.getTabsTotalCount}
            zeroDayInventory={zeroDayInventory}
          />
        </OrderFulFilmentContext.Provider>
        {this.state.printDataLoading && <Loading title={'Print Data Loading... Please wait.'} />}
      </Fragment>
    );
  }
}

OrderProcessing.propTypes = propTypes;

OrderProcessing.defaultProps = defaultProps;

export default withAlert()(OrderProcessing);
