import styled from 'styled-components';

const BuStyled = styled.div`
  padding: 32px 48px;
  background: #fbfbfc;
  .adhoc-card {
    p {
      margin: 0px;
    }
    align-items: center;
  }
  &.bu {
    padding: 0;
    .section-content {
      overflow: hidden !important;
      background: #fbfbfc;
    }
  }
  .bu-replicate-text {
    margin-bottom: 48px;

    .form-input {
      margin-bottom: 0 !important;
    }

    > span {
      font-size: 10px;
      line-height: 12px;
      margin-top: -4px;
      display: inline-block;
      width: 160px;
      color: #6b6c7e;
    }
  }

  .bu-section {
    .loading-wrap {
      display: none;
    }
  }

  .subd-holiday-wrap {
    .custom-control-label-text {
      width: 255px;
    }
    .bu-form-content {
      label {
        width: 142px;
        margin-right: 24px;
      }

      input {
        width: 310px;
        flex: none;
        background: #f1f2f5;
        border-color: #e7e7ed;
      }
    }
  }

  .bu-form-content {
    margin-bottom: 64px;

    &:last-child {
      margin-bottom: 0;
    }

    h2 {
      font-size: 14px;
      font-weight: normal;
      color: #6b6c7e;
      padding-bottom: 5px;
      margin: 0 0 24px;
      border-bottom: 1px solid #e7e7ed;
      width: 66.66667%;
    }
  }

  .measurement-wrap {
    width: 66.67%;
    width: 100%;
    + button {
      margin-top: 8px;

      &:hover,
      &:focus {
        .icon {
          color: #fff;
        }
      }

      .icon {
        width: 14px;
        height: 14px;
      }
    }

    .list-title {
      display: flex;

      li {
        width: 150px;
        margin-right: 16px;

        &.medium {
          width: 222px;
        }
        &.large {
          width: 252px;
        }
      }

      span {
        display: inline-block;
        margin-bottom: 4px;
        color: #272833;
        font-size: 14px;
        font-weight: 600;
      }
    }

    .list-content {
      margin-bottom: 4px;

      label {
        width: 150px;
        margin-right: 16px;

        > div {
          width: 100%;
        }

        &.small {
          margin-right: 0px;
        }

        &.medium {
          width: 222px;
        }

        &.large {
          width: 252px;
        }
      }

      input {
        width: 100% !important;
      }
    }

    .icon {
      cursor: pointer;
      color: #6b6c7e;
    }
  }

  .bu-draggable {
    > div {
      padding: 0 !important;
      width: 100% !important;
    }

    .form-input {
      padding: 0 !important;
      margin-bottom: 16px !important;

      &:last-child {
        margin-bottom: 0 !important;
      }
    }
  }

  .form-input,
  .form-select-input {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
  }

  label {
    width: 260px;
    display: inline-block;
    margin-bottom: 4px;
    color: #272833;
    font-size: 14px;
    font-weight: 600;

    input {
      background: #f1f2f5;
      border: 1px solid #e7e7ed;
    }

    .datepicker-wrapper {
      .moment {
        border-color: #e7e7ed;
        background: #f1f2f5;
        color: #6b6c7e;
        padding: 10px 14px;
        font-size: 14px;
        width: 100%;
        height: 40px;
        font-weight: 400;

        .ic-date-icon {
          top: 9px;
        }
      }
    }

    .filter-dropdown-card {
      min-width: 310px;
    }
  }

  input {
    flex: 1;
  }

  .form-select-input > div {
    flex: 1;
  }

  .subd-holiday-wrap .bu-form-content .holiday-status-checkbox .checkbox-list {
    label {
      width: auto;
      margin-bottom: 0;
    }
  }

  .checkbox {
    label {
      width: auto;
      margin-right: 32px;
    }
  }

  .target-list {
    display: flex;
    gap: 30px;
    label {
      width: unset;
    }
  }
  .stock-content {
    display: flex;
    padding-bottom: 16px;
    .radio-list {
      width: 70px;
      margin-right: 22px;
    }
    &:last-child {
      padding-bottom: 0;
    }
  }
  .form-input-wrap.card {
    &.order {
      .form-error {
        flex-basis: 100%;
        text-align: right;
      }
     &.distributionMetric .form-input:not(.switch) {
        label{
          width:260px !important;
          flex:unset !important;
        }
    }
    &.focused-sku {
      display: flex;
      flex-direction: column;
      & > div {
        margin-bottom: 0;
      }
    }
    .form-select-input {
      width: 100%;
    }
    & > div {
      margin-bottom: 24px;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
`;

export default BuStyled;
