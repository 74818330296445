import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import BuView from './View';
import * as arrayProcessor from '../../../utils/arrayProcessor';
import { EVENT_OPERATION } from '../../../data/enums/EventOperation';
import { breadCrumbConfig, formConfig, responseToState, initialDataObj, TAB_INDEX } from './config';
import { handleFormSubmit } from '../../../utils/crudResponseProcessor';
import { ALERT_TYPE } from '../../../data/enums/AlertType';
import withAlert from '../../../utils/composition/withAlert';
import { refGenerator } from '../../../utils/refGenerator';
import { dropdownChange, inputChange } from '../../../utils/formHandlers';
import { getPermissionForBuSettings } from '../../base/permission';
import { headerLabelConfig, storeInLocalStorage } from '../../common/HelperFunctions';
import { MASTER_DATA_TYPES } from '../sku/createSku/config';
import { DialogFormWrapper } from '../../common';
import { clone } from '../../../utils/objectProcessor';
import { Service } from './component/Service';
import { FiscalYear } from './component/FiscalYear';
import { Target } from './component/Target';
import { SKUAssortment } from './component/SKUAssortment';
import { StockMovementPolicy } from './component/StockMovementPolicy';
import { OrderTakingSequence } from './component/OrderTakingSequence';
import { BCPSequence } from './component/BCPSequence';
import { Holidays } from './component/Holidays';
import { UOM } from './component/UOM';
import { ICO } from './component/ICO';
import { MerchandisingSequence } from './component/MerchandisingSequence';

const propTypes = {
  displayAlert: PropTypes.func.isRequired,
  getBuData: PropTypes.func.isRequired,
  updateBU: PropTypes.func.isRequired,
  callProcedureDetailList: PropTypes.func.isRequired,
  createCallProcedure: PropTypes.func.isRequired,
  serverResponseWaiting: PropTypes.bool,
  match: PropTypes.instanceOf(Object).isRequired,
};

const defaultProps = {
  serverResponseWaiting: false,
};
class BU extends Component {
  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }
  constructor(props) {
    super(props);
    this.state = {
      dataObj: {
        ...initialDataObj,
      },
      crudMode: EVENT_OPERATION.READ,
      callHolidaySection: false,
      outletFundamentalList: [],
      orderSequenceAttribute: [],
      catalogList: [],
      enableErrorDisplay: false,
      dialog: {
        type: '',
        element: '',
      },
      activeTab: TAB_INDEX?.TAB_SERVICE,
      resetDataHoliday: false,
      areEqual: true,
      disableGrnJobButton: false,
    };
    this.permission = getPermissionForBuSettings();
    this.formReferenceUOM = refGenerator(['title', 'label']);
    this.formReferenceOrder = refGenerator(['groupBySku', 'labelName', 'groupByCatalog']);
    this.onFormSubmit = handleFormSubmit(this.onCRUDSuccess, this.onAPIRequestFailure);
  }

  componentDidMount() {
    (async () => {
      await this.getSkuTags();
      await this.getCatalogListFun();
    })();
  }
  getCatalogListFun = async () => {
    const { getCatalogList } = this.props;
    getCatalogList(
      {},
      {
        handleSuccess: async response => {
          this.setState({ catalogList: response?.data?.catalogs?.rows });
        },
        handleError: err => {
          this.onAPIRequestFailure(err);
        },
      },
    );
  };
  setData = async (data, sequence) => {
    const { dataObj, catalogList } = this.state;
    const updateDataObj = clone(dataObj);
    const { dseSequence, merchandisingSequence, orderTakingSequence, enableOrderTakingProcess } = sequence;
    const {
      geoFencing,
      orderForMultipleDistributors,
      brandDistributionCoverageTarget,
      outletCoverageTarget,
      pfd,
      srn,
      focusedSKU,
      routeSegmentation,
      sapIntegration,
      callRoster,
      rigoIntegration,
      smsIntegration,
      smsforOrderIntegration,
      web_order,
      brand_wise_order,
      sbd,
      fiscalYear,
      targets,
      targetDimension,
      targetDimensionMetric,
      fundamental_targets,
      skuAssortment,
      stockMovementRule,
      ico,
      unitsOfMeasurement,
      currency,
      payment,
      dateSettings,
      multipleDseInRoute,
      orderStatus,
      orderTakingProcess,
      distributionMetricLabel = '',
      focusedSkuType,
    } = data;
    const updatedServiceData = {
      currency,
      payment,
      dateSettings,
      multipleDseInRoute,
      orderStatus,
      geoFencing,
      orderForMultipleDistributors,
      brandDistributionCoverageTarget,
      outletCoverageTarget,
      pfd,
      srn,
      routeSegmentation,
      sapIntegration,
      callRoster,
      rigoIntegration,
      smsIntegration,
      smsforOrderIntegration,
      web_order,
      brand_wise_order: {
        ...brand_wise_order,
        status: brand_wise_order?.status,
      },
    };
    const updatedFyData = { fiscalYear };
    const updatedTargetsData = {
      target: { ...updateDataObj?.targetsData?.target },
      targets: { ...targets, manualSalesTarget: !targets?.automatedSalesTarget },
      targetDimension,
      targetDimensionMetric,
      fundamental_targets,
    };
    const updatedSkuData = {
      skuAssortmentMenu: skuAssortment.channel || skuAssortment.distributorGroup,
      skuAssortment,
    };
    const updatedStockData = {
      stockMovementRule,
    };
    const updatedIcoData = {
      ico,
    };
    const updatedUomData = {
      unitsOfMeasurement,
    };
    updateDataObj.serviceDataCloned = clone({ ...updatedServiceData });
    updateDataObj.serviceData = { ...updatedServiceData };
    updateDataObj.fyDataCloned = clone({ ...updatedFyData });
    updateDataObj.fyData = { ...updatedFyData };
    updateDataObj.targetsDataCloned = clone({ ...updatedTargetsData });
    updateDataObj.targetsData = { ...updatedTargetsData };
    updateDataObj.skuAssortmentDataCloned = clone({ ...updatedSkuData });
    updateDataObj.skuAssortmentData = { ...updatedSkuData };
    updateDataObj.stockDataCloned = clone({ ...updatedStockData });
    updateDataObj.stockData = { ...updatedStockData };
    updateDataObj.icoDataCloned = clone({ ...updatedIcoData });
    updateDataObj.icoData = { ...updatedIcoData };
    updateDataObj.uomDataCloned = clone({ ...updatedUomData });
    updateDataObj.uomData = { ...updatedUomData };
    updateDataObj.bcpDataCloned = clone({
      ...dseSequence,
      ...merchandisingSequence,
      ...orderTakingSequence,
      enableOrderTakingProcess,
      groupByCatalog: orderTakingProcess?.catalogGroupId
        ? { id: orderTakingProcess?.catalogGroupId, title: orderTakingProcess?.catalogGroupTitle }
        : {},
      groupBySku: orderTakingProcess?.skuGroupId
        ? { id: orderTakingProcess?.skuGroupId, title: orderTakingProcess?.skuGroupTitle }
        : {},
      paginationBySelectedGroup: orderTakingProcess?.catalogGroupPagination || false,
      labelName: sbd ? distributionMetricLabel || '' : '',
      distributionMetric: sbd || false,
      focusedSku: focusedSKU || false,
      focusedSkuType: focusedSKU
        ? {
            general: focusedSkuType?.general ?? true,
            smart: focusedSkuType?.smart ?? false,
          }
        : null,
    });
    updateDataObj.bcpData = {
      ...dseSequence,
      ...merchandisingSequence,
      ...orderTakingSequence,
      enableOrderTakingProcess,
      groupByCatalog: orderTakingProcess?.catalogGroupId
        ? { id: orderTakingProcess?.catalogGroupId, title: orderTakingProcess?.catalogGroupTitle }
        : {},
      groupBySku: orderTakingProcess?.skuGroupId
        ? { id: orderTakingProcess?.skuGroupId, title: orderTakingProcess?.skuGroupTitle }
        : {},
      paginationBySelectedGroup: orderTakingProcess?.catalogGroupPagination || false,
      labelName: sbd ? distributionMetricLabel || '' : '',
      distributionMetric: sbd || false,
      focusedSku: focusedSKU || false,
      focusedSkuType: focusedSKU
        ? {
            general: focusedSkuType?.general ?? true,
            smart: focusedSkuType?.smart ?? false,
          }
        : null,
    };
    this.setState({ dataObj: { ...updateDataObj } });
  };

  getData = async () => {
    const { getBuData } = this.props;
    const { orderSequenceAttribute } = this.state;
    getBuData(
      {},
      {
        handleSuccess: async response => {
          const { settings } = response.data ?? {};
          const formattedData = responseToState({ ...settings, orderSequenceAttribute });
          const orderTakingSequence = formattedData?.orderTakingSequenceFormattedData?.map(a => ({
            ...a,
            id: a?.CallProcedure?.id,
            type: 'orderTakingProcess',
            active: a?.active ?? false,
          }));
          const dseSequence = await this.getSequence('DSE', true);
          const merchandisingSequence = await this.getSequence('MERCHANDISING', true);
          await this.updateTargetList(settings?.targetDimension, settings?.targetDimensionMetric, true);
          await this.setData(settings, {
            dseSequence,
            merchandisingSequence,
            orderTakingSequence: { orderTakingProcessSequenceData: orderTakingSequence },
            enableOrderTakingProcess: formattedData?.orderTakingSequence?.orderTakingSequence,
          });
        },
        handleError: err => {
          this.onAPIRequestFailure(err);
        },
      },
    );
  };

  handleCheckBoxClick = id => {
    const { checkedList } = this.state;
    const toggledList = arrayProcessor.toggle([...checkedList], id);
    this.setState({ checkedList: toggledList });
  };

  handleDateRangeChange = (name, date) => {
    const { dataObj } = this.state;
    const updatedDataObj = clone(dataObj);
    updatedDataObj.fyData.fiscalYear[name] = date;
    this.setState({ dataObj: updatedDataObj });
  };

  handleInputChange = (event, firstParam = '', paramList = []) => {
    const { dataObj } = this.state;
    const updatedDataObj = clone(dataObj);
    const updatedDetails = inputChange(updatedDataObj, event, firstParam, paramList);
    if (firstParam === 'focusedSku') {
      updatedDetails.bcpData.focusedSkuType = {
        ...updatedDetails.bcpData.focusedSkuType,
        general: true,
        smart: false,
      }; //reset focusedSkuType
    }
    if (firstParam === 'distributionMetric') {
      updatedDetails.bcpData.labelName = ''; //reset labelName
    }
    if (event.target.name === 'enableOrderTakingProcess') {
      updatedDetails.bcpData.enableOrderTakingProcess = event.target.value;
    }

    if (event.target.name === 'skuAssortmentMenu' && !event.formattedValue) {
      updatedDetails.skuAssortmentData.skuAssortment.channel = false;
      updatedDetails.skuAssortmentData.skuAssortment.distributorGroup = false;
    }

    this.setState({ dataObj: updatedDetails });
  };
  updateTargetList = async (value, metricValue, flag = false) => {
    const { dataObj } = this.state;
    const updatedDataObj = clone(dataObj);
    const targetListMetric = updatedDataObj?.targetsData?.target?.targetList
      ?.filter?.(a => a?.type === value)
      ?.map?.(a => a?.metrics)
      .flat();
    updatedDataObj.targetsData.target.targetDimensionMetricList = targetListMetric;
    if (flag) {
      updatedDataObj.targetsDataCloned.target.targetDimensionMetricList = clone(targetListMetric);
    }
    this.setState({ dataObj: updatedDataObj }, () => {
      if (metricValue?.length > 0) return;
      updatedDataObj.targetsData.targetDimensionMetric = targetListMetric?.[0];
      this.setState({ dataObj: updatedDataObj });
    });
  };
  handleDropDownChange = (value, parameterRef = [], callBack = () => null) => {
    const { dataObj } = this.state;
    let updatedData = dropdownChange(dataObj, parameterRef, value);
    if (parameterRef[2] === 'groupByCatalog') {
      updatedData = { ...dataObj, bcpData: { ...data?.bcpData, groupBySku: null } }; //done to reset groupBySku
    }
    this.setState({ dataObj: updatedData }, () => {
      if (parameterRef?.[parameterRef?.length - 1] === 'targetDimension') {
        this.updateTargetList(value);
      }
    });
  };
  getValidationStatus = activeTab =>
    !Object.values(activeTab == TAB_INDEX.TAB_ORDER ? this.formReferenceOrder : this.formReferenceUOM).find(
      item => item && typeof item.getValidState === 'function' && !item.getValidState(),
    );
  confirmDialog = () => {
    const { dialog, activeTab } = this.state;
    const checkValid = [TAB_INDEX.TAB_UOM, TAB_INDEX.TAB_ORDER]?.includes(activeTab);
    const valid = checkValid ? this.getValidationStatus(activeTab) : true;
    if (!valid) {
      this.setState({ enableErrorDisplay: true });
      return;
    }
    this.setState({ dialog: { ...dialog, type: EVENT_OPERATION?.APPROVE } });
  };
  handleSubmit = () => {
    const { updateBU, displayAlert } = this.props;
    const { callHolidaySection, activeTab, dataObj } = this.state;
    if (activeTab === TAB_INDEX.TAB_BCP) {
      this.handleSubmitSequenceData('DSE');
      return;
    }
    if (activeTab === TAB_INDEX.TAB_MERCHANDISE) {
      this.handleSubmitSequenceData('MERCHANDISING');
      return;
    }
    if (activeTab === TAB_INDEX.TAB_HOLIDAYS) {
      this.setState(
        {
          callHolidaySection: !callHolidaySection,
        },
        () => {
          this.getData();
        },
      );
      return;
    }
    const updatedDataObj = clone(dataObj);
    const { serviceData, fyData, targetsData, skuAssortmentData, stockData, bcpData, holidayData, icoData, uomData } =
      updatedDataObj;
    const {
      enableOrderTakingProcess,
      focusedSkuType,
      focusedSku,
      groupBySku,
      distributionMetric,
      labelName,
      paginationBySelectedGroup,
      groupByCatalog,
    } = bcpData;
    const { target, targetDimensionMetricList, ...targetDataRemaining } = targetsData;
    const { targets, ...remainTargetPayload } = targetDataRemaining;
    const { brandWise, salesTeamWise, subDWise, manualSalesTarget, automatedSalesTarget } = targets;
    const { skuAssortmentMenu, ...skuDataRemaining } = skuAssortmentData;
    const orderPayloadOut = updatedDataObj?.bcpData?.[`orderTakingProcessSequenceData`]?.map(
      ({ active, alias, CallProcedure: { title } }, index) => ({
        active: active ?? false,
        sequence: index + 1,
        title,
        alias,
      }),
    );
    const targetsPayload = {
      brandWise,
      salesTeamWise,
      subDWise,
      automatedSalesTarget: automatedSalesTarget ?? false,
    };

    const sequencingItems = enableOrderTakingProcess
      ? orderPayloadOut
      : orderPayloadOut?.map(item => {
          return {
            ...item,
            active: false,
          };
        });

    const clonedFormattedInput = {
      ...serviceData,
      focusedSKU: focusedSku,
      sbd: distributionMetric,
      ...fyData,
      ...remainTargetPayload,
      targets: targetsPayload,
      ...skuDataRemaining,
      ...stockData,
      ...icoData,
      ...uomData,
      orderTakingProcess: {
        catalog_grouping_id: groupByCatalog?.id,
        catalog_grouping_title: groupByCatalog?.title,
        catalog_grouping_pagination: paginationBySelectedGroup,
        sku_grouping_title: groupBySku?.title || null,
        sku_grouping_id: groupBySku?.id || null,
      },
      distributionMetricLabel: distributionMetric ? (labelName?.length > 0 ? labelName : 'SBD') : null,
      focusedSkuType: {
        general: focusedSkuType?.general,
        smart: focusedSkuType?.smart,
      },
      orderTakingSequenceInput: { orderTakingSequence: enableOrderTakingProcess, sequencing: sequencingItems },
    };
    updateBU(
      {
        input: clonedFormattedInput,
      },
      {
        handleSuccess: response => {
          const { storeSettings } = response.data;
          this.setState(
            {
              crudMode: EVENT_OPERATION.READ,
              enableErrorDisplay: false,
            },
            () => {
              this.getData();
            },
          );
          displayAlert(ALERT_TYPE.INFO, storeSettings);
          storeInLocalStorage('bu-settings', clonedFormattedInput, 'rosia');
        },
        handleError: err => {
          this.onAPIRequestFailure(err);
        },
      },
    );
  };

  onAPIRequestFailure = error => {
    const { displayAlert } = this.props;
    displayAlert(ALERT_TYPE.DANGER, error);
  };

  onTargetChange = (e, param) => {
    const { dataObj } = this.state;
    const {
      targetsData: { targets },
    } = dataObj;
    const { value, checked } = e.target;

    const updatedDataObj = {
      ...dataObj,
      targetsData: {
        ...dataObj.targetsData,
        targets: {
          ...targets,
          automatedSalesTarget: param === 'target' ? !targets.automatedSalesTarget : targets.automatedSalesTarget,
          manualSalesTarget: param === 'target' ? !targets.manualSalesTarget : targets.manualSalesTarget,
          salesTeamWise: param === 'targetTwo' ? !targets.salesTeamWise : targets.salesTeamWise,
          subDWise: param === 'targetTwo' ? !targets.subDWise : targets.subDWise,
          [value]: checked,
        },
      },
    };

    this.setState({ dataObj: updatedDataObj });
  };

  onIcoChange = e => {
    const { dataObj } = this.state;
    const {
      icoData: { ico },
    } = dataObj;
    const { value, checked } = e.target;
    const updatedIco = { [value]: checked };
    value === 'sku' ? (updatedIco.skuFamily = false) : (updatedIco.sku = false);
    const updatedDataObj = {
      ...dataObj,
      icoData: {
        ...dataObj.icoData,
        ico: {
          ...ico,
          ...updatedIco,
        },
      },
    };
    this.setState({ dataObj: updatedDataObj });
  };

  onStockChange = (e, index, stockIndex, params) => {
    const { dataObj } = this.state;
    const typeChange = stockIndex === 0 ? 1 : 0;
    const updatedDataCloned = clone({ ...dataObj });
    if (params === 'outletTarget') {
      updatedDataCloned.targetsData.fundamental_targets[index].parameters[stockIndex].status =
        !updatedDataCloned.targetsData.fundamental_targets[index].parameters[stockIndex].status;
    } else {
      updatedDataCloned.stockData.stockMovementRule[index].stockRule[stockIndex].status =
        !updatedDataCloned.stockData.stockMovementRule[index].stockRule[stockIndex].status;

      updatedDataCloned.stockData.stockMovementRule[index].stockRule[typeChange].status =
        !updatedDataCloned.stockData.stockMovementRule[index].stockRule[stockIndex].status;
    }
    this.setState({
      dataObj: updatedDataCloned,
    });
  };

  onFundamentalChange = (e, index, params) => {
    const { dataObj } = this.state;
    const updatedDataCloned = clone({ ...dataObj });
    if (params === 'outletTarget') {
      updatedDataCloned.targetsData.fundamental_targets[index].status =
        !updatedDataCloned?.targetsData?.fundamental_targets[index].status;
      updatedDataCloned.targetsData.fundamental_targets[index].parameters.map(targets => {
        targets.status = false;
      });
    }
    this.setState({ dataObj: updatedDataCloned });
  };

  handleEditIconClick = () => {
    this.setState({
      crudMode: EVENT_OPERATION.UPDATE,
    });
  };
  resetData = () => {
    const { dataObj, activeTab } = this.state;
    const updatedDataObj = clone({ ...dataObj });
    const findTabValue = this.getTabList()?.find(a => a?.tab === activeTab)?.value;
    updatedDataObj[findTabValue] = dataObj?.[`${findTabValue}Cloned`];
    this.setState({ dataObj: updatedDataObj });
  };
  handleCancelButton = () => {
    this.setState({
      crudMode: EVENT_OPERATION.READ,
      resetDataHoliday: true,
      enableErrorDisplay: false,
    });
    this.resetData();
    this.resetDialog();
  };

  handleMeasurementAdd = () => {
    const { dataObj } = this.state;
    const {
      uomData: { unitsOfMeasurement },
    } = dataObj;
    const updatedDataObj = {
      ...dataObj,
      uomData: {
        ...dataObj.uomData,
        unitsOfMeasurement: [
          ...unitsOfMeasurement,
          ...[
            {
              title: null,
              label: null,
            },
          ],
        ],
      },
    };
    this.setState({ dataObj: updatedDataObj });
  };

  handleMeasurementCancel = title => {
    const { dataObj } = this.state;
    const {
      uomData: { unitsOfMeasurement },
    } = dataObj;
    const filterData = [...unitsOfMeasurement].filter(d => d.title !== title);
    const updatedDataObj = {
      ...dataObj,
      uomData: {
        ...dataObj.uomData,
        unitsOfMeasurement: filterData,
      },
    };
    this.setState({ dataObj: updatedDataObj });
  };
  getSequence = async (type, initialStateFlag = false, rowData) => {
    return new Promise((resolve, reject) => {
      const { callProcedureDetailList } = this.props;
      callProcedureDetailList(
        { type },
        {
          handleSuccess: response => {
            const { rows } = response.data.callProcedureDetails;
            const mappedRow = rows?.map(a => ({
              ...a,
              type,
              id: a?.CallProcedure?.id,
              active: a?.active ?? false,
            }));
            resolve({ [`${type}SequenceData`]: mappedRow });
          },
          handleError: error => {
            reject(error);
          },
        },
      );
    });
  };
  handleSubmitSequenceData = type => {
    const { createCallProcedure, displayAlert } = this.props;
    const { dataObj } = this.state;
    const updateDataObj = clone(dataObj);
    const mappedData = updateDataObj?.bcpData?.[`${type}SequenceData`]?.map(({ id, active }, index) => ({
      id,
      active: active ?? false,
      sequence: index + 1,
    }));
    const payloadOut = { type, domain: 'BU', refId: 1, callProcedureSequence: mappedData };
    createCallProcedure(
      {
        input: payloadOut,
      },
      {
        handleSuccess: response => {
          updateDataObj.bcpDataCloned[`${type}SequenceData`] = updateDataObj?.bcpData?.[`${type}SequenceData`];
          const { createCallProcedure: callMessage } = response.data;
          this.setState({ dataObj: updateDataObj, enableErrorDisplay: false });
          displayAlert(ALERT_TYPE.SUCCESS, callMessage.message);
        },
        handleError: err => {
          this.onAPIRequestFailure(err);
        },
      },
    );
  };

  onGrnJobClick = () => {
    const { runGrnJob, displayAlert } = this.props;
    this.setState({
      disableGrnJobButton: true,
    });

    runGrnJob(
      {},
      {
        handleSuccess: response => {
          response.data && displayAlert(ALERT_TYPE.SUCCESS, 'Job ran successfully');
          this.setState({
            disableGrnJobButton: false,
          });
        },
        handleError: err => {
          this.onAPIRequestFailure(err);
          this.setState({
            disableGrnJobButton: false,
          });
        },
      },
    );
  };

  getSkuTags = async () => {
    const { getMasterData, displayAlert } = this.props;
    const { dataObj } = this.state;
    const updatedDataObj = clone(dataObj);
    getMasterData(
      {},
      {
        handleSuccess: res => {
          const { listMasterData = [] } = res.data;
          const newData = {
            [MASTER_DATA_TYPES.SKU_TAGS]: [],
            [MASTER_DATA_TYPES.USER_FUNDAMENTAL_TARGETS]: [],
          };
          if (listMasterData.length > 0) {
            listMasterData.forEach(item => {
              newData[item.type] = [...(item.list || [])];
            });
            const skuTagList = newData[MASTER_DATA_TYPES.SKU_TAGS];
            const outletFundamentalList = newData[MASTER_DATA_TYPES.OUTLET_FUNDAMENTAL_TARGETS];
            const targetList = newData[MASTER_DATA_TYPES.TARGET_DIMENSIONS];
            const orderSequenceAttribute = newData[MASTER_DATA_TYPES.ORDER_SEQUENCE_ATTRIBUTE];
            const result = skuTagList.reduce((acc, item) => {
              acc.push({
                skuTag: item,
                stockRule: [
                  { stockRuleType: 'FIFO', status: false },
                  { stockRuleType: 'FEFO', status: false },
                ],
              });
              return acc;
            }, []);
            updatedDataObj.targetsData.target.targetDimension = targetList?.map(({ type }) => type);
            updatedDataObj.targetsData.target.targetList = targetList;
            updatedDataObj.stockData.stockMovementRule = result;
            this.setState(
              {
                dataObj: updatedDataObj,
                outletFundamentalList: outletFundamentalList,
                orderSequenceAttribute: orderSequenceAttribute,
              },
              () => {
                this.getData();
              },
            );
          }
        },
        handleError: err => {
          displayAlert(ALERT_TYPE.DANGER, err);
        },
      },
    );
  };

  handleFormSubmit = (type, dialogData, error) => {
    if (type === EVENT_OPERATION.DELETE) {
      this.handleRowDelete(dialogData);
    } else {
      this.handleDataUpdate(dialogData);
    }
  };
  resetDialog = () => {
    this.setState({
      dialog: {
        type: '',
        element: '',
      },
    });
  };
  handleTabChange = tab => {
    this.setState({
      activeTab: tab,
      crudMode: EVENT_OPERATION.READ,
      enableErrorDisplay: false,
    });
    this.resetData();
  };
  getHolidayData = (holiday, holidayCloned, initialStateFlag = false) => {
    this.setState(prevState => ({
      dataObj: {
        ...prevState.dataObj,
        holidayData: {
          ...holiday,
        },
        holidayDataCloned: {
          ...holidayCloned,
        },
      },
    }));
    this.setState({
      resetDataHoliday: false,
    });
  };
  changeDrag = (value, data, index, type) => {
    const { dataObj } = this.state;
    const updateDataObj = clone(dataObj);
    const findIndexItem = updateDataObj?.bcpData?.[`${data.type}SequenceData`]?.findIndex(
      item => item?.id === data?.id,
    );
    updateDataObj.bcpData[`${data.type}SequenceData`][findIndexItem] = { ...data, active: value?.formattedValue };
    this.setState(prevState => ({
      dataObj: {
        ...prevState.dataObj,
        bcpData: {
          ...prevState.dataObj.bcpData,
          [`${data.type}SequenceData`]: [...updateDataObj?.bcpData?.[`${data.type}SequenceData`]],
        },
      },
    }));
  };
  onSequenceChange = (data, item) => {
    this.setState(prevState => ({
      dataObj: {
        ...prevState.dataObj,
        bcpData: {
          ...prevState.dataObj.bcpData,
          [`${data[0].type}SequenceData`]: data,
        },
      },
    }));
  };

  onFocusedSKUType = (e, param) => {
    const { dataObj } = this.state;
    const { value, checked } = e.target;
    const updatedDataObj = {
      ...dataObj,
      bcpData: {
        ...dataObj.bcpData,
        focusedSkuType: {
          smart: param === 'smart' ? checked : !checked,
          general: param === 'general' ? checked : !checked,
        },
      },
    };
    this.setState({ dataObj: updatedDataObj });
  };
  getTabList = () => {
    const {
      dataObj,
      crudMode,
      target,
      enableOrderTakingProcess,
      callHolidaySection,
      resetDataHoliday,
      enableErrorDisplay,
      disableGrnJobButton,
      catalogList,
    } = this.state;
    const { match } = this.props;
    const hideEditButton = crudMode !== EVENT_OPERATION.UPDATE;
    const tabsList = [
      {
        tab: TAB_INDEX?.TAB_SERVICE,
        value: 'serviceData',
        label: 'Services',
        content: (
          <Service
            data={dataObj}
            onInputChange={this.handleInputChange}
            headerLabelConfig={headerLabelConfig}
            disableGrnJobButton={disableGrnJobButton}
            onGrnJobClick={this.onGrnJobClick}
          />
        ),
      },
      {
        tab: TAB_INDEX?.TAB_FY,
        value: 'fyData',
        label: 'Fiscal Year',
        content: <FiscalYear data={dataObj} handleDateRangeChange={this.handleDateRangeChange} />,
      },
      {
        tab: TAB_INDEX?.TAB_TARGET,
        value: 'targetsData',
        label: 'Target',
        content: (
          <Target
            data={dataObj}
            onAutoManualTargetChange={this.onAutoManualTargetChange}
            handleDropDownChange={this.handleDropDownChange}
            hideEditButton={hideEditButton}
            onFundamentalChange={this.onFundamentalChange}
            onStockChange={this.onStockChange}
            target={target}
            onTargetChange={this.onTargetChange}
            onInputChange={this.handleInputChange}
          />
        ),
      },
      {
        tab: TAB_INDEX?.TAB_UOM,
        value: 'uomData',
        label: 'Unit Of Measurement',
        content: (
          <UOM
            data={dataObj?.uomData}
            onInputChange={this.handleInputChange}
            handleMeasurementCancel={this.handleMeasurementCancel}
            handleMeasurementAdd={this.handleMeasurementAdd}
            enableErrorDisplay={enableErrorDisplay}
            refsObj={this.formReferenceUOM}
          />
        ),
      },
      {
        tab: TAB_INDEX?.TAB_SKU,
        value: 'skuAssortmentData',
        label: 'SKU Assortment',
        content: <SKUAssortment data={dataObj?.skuAssortmentData} onInputChange={this.handleInputChange} />,
      },
      {
        tab: TAB_INDEX?.TAB_STOCK,
        value: 'stockData',
        label: 'Stock Movement Policy',
        content: <StockMovementPolicy data={dataObj?.stockData} onStockChange={this.onStockChange} />,
      },
      {
        tab: TAB_INDEX?.TAB_BCP,
        value: 'bcpData',
        label: 'BCP Sequence',
        content: (
          <BCPSequence
            sequenceData={dataObj?.bcpData?.DSESequenceData}
            onInputChange={this.changeDrag}
            onSequenceChange={this.onSequenceChange}
            sequenceTitle="Basic Call Procedure Sequence"
          />
        ),
      },
      {
        label: 'Order Taking Process',
        tab: TAB_INDEX?.TAB_ORDER,
        value: 'bcpData',
        content: (
          <OrderTakingSequence
            enableOrderTakingProcess={enableOrderTakingProcess}
            onInputChange={this.handleInputChange}
            sequenceData={dataObj?.bcpData}
            changeDrag={this.changeDrag}
            onSequenceChange={this.onSequenceChange}
            sequenceTitle="Order Sequence "
            handleDropDownChange={this.handleDropDownChange}
            catalogList={catalogList}
            onFocusedSKUType={this.onFocusedSKUType}
            enableErrorDisplay={enableErrorDisplay}
            refsObj={this.formReferenceOrder}
          />
        ),
      },
      {
        tab: TAB_INDEX?.TAB_MERCHANDISE,
        value: 'bcpData',
        label: 'Merchandising Sequence',
        content: (
          <MerchandisingSequence
            sequenceData={dataObj?.bcpData?.MERCHANDISINGSequenceData}
            onInputChange={this.changeDrag}
            onSequenceChange={this.onSequenceChange}
            sequenceTitle="Merchandising Sequence"
          />
        ),
      },
      {
        tab: TAB_INDEX?.TAB_HOLIDAYS,
        value: 'holidayData',
        label: 'Holidays',
        content: (
          <Holidays
            params={match.params}
            callHolidaySection={callHolidaySection}
            getHolidayData={this.getHolidayData}
            resetDataHoliday={resetDataHoliday}
          />
        ),
      },
      {
        tab: TAB_INDEX?.TAB_ICO,
        value: 'icoData',
        label: 'ICO Configuration',
        content: <ICO data={dataObj?.icoData} onIcoChange={this.onIcoChange} />,
      },
    ];
    return tabsList;
  };
  checkValueChanges = (dataObj, activeTab) => {
    const findTabValue = this.getTabList()?.find(a => a?.tab === activeTab)?.value;
    const areEqualData = _.isEqual(dataObj?.[findTabValue], dataObj?.[`${findTabValue}Cloned`]);
    return areEqualData;
  };
  render() {
    const { crudMode, dialog, activeTab, dataObj } = this.state;
    const { type } = dialog;
    const { serverResponseWaiting } = this.props;
    const areEqual = this.checkValueChanges(dataObj, activeTab);
    return (
      <Fragment>
        {type && (
          <DialogFormWrapper
            formConfig={crudMode[type]}
            dialogElement={dialog.element}
            formTitle={'Confirmation'}
            onDialogSubmit={this.handleSubmit}
            onDialogCancel={this.resetDialog}
            type={type}
            renderDialog={({}) => (
              <>
                <b>Are you sure you want to proceed with the changes?</b>
              </>
            )}
          />
        )}
        <BuView
          breadCrumb={breadCrumbConfig}
          loading={serverResponseWaiting}
          crudMode={crudMode}
          handleButtonSubmit={this.confirmDialog}
          handleButtonCancel={this.handleCancelButton}
          handleEditIconClick={this.handleEditIconClick}
          permission={this.permission}
          handleTabChange={this.handleTabChange}
          activeTab={activeTab}
          tabsList={this.getTabList()}
          areEqual={areEqual}
        />
      </Fragment>
    );
  }
}

BU.propTypes = propTypes;

BU.defaultProps = defaultProps;

export default withAlert()(BU);
