export const generateRandomId = () => Math.random().toString(16).slice(2);
export const groupByAlias = (data, type?: any) => {
  const groupId = data?.groupId ?? generateRandomId();
  return {
    measure: data?.measure,
    id: data?.id ?? null,
    groupId,
    items: data?.items?.map?.(item => ({
      ...item,
      measure: data?.measure,
      groupId,
      parentId: data?.id,
      itemId: item?.itemId,
    })),
  };
};

export const updateOrAddToList = (list, newItem) => {
  const exists = list?.some(item => item?.groupId === newItem?.groupId);
  if (exists) {
    return list.map(item => (item?.groupId === newItem?.groupId ? newItem : item));
  } else {
    return [...list, newItem];
  }
};
