import { useEffect, useState } from 'react';
import EVENT_OPERATION from '../../../../../data/enums/EventOperation';
import { ERR_VALIDATION, initialDialog, initialDialogItems, initialSummaryObj, TABLE_CONFIG } from '../View/config';
import { useFormController } from '../../List/Controllers/useForm.controller';
import { has } from '../../../../../utils/objectPrototypes';
import { summarySchema, dialogSchema } from '../Schema/Details.schema';
import { groupByAlias, updateOrAddToList } from '../Schema/helperFuntion';
import { useTableConfigController } from './useBukDelete.controller';
import { ALERT_TYPE } from '../../../../../v4/constants/AlertType';
import { INCENTIVE } from '../../../../../data/enums/Route';
import history from '../../../../../utils/history';
import { commonApiFunc } from '../../List/DataMapper/helperFunction';
import {
  createToServerMapper,
  getDetailsServerToClientMapper,
  resDataServerToClientMapper,
  updateToServerMapper,
} from '../DataMapper/DetailsDataMapper';
import { useIncentiveList } from './useIncentiveList.controller';

export const useDetailsController = ({ ...props }) => {
  const { displayAlert, updateIncentive, createIncentive, getIncentiveDetails } = props;
  const [load, setLoad] = useState(false);
  const [details, setDetails] = useState<any>();
  const [dialog, setDialog] = useState(initialDialog);
  const [summaryObj, setSummaryObj] = useState(initialSummaryObj);
  const [tempList, setTempList] = useState([]);
  const [clonedData, setClonedData] = useState<any>();

  const id = has.call(props.match.params, 'id') ? parseInt(props.match.params.id, 10) : 0;
  const [crudMode, setCrudMode] = useState<string>(id ? EVENT_OPERATION.READ : EVENT_OPERATION.CREATE);

  const isCreate = crudMode === EVENT_OPERATION.CREATE;
  const isUpdate = crudMode === EVENT_OPERATION.UPDATE;
  const isRead = crudMode === EVENT_OPERATION.READ;

  const { userGroupList } = useIncentiveList({ props, apiCall: isCreate || isUpdate });

  const permissions = JSON.parse(localStorage.getItem('-rosia-permission'));
  const permission = permissions?.find(a => a?.program === 'Incentive')?.permission;

  const { handleSubmit, watch, control, reset, errors, setValue, clearErrors, trigger, getValues } = useFormController({
    customSchema: dialog?.type?.length === 0 ? summarySchema : dialogSchema,
    defaultValues: {
      ...summaryObj,
      items: [{ ...initialDialogItems }],
    },
    displayAlert,
    msg: [TABLE_CONFIG.CREATE, TABLE_CONFIG.UPDATE]?.includes(dialog.type)
      ? 'Please fill all the required field to proceed ahead.'
      : '',
  });
  const loadDetailsData = async () => {
    setLoad(true);
    await commonApiFunc({
      payload: {
        id,
        param: 'detailsData',
      },
      func: {
        api: getIncentiveDetails,
        success: handleSuccess,
        error: handleError,
        mapper: getDetailsServerToClientMapper,
      },
    });
  };

  useEffect(() => {
    if (id) {
      loadDetailsData();
    }
  }, []);

  useEffect(() => {
    reset({ userGroup: summaryObj?.userGroup, month: summaryObj?.month });
  }, [summaryObj]);

  const handleClose = () => {
    setDialog(initialDialog);
  };

  const updateTempList = (data, type) => {
    const list = groupByAlias(data, type);
    setTempList(prev => updateOrAddToList(prev, list));
  };

  const handleIconClick = ({ data, type }: any) => {
    setDialog({ ...dialog, type, element: data });
    switch (type) {
      case TABLE_CONFIG.CREATE:
        setSelectedItems([]);
        reset({ ...summaryObj, items: [{ ...initialDialogItems }] });
        break;
      case TABLE_CONFIG.UPDATE:
        setSelectedItems([]);
        const dialogItem = tempList?.find?.(obj => obj?.measure === data?.measure);
        reset({
          ...summaryObj,
          groupId: dialogItem?.groupId,
          id: dialogItem?.id,
          measure: data?.measure,
          items: dialogItem?.items,
        });
        break;
      default:
        break;
    }
  };
  const { selectedItems, isAllSelected, handleRowSelect, handleBulkDelete, handleSelectAll, setSelectedItems } =
    useTableConfigController(tempList, handleIconClick);

  const handleDialogFormSubmit = ({ data, type }) => {
    switch (dialog?.type) {
      case TABLE_CONFIG.UPDATE:
      case TABLE_CONFIG.CREATE:
        updateTempList(data, type);
        reset({ ...summaryObj });
        setDialog(initialDialog);
        break;
      case TABLE_CONFIG.BULK_DELETE:
        const selectedGroupIds = selectedItems?.map(ie => ie?.groupId);
        const newTempGroups = tempList?.filter(group => [...selectedGroupIds]?.includes(group?.groupId));
        setTempList(newTempGroups);
        setSelectedItems([]);
        break;
      case TABLE_CONFIG.DELETE:
        const newTemp = tempList?.filter(group => group.groupId !== dialog?.element?.groupId);
        setTempList(newTemp);
        break;
      default:
        break;
    }
  };
  const handleEditIconClick = () => {
    setCrudMode(EVENT_OPERATION.UPDATE);
  };
  const checkValidation = () => {
    if (tempList?.length === 0) {
      displayAlert(ALERT_TYPE.WARNING, ERR_VALIDATION?.LENGTH_ERR);
      return false;
    }
    return true;
  };

  const updateState = (res: any) => {
    const mappedData = resDataServerToClientMapper(res);
    setClonedData(mappedData);
    setSummaryObj(mappedData?.summaryObj);
    setTempList(mappedData?.tableList);
  };
  const handleSuccess = ({ res, param }: any) => {
    if (res) {
      if (param === 'detailsData') {
        updateState(res?.data?.incentive);
        setLoad(false);
        return;
      }
      setLoad(false);
      if (isUpdate) {
        updateState(res?.data?.updateIncentive);
      }
    }
    displayAlert(ALERT_TYPE.SUCCESS, `Incentive ${crudMode?.toLocaleLowerCase()}d successfully`);
    setTimeout(() => {
      history.push(`/${INCENTIVE}`);
    }, 600);
  };
  const updateIncentiveFun = async () => {
    setLoad(true);
    await commonApiFunc({
      payload: {
        id,
        tempList,
        summaryObj,
        param: 'updateIncentive',
      },
      func: {
        api: updateIncentive,
        success: handleSuccess,
        error: handleError,
        mapper: updateToServerMapper,
      },
    });
  };
  const createIncentiveFunc = async () => {
    setLoad(true);
    await commonApiFunc({
      payload: {
        tempList,
        summaryObj,
        param: 'createIncentive',
      },
      func: {
        api: createIncentive,
        success: handleSuccess,
        error: handleError,
        mapper: createToServerMapper,
      },
    });
  };
  const handleError = ({ err, param }: any) => {
    setLoad(false);
    displayAlert(ALERT_TYPE.DANGER, err);
  };
  const handleSave = () => {
    if (checkValidation()) {
      switch (crudMode) {
        case EVENT_OPERATION.UPDATE:
          updateIncentiveFun();
          break;
        case EVENT_OPERATION.CREATE:
          // createToServerMapper({ tempList, summaryObj });
          createIncentiveFunc();
          break;
        default:
          break;
      }
    }
  };
  const handleCancelClick = () => {
    if (isUpdate) {
      setCrudMode(EVENT_OPERATION.READ);
      setSummaryObj(clonedData?.summaryObj);
      setTempList(clonedData?.tableList);
    }
    if (isCreate) {
      history.push(`/${INCENTIVE}`);
    }
  };
  return {
    handleDialogFormSubmit,
    handleClose,
    tempList,
    summaryObj,
    setSummaryObj,
    handleCancelClick,
    handleEditIconClick,
    handleSave,
    dialog,
    load,
    handleIconClick,
    mode: { isUpdate, isCreate, isRead, crudMode },
    bulkCompo: { selectedItems, isAllSelected, handleRowSelect, handleBulkDelete, handleSelectAll },
    formParam: { handleSubmit, watch, control, reset, errors, setValue, clearErrors, trigger, getValues },
    list: { userGroupList },
    permission,
  };
};
