import { ChangeEvent } from 'react';
import EVENT_OPERATION from '../../../../../data/enums/EventOperation';
import { PROGRAM } from '../../../../../data/enums/Permission';
import withErrorAndLoadingScreen from '../../../../../utils/composition/withLoading';
import { Loading, Pagination } from '../../../../../v4/components';
import PageHeader from '../../../../base/PageHeader';
import { DialogFormWrapper } from '../../../../common';
import { PanelStyled } from '../../../../common/configuration';
import { breadCrumbConfig } from '../../../promotion/config';
import { useIncentiveController } from '../Controllers/useIncentive.controller';
import { buttonConfig, formConfig } from './config';
import { IncentiveStyled } from './Incentive.style';
import { TableBody, TableHeader } from './tableConfig';
import BulkComponent from '../../NewComponent/BulkComponent';
import withAlert from '../../../../../utils/composition/withAlert';

const Incentive = ({ ...props }) => {
  const {
    handleIconClick,
    dialog,
    onDialogSubmit,
    onCloseDialog,
    filterMenu,
    filters,
    handleFilterChange,
    handleSearchInputChange,
    handleDateRangeChange,
    handleTableSorting,
    handlePageChange,
    sort,
    load,
    pagination,
    data,
    permission,
    handleTableRowClick,
    handleCreate,
    handleBulkDelete,
    nameClass,
  } = useIncentiveController({ ...props });
  return (
    <IncentiveStyled>
      {load && <Loading title="Loading Details" />}
      {dialog.type && (
        <DialogFormWrapper
          type={dialog.type}
          dialogElement={dialog.element}
          onDialogSubmit={onDialogSubmit}
          formConfig={formConfig[dialog?.type]}
          onDialogCancel={onCloseDialog}
          activeStatus
          renderDialog={({ }) => (
            <>
              {dialog?.type === EVENT_OPERATION?.DELETE && (
                <>
                  <span>Are you sure you want to delete 1 record?</span>
                </>
              )}
            </>
          )}
        />
      )}
      <PanelStyled>
        <PageHeader
          breadCrumb={breadCrumbConfig}
          config={{
            title: PROGRAM.INCENTIVE,
            create: permission?.create,
            download: false,
            filter: false,
            date: true,
            search: false,
          }}
          downloadConfig={{ domain: PROGRAM?.INCENTIVE }}
          filter={{
            menuList: filterMenu,
            onFilterChange: handleFilterChange,
            date: filters?.date,
            query: filters?.queryString,
          }}
          handleCreateClick={handleCreate}
          queryParameters={{ search: filters?.queryString }}
          handleSearchChange={handleSearchInputChange}
          handleDateRangeChange={handleDateRangeChange}
        />
      </PanelStyled>
      <div className="section-content table-present ">
        <BulkComponent
          data={data?.rows}
          receiveType={""}
          handleFormSubmit={handleBulkDelete}
          buttonToRender={buttonConfig}
          showBulk
          dialogTitle={`Delete ${PROGRAM.INCENTIVE}`}
          showConfirmDialog={true}
          dialogPopupText={`Are you sure you want to delete these ${PROGRAM.INCENTIVE}.?`}
          dialogSubmitButtonName={"Delete All"}
          actionButtonClassName="incentive-button"
          handleDialogClose={onCloseDialog}
          render={(
            checkedList: number[],
            primaryCheckboxHandler: (
              e: ChangeEvent<HTMLInputElement>
            ) => void,
            secondaryCheckboxHandler: (id: any) => void,
            showOptionButton: boolean
          ) => (
            <div className={`table-wrap ${nameClass}`}>
              <table>
                {TableHeader({
                  dataListLength: data?.rows?.length,
                  checkedListLength: checkedList?.length,
                  primaryCheckboxHandler,
                  permission,
                  handleTableSorting,
                  sort,
                })}
                <tbody>
                  {data?.rows?.map?.(user =>
                    TableBody({
                      handleTableRowClick,
                      secondaryCheckboxHandler,
                      checkedList,
                      data: user,
                      permission,
                      handleIconClick,
                      showOptionButton,
                    }),
                  )}
                </tbody>
              </table>
            </div>
          )}
        />
        <Pagination
          currentPage={pagination?.page}
          limit={pagination?.limit}
          totalItems={data?.count}
          onPageChange={handlePageChange}
        />
      </div>
    </IncentiveStyled>
  );
};
const viewWithErrorAndLoading = withErrorAndLoadingScreen(Incentive);
export default withAlert()(viewWithErrorAndLoading);
