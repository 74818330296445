import { useState, useEffect } from 'react';

export const useTableConfigController = (data: any[], handleIconClick: Function) => {
  const [selectedItems, setSelectedItems] = useState([]);

  const handleRowSelect = (groupId: number, itemId: number) => {
    setSelectedItems(prev => {
      const exists = prev.some(item => item?.groupId === groupId && item.itemId === itemId);
      if (exists) {
        return prev.filter(item => item?.groupId !== groupId || item?.itemId !== itemId);
      } else {
        return [...prev, { groupId, itemId }];
      }
    });
  };

  const handleBulkDelete = () => {
    selectedItems.forEach(({ groupId, itemId }) => {
      const item = data.flatMap(group => group.items).find(item => item.groupId === groupId && item.itemId === itemId);
      if (item) {
        handleIconClick?.({ type: 'BULK_DELETE', data: item });
      }
    });
    // setSelectedItems([]);
  };

  const handleSelectAll = () => {
    const allItems = data.flatMap(group =>
      group.items.map(item => ({ groupId: group.groupId, itemId: item.itemId, measure: group?.measure })),
    );
    if (selectedItems.length === allItems.length) {
      setSelectedItems([]);
    } else {
      setSelectedItems(allItems);
    }
  };

  const isAllSelected =
    data?.length > 0
      ? data
          .flatMap(group => group.items)
          .every(item =>
            selectedItems.some(selected => selected.groupId === item.groupId && selected.itemId === item.itemId),
          )
      : false;

  useEffect(() => {
    if (selectedItems.length === 0) {
      setSelectedItems([]);
    }
  }, []);
  return {
    selectedItems,
    setSelectedItems,
    isAllSelected,
    handleRowSelect,
    handleBulkDelete,
    handleSelectAll,
  };
};
