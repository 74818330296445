import * as userService from './user';
import * as subDService from './subD';
import * as routeService from './route';
import * as catalogService from './catalog';
import * as channelService from './channel';
import * as universeService from './universe';
import * as permissionService from './permission';
import * as merchandiseDeviceService from './merchandiseDevices';
import { CATALOG_LEVEL } from '../enums/Catalog';
import { apiFilterProcessor } from '../../utils/api';
import { USER_ROLE } from '../enums';
import { getBankFromServer } from './bank';
import { INCENTIVE } from '../enums/Route';
import { getUserRoleList, mapFilterData } from '../../views/salesForce/Incentive/NewComponent/GlobalFilter';
import { FILTER } from '../enums/filter';

const STORAGE_TABLE = {
  SUB_D: 'sub_d',
  PERMISSION: 'permission',
  CATALOG: 'catalog',
  CATALOG_CHILDREN: 'catalog_children',
  CHANNEL: 'channel',
  CATEGORY: 'category',
  CHANNEL_CATEGORY: 'channel_category',
  TOWN: 'town',
  USER: 'user',
  DSE: 'dse',
  USER_GROUP: 'user_role',
  USER_ROLE: 'user_role',
  TERRITORY: 'territory',
  PRINCIPAL: 'principal',
  PRODUCT_GROUP: 'product_group',
  SKU_FAMILY: 'sku_family',
  BRAND: 'brand',
  SUB_BRAND: 'sub_brand',
  SUB_BRAND_FORM: 'sub_brand_form',
  MARKET_CATALOG: 'market_catalog',
  ROUTE: 'route',
  ROUTE_VISIT_FREQUENCY: 'route_visit_frequency',
  ROUTE_VISIT_CATEGORY: 'route_visit_category',
  ROUTE_VISIT_TYPE: 'route_visit_type',
  SKU: 'sku',
  ACTIVE: 'active',
  LEAVE_STATUS: 'leave_status',
  LEAVE_TYPE: 'leave_type',
  BUSINESS_TYPE: 'business_type',
  PROMOTION_TYPE: 'promotion_type',
  ASM: 'asm',
  STL: 'stl',
  ZONE: 'zone',
  DSE_TAS: 'dse_tas',
  BRANCH_HEAD: 'branch_head',
  PAYMENT_STATUS: 'payment_status',
  COLLECTION_TYPE: 'collection_type',
  AMOUNT: 'amount',
  BANK: 'bank',
  HIERARCHY_STATUS: 'status',
  OUTSTANDINGS_STATUS: 'transaction_status',
  OSR_STATUS: 'current_status',
  MESSAGING_PLATFORM: 'platform',
  REQUEST_STATUS_LIST: 'request_status',
  MERCHANDISE_DEVICE: 'merchandise_device',
  POWER_SKU: 'power_sku',
  TAS_USER: 'tas_user',
  TAS: 'tas',
  DSE_OUTLET: 'dse_outlet',
  COLLECTOR: 'collector',
};

const statusList = [
  {
    id: 'true',
    title: 'Active',
  },
  {
    id: 'false',
    title: 'Inactive',
  },
];
const messagingPlatform = [
  {
    id: 'SMS',
    title: 'SMS',
  },
  {
    id: 'NOTIFICATION',
    title: 'NOTIFICATION',
  },
  {
    id: 'VIBER',
    title: 'VIBER',
  },
  {
    id: 'WHATSAPP',
    title: 'WHATSAPP',
  },
];

const hierarchyList = [
  {
    id: 1,
    title: 'Active',
  },
  {
    id: 0,
    title: 'Inactive',
  },
  {
    id: 2,
    title: 'Onleave',
  },
];

const leaveStatusList = [
  {
    id: 'PENDING',
    title: 'PENDING',
  },
  {
    id: 'APPROVED',
    title: 'APPROVED',
  },
  {
    id: 'REJECTED',
    title: 'REJECTED',
  },
  {
    id: 'VERIFIED',
    title: 'VERIFIED',
  },
];
const requestStatusList = [
  {
    id: 'PENDING',
    title: 'PENDING',
  },
  {
    id: 'APPROVED',
    title: 'APPROVED',
  },
  {
    id: 'REJECTED',
    title: 'REJECTED',
  },
];

const leaveTypeList = [
  {
    id: 'SICK',
    title: 'SICK',
  },
  {
    id: 'CASUAL',
    title: 'CASUAL',
  },
  {
    id: 'PATERNITY',
    title: 'PATERNITY',
  },
  {
    id: 'MATERNITY',
    title: 'MATERNITY',
  },
  {
    id: 'BEREAVEMENT',
    title: 'BEREAVEMENT',
  },
  {
    id: 'PERSONAL',
    title: 'PERSONAL',
  },
  {
    id: 'PAID',
    title: 'PAID',
  },
];

const promotionType = [
  {
    id: 'NORMAL',
    title: 'NORMAL',
  },
  {
    id: 'CURRENT_BILL',
    title: 'CURRENT BILL',
  },
  {
    id: 'TOP_UP',
    title: 'TOP UP',
  },
  // {
  //   id: 'NEXT_BILL',
  //   title: 'NEXT BILL',
  // },
  // {
  //   id: 'DIRECT_REIMBURSEMENT',
  //   title: 'DIRECT REIMBURSEMENT',
  // },
];

const paymentStatusList = [
  {
    id: 'RECEIVED',
    title: 'RECEIVED',
  },
  {
    id: 'PENDING',
    title: 'PENDING',
  },
  {
    id: 'CHEQUE_RECEIVED',
    title: 'CHEQUE RECEIVED',
  },
  {
    id: 'DISCARDED',
    title: 'DISCARDED',
  },
  {
    id: 'RECONCILED',
    title: 'RECONCILED',
  },
  {
    id: 'DISHONORED',
    title: 'DISHONORED',
  },
];

const collectionType = [
  {
    id: 'CASH',
    title: 'CASH',
  },
  {
    id: 'CHEQUE',
    title: 'CHEQUE',
  },
  {
    id: 'POST_DATED_CHEQUE',
    title: 'POST DATED CHEQUE',
  },
  {
    id: 'ONLINE_QR',
    title: 'ONLINE QR',
  },
];

const amountList = [
  {
    id: 'above',
    title: 'Above 50,000',
  },
  {
    id: 'below',
    title: 'Below 50,000',
  },
];

const outstandingsStatusList = [
  {
    id: 'IN_PROGRESS',
    title: 'IN PROGRESS',
  },
  {
    id: 'STOPPED',
    title: 'STOPPED',
  },
  {
    id: 'BLACK_LISTED',
    title: 'BLACKLISTED',
  },
];
const osrStatusList = [
  {
    id: 'INVOICED',
    title: 'INVOICED',
  },
  {
    id: 'RECEIVED',
    title: 'RECEIVED',
  },
  {
    id: 'DISPATCHED',
    title: 'DISPATCHED',
  },
  {
    id: 'DELIVERED',
    title: 'DELIVERED',
  },
  {
    id: 'CANCELLED',
    title: 'CANCELLED',
  },
];

const powerSKUList = [
  {
    id: 'true',
    title: 'True',
  },
  {
    id: 'false',
    title: 'False',
  },
];

const getDataFromLocalStorage = async (label, value = null) => {
  try {
    let dataList;
    switch (label) {
      case STORAGE_TABLE.SUB_D:
        // const response = await getSubDList();
        // eslint-disable-next-line no-case-declarations
        dataList = await subDService.getSubDList();
        break;
      case FILTER.USER_GROUP?.LABEL:
        const list = await getUserRoleList();
        dataList = list?.map(({ id, name }) => ({ id, title: name }));
        break;
      case STORAGE_TABLE.PERMISSION:
        dataList = await permissionService.getPermission().then(re => re);
        break;
      case STORAGE_TABLE.CATALOG:
        dataList = await catalogService.getCatalog().then(te => te);
        break;
      case STORAGE_TABLE.CATALOG_CHILDREN:
        dataList = await catalogService.getCatalogChildren(value);
        break;
      case STORAGE_TABLE.PRINCIPAL:
        dataList = await catalogService.getCatalogChildren(CATALOG_LEVEL.PRINCIPAL);
        break;
      case STORAGE_TABLE.PRODUCT_GROUP:
        dataList = await catalogService.getCatalogChildren(CATALOG_LEVEL.PRODUCT_GROUP);
        break;
      case STORAGE_TABLE.BRAND:
        dataList = await catalogService.getCatalogChildren(CATALOG_LEVEL.BRAND);
        break;
      case STORAGE_TABLE.SUB_BRAND:
        dataList = await catalogService.getCatalogChildren(CATALOG_LEVEL.SUB_BRAND);
        break;
      case STORAGE_TABLE.SUB_BRAND_FORM:
        dataList = await catalogService.getCatalogChildren(CATALOG_LEVEL.SUB_BRAND_FORM);
        break;
      case STORAGE_TABLE.MARKET_CATALOG:
        dataList = await catalogService.getCatalogChildren(CATALOG_LEVEL.MARKET_CATALOG);
        break;
      case STORAGE_TABLE.SKU_FAMILY:
        dataList = await catalogService.getCatalogChildren(CATALOG_LEVEL.SKU_FAMILY);
        break;
      case STORAGE_TABLE.SKU:
        dataList = await catalogService.getCatalogChildren(CATALOG_LEVEL.SKU_CATALOG_LEVEL);
        break;
      case STORAGE_TABLE.CHANNEL:
        dataList = await channelService.getChannel();
        break;
      case STORAGE_TABLE.CATEGORY:
        dataList = await channelService.getCategory();
        break;
      case STORAGE_TABLE.TOWN:
        dataList = await universeService.getTown();
        break;
      case STORAGE_TABLE.USER:
        dataList = await userService.getUserList();
        break;
      case STORAGE_TABLE.USER_ROLE:
        dataList = await userService.getUserRole();
        break;
      case STORAGE_TABLE.TERRITORY:
        dataList = await universeService.getTerritory();
        break;
      case STORAGE_TABLE.ROUTE:
        dataList = await routeService.getRouteList();
        break;
      case STORAGE_TABLE.ROUTE_VISIT_FREQUENCY:
        dataList = await routeService.getRouteVisitFrequencyList();
        break;
      case STORAGE_TABLE.ROUTE_VISIT_TYPE:
        dataList = await routeService.getRouteVisitTypeList();
        break;
      case STORAGE_TABLE.ROUTE_VISIT_CATEGORY:
        dataList = await routeService.getRouteVisitCategoryList();
        break;
      case STORAGE_TABLE.ACTIVE:
        dataList = statusList;
        break;
      case STORAGE_TABLE.MESSAGING_PLATFORM:
        dataList = messagingPlatform;
        break;
      case STORAGE_TABLE.HIERARCHY_STATUS:
        dataList = hierarchyList;
        break;
      case STORAGE_TABLE.LEAVE_STATUS:
        dataList = leaveStatusList;
        break;
      case STORAGE_TABLE.REQUEST_STATUS_LIST:
        dataList = requestStatusList;
        break;
      case STORAGE_TABLE.LEAVE_TYPE:
        dataList = leaveTypeList;
        break;
      case STORAGE_TABLE.BUSINESS_TYPE:
        dataList = channelService.getBusinessType();
        break;
      case STORAGE_TABLE.PROMOTION_TYPE:
        dataList = promotionType;
        break;
      case STORAGE_TABLE.OSR_STATUS:
        dataList = osrStatusList;
        break;
      case STORAGE_TABLE.ASM:
        dataList = await userService.getUserList({
          filters: apiFilterProcessor({ role_id: [USER_ROLE.ASM] }),
        });
        break;
      case STORAGE_TABLE.STL:
        dataList = await userService.getUserList({
          filters: apiFilterProcessor({ role_id: [USER_ROLE.STL] }),
        });
        break;
      case STORAGE_TABLE.ZONE:
        dataList = await universeService.getZone();
        break;
      case STORAGE_TABLE.DSE:
        dataList = await userService.getUserList({
          filters: apiFilterProcessor({ role_id: [USER_ROLE.DSE] }),
        });
        break;

      case STORAGE_TABLE.DSE_OUTLET:
        dataList = await userService.getUserList({
          filters: apiFilterProcessor({
            role_id: [USER_ROLE.DSE],
            distributor_id: [value],
          }),
        });
        break;
      case STORAGE_TABLE.TAS:
        dataList = await userService.getUserList({
          filters: apiFilterProcessor({ role_id: [USER_ROLE.TAS] }),
        });
        break;
      case STORAGE_TABLE.DSE_TAS:
        dataList = await userService.getUserList({
          filters: apiFilterProcessor({
            role_id: [USER_ROLE.DSE, USER_ROLE.TAS],
          }),
        });
        break;
      case STORAGE_TABLE.TAS_USER:
        const { id, roleId } = JSON.parse(localStorage.getItem('rosia-detail'));

        let filters = [
          {
            column: 'role_id',
            value: [USER_ROLE.TAS.toString()],
          },
        ];

        if (roleId === 14) {
          filters.push({
            column: 'parent_user_id',
            value: [id.toString()],
          });
        }
        dataList = await userService.getUserList({
          filters: filters,
        });

        break;
      case STORAGE_TABLE.BRANCH_HEAD:
        dataList = await userService.getUserList({
          filters: apiFilterProcessor({
            role_id: [USER_ROLE.DSE, USER_ROLE.TAS, USER_ROLE.BRANCH_HEAD],
          }),
        });
        break;
      case STORAGE_TABLE.PAYMENT_STATUS:
        dataList = paymentStatusList;
        break;
      case STORAGE_TABLE.COLLECTION_TYPE:
        dataList = collectionType;
        break;
      case STORAGE_TABLE.AMOUNT:
        dataList = amountList;
        break;
      case STORAGE_TABLE.POWER_SKU:
        dataList = powerSKUList;
        break;
      case STORAGE_TABLE.BANK:
        dataList = await getBankFromServer();
        break;
      case STORAGE_TABLE.OUTSTANDINGS_STATUS:
        dataList = outstandingsStatusList;
        break;
      case STORAGE_TABLE.MERCHANDISE_DEVICE:
        dataList = await merchandiseDeviceService.getMerchandiseDeviceList();
        break;
      case STORAGE_TABLE.MERCHANDISE_DEVICE:
        dataList = await merchandiseDeviceService.getMerchandiseDeviceList();
        break;
      case STORAGE_TABLE.COLLECTOR:
        dataList = await userService.getUserList({
          filters: apiFilterProcessor({ role_id: [USER_ROLE.DSE] }),
        });
        break;
      default:
        break;
    }
    return dataList;
  } catch (err) {
    throw err;
  }
};

const fetchAndSetDataToLocalStorage = async label => {
  try {
    switch (label) {
      case STORAGE_TABLE.SUB_D:
        await subDService.fetchAndSetSubDList();
        break;
      case STORAGE_TABLE.CATALOG:
        await catalogService.fetchAndSetCatalog();
        break;
      case STORAGE_TABLE.CHANNEL:
        await channelService.fetchAndSetChannel();
        break;
      case STORAGE_TABLE.CATEGORY:
        await channelService.fetchAndSetCategory();
        break;
      case STORAGE_TABLE.CHANNEL_CATEGORY:
        await channelService.fetchAndSetChannelCategory();
        break;
      case STORAGE_TABLE.TOWN:
        await universeService.fetchAndSetTownList();
        break;
      default:
        break;
    }

    return { resolved: true };
  } catch (err) {
    throw err;
  }
};

const loadInitialData = () =>
  Promise.all([
    // userService.fetchAndSetUserList(),
    // userService.fetchAndSetUserGroup(),
    catalogService.fetchAndSetCatalog(),
    // subDService.fetchAndSetSubDList(),
    // channelService.fetchAndSetChannelCategory(),
  ]);

export { loadInitialData, getDataFromLocalStorage, fetchAndSetDataToLocalStorage, STORAGE_TABLE };
