import moment from 'moment';

export const commonApiFunc = async ({ payload, func }: any) => {
  const { api, success, error, mapper } = func || {};
  const { param } = payload || {};
  const payloadOut = mapper?.(payload);
  await api(
    { ...payloadOut },
    {
      handleSuccess: (res: any) => {
        if (res) {
          success?.({ res, param });
        }
      },
      handleError: (err: any) => {
        error?.({ err, param });
      },
    },
  );
};

export const getCurrentMonth = () => moment().month() + 1;
export const getCurrentYear = () => moment().year();
