import orderBy from 'lodash/orderBy';
import { FILTER } from '../data/enums/filter';

const has = Object.prototype.hasOwnProperty;

const objectToArrayConverter = object => Object.keys(object).map(attribute => object[attribute]);

const objectToSortedArray = (object, sortingLABEL = 'title') => {
  const arrayList = objectToArrayConverter(object);
  return orderBy(arrayList, sortingLABEL);
};

const isObjectEmpty = obj => Object.keys(obj).length === 0 && obj.constructor === Object;
// const filterGroups = ['asm', 'stl', 'dse', 'channel', 'category'];

const baseConfig = {
  index: 0,
  active: false,
  selectedIdList: [],
  selectedItemList: [],
};

const config = {
  [FILTER.USER_OPTION.LABEL]: {
    title: FILTER.USER_OPTION.TITLE,
    label: FILTER.USER_OPTION.LABEL,
    value: FILTER.USER_OPTION.VALUE,
    ...baseConfig,
  },
  [FILTER.TAS_USER.LABEL]: {
    title: FILTER.TAS_USER.TITLE,
    label: FILTER.TAS_USER.LABEL,
    value: FILTER.TAS_USER.VALUE,
    ...baseConfig,
  },
  [FILTER.TAS.LABEL]: {
    title: FILTER.TAS.TITLE,
    label: FILTER.TAS.LABEL,
    value: FILTER.TAS.VALUE,
    ...baseConfig,
  },
  [FILTER.CALL_STATUS.LABEL]: {
    title: FILTER.CALL_STATUS.TITLE,
    label: FILTER.CALL_STATUS.LABEL,
    value: FILTER.CALL_STATUS.VALUE,
    ...baseConfig,
  },
  [FILTER.PERIOD.LABEL]: {
    title: FILTER.PERIOD.TITLE,
    label: FILTER.PERIOD.LABEL,
    value: FILTER.PERIOD.VALUE,
    ...baseConfig,
  },
  [FILTER.SUB_D.LABEL]: {
    title: FILTER.SUB_D.TITLE,
    label: FILTER.SUB_D.LABEL,
    value: FILTER.SUB_D.VALUE,
    ...baseConfig,
  },
  [FILTER.CHANNEL.LABEL]: {
    title: FILTER.CHANNEL.TITLE,
    label: FILTER.CHANNEL.LABEL,
    value: FILTER.CHANNEL.VALUE,
    ...baseConfig,
  },
  [FILTER.CATEGORY.LABEL]: {
    title: FILTER.CATEGORY.TITLE,
    label: FILTER.CATEGORY.LABEL,
    value: FILTER.CATEGORY.VALUE,
    ...baseConfig,
  },
  [FILTER.PRINCIPAL.LABEL]: {
    title: FILTER.PRINCIPAL.TITLE,
    label: FILTER.PRINCIPAL.LABEL,
    value: FILTER.PRINCIPAL.VALUE,
    ...baseConfig,
  },
  [FILTER.PRODUCT_GROUP.LABEL]: {
    title: FILTER.PRODUCT_GROUP.TITLE,
    label: FILTER.PRODUCT_GROUP.LABEL,
    value: FILTER.PRODUCT_GROUP.VALUE,
    ...baseConfig,
  },
  [FILTER.BRAND.LABEL]: {
    title: FILTER.BRAND.TITLE,
    label: FILTER.BRAND.LABEL,
    value: FILTER.BRAND.VALUE,
    ...baseConfig,
  },
  [FILTER.BRANCH_HEAD.LABEL]: {
    title: FILTER.BRANCH_HEAD.TITLE,
    label: FILTER.BRANCH_HEAD.LABEL,
    value: FILTER.BRANCH_HEAD.VALUE,
    ...baseConfig,
  },
  [FILTER.SUB_BRAND.LABEL]: {
    title: FILTER.SUB_BRAND.TITLE,
    label: FILTER.SUB_BRAND.LABEL,
    value: FILTER.SUB_BRAND.VALUE,
    ...baseConfig,
  },
  [FILTER.SUB_BRAND_FORM.LABEL]: {
    title: FILTER.SUB_BRAND_FORM.TITLE,
    label: FILTER.SUB_BRAND_FORM.LABEL,
    value: FILTER.SUB_BRAND_FORM.VALUE,
    ...baseConfig,
  },
  [FILTER.MARKET_CATALOG.LABEL]: {
    title: FILTER.MARKET_CATALOG.TITLE,
    label: FILTER.MARKET_CATALOG.LABEL,
    value: FILTER.MARKET_CATALOG.VALUE,
    ...baseConfig,
  },
  [FILTER.SKU_FAMILY.LABEL]: {
    title: FILTER.SKU_FAMILY.TITLE,
    label: FILTER.SKU_FAMILY.LABEL,
    value: FILTER.SKU_FAMILY.VALUE,
    ...baseConfig,
  },
  [FILTER.SKU.LABEL]: {
    title: FILTER.SKU.TITLE,
    label: FILTER.SKU.LABEL,
    value: FILTER.SKU.VALUE,
    ...baseConfig,
  },
  [FILTER.TOWN.LABEL]: {
    title: FILTER.TOWN.TITLE,
    label: FILTER.TOWN.LABEL,
    value: FILTER.TOWN.VALUE,
    ...baseConfig,
  },
  [FILTER.TERRITORY.LABEL]: {
    title: FILTER.TERRITORY.TITLE,
    label: FILTER.TERRITORY.LABEL,
    value: FILTER.TERRITORY.VALUE,
    ...baseConfig,
  },
  [FILTER.USER_ROLE.LABEL]: {
    title: FILTER.USER_ROLE.TITLE,
    label: FILTER.USER_ROLE.LABEL,
    value: FILTER.USER_ROLE.VALUE,
    ...baseConfig,
  },
  [FILTER.ROUTE.LABEL]: {
    title: FILTER.ROUTE.TITLE,
    label: FILTER.ROUTE.LABEL,
    value: FILTER.ROUTE.VALUE,
    ...baseConfig,
  },
  [FILTER.ROUTE_VISIT_FREQUENCY.LABEL]: {
    title: FILTER.ROUTE_VISIT_FREQUENCY.TITLE,
    label: FILTER.ROUTE_VISIT_FREQUENCY.LABEL,
    value: FILTER.ROUTE_VISIT_FREQUENCY.VALUE,
    ...baseConfig,
  },
  [FILTER.ROUTE_VISIT_CATEGORY.LABEL]: {
    title: FILTER.ROUTE_VISIT_CATEGORY.TITLE,
    label: FILTER.ROUTE_VISIT_CATEGORY.LABEL,
    value: FILTER.ROUTE_VISIT_CATEGORY.VALUE,
    ...baseConfig,
  },
  [FILTER.ROUTE_VISIT_TYPE.LABEL]: {
    title: FILTER.ROUTE_VISIT_TYPE.TITLE,
    label: FILTER.ROUTE_VISIT_TYPE.LABEL,
    value: FILTER.ROUTE_VISIT_TYPE.VALUE,
    ...baseConfig,
  },
  [FILTER.STATUS.LABEL]: {
    title: FILTER.STATUS.TITLE,
    label: FILTER.STATUS.LABEL,
    value: FILTER.STATUS.VALUE,
    ...baseConfig,
  },
  [FILTER.MESSAGING_PLATFORM.LABEL]: {
    title: FILTER.MESSAGING_PLATFORM.TITLE,
    label: FILTER.MESSAGING_PLATFORM.LABEL,
    value: FILTER.MESSAGING_PLATFORM.VALUE,
    ...baseConfig,
  },
  [FILTER.HIERARCHY_STATUS.LABEL]: {
    title: FILTER.HIERARCHY_STATUS.TITLE,
    label: FILTER.HIERARCHY_STATUS.LABEL,
    value: FILTER.HIERARCHY_STATUS.VALUE,
    ...baseConfig,
  },
  [FILTER.LEAVE_STATUS.LABEL]: {
    title: FILTER.LEAVE_STATUS.TITLE,
    label: FILTER.LEAVE_STATUS.LABEL,
    value: FILTER.LEAVE_STATUS.VALUE,
    ...baseConfig,
  },
  [FILTER.LEAVE_TYPE.LABEL]: {
    title: FILTER.LEAVE_TYPE.TITLE,
    label: FILTER.LEAVE_TYPE.LABEL,
    value: FILTER.LEAVE_TYPE.VALUE,
    ...baseConfig,
  },
  [FILTER.BUSINESS_TYPE.LABEL]: {
    title: FILTER.BUSINESS_TYPE.TITLE,
    label: FILTER.BUSINESS_TYPE.LABEL,
    value: FILTER.BUSINESS_TYPE.VALUE,
    ...baseConfig,
  },
  [FILTER.PROMOTION_TYPE.LABEL]: {
    title: FILTER.PROMOTION_TYPE.TITLE,
    label: FILTER.PROMOTION_TYPE.LABEL,
    value: FILTER.PROMOTION_TYPE.VALUE,
    ...baseConfig,
  },
  [FILTER.DSE.LABEL]: {
    title: FILTER.DSE.TITLE,
    label: FILTER.DSE.LABEL,
    value: FILTER.DSE.VALUE,
    ...baseConfig,
  },
  [FILTER.ASM.LABEL]: {
    title: FILTER.ASM.TITLE,
    label: FILTER.ASM.LABEL,
    value: FILTER.ASM.VALUE,
    ...baseConfig,
  },
  [FILTER.STL.LABEL]: {
    title: FILTER.STL.TITLE,
    label: FILTER.STL.LABEL,
    value: FILTER.STL.VALUE,
    ...baseConfig,
  },
  [FILTER.ZONE.LABEL]: {
    title: FILTER.ZONE.TITLE,
    label: FILTER.ZONE.LABEL,
    value: FILTER.ZONE.VALUE,
    ...baseConfig,
  },
  [FILTER.DSE_TAS.LABEL]: {
    title: FILTER.DSE_TAS.TITLE,
    label: FILTER.DSE_TAS.LABEL,
    value: FILTER.DSE_TAS.VALUE,
    ...baseConfig,
  },
  [FILTER.DSE_OUTLET.LABEL]: {
    title: FILTER.DSE_OUTLET.TITLE,
    label: FILTER.DSE_OUTLET.LABEL,
    value: FILTER.DSE_OUTLET.VALUE,
    ...baseConfig,
  },
  [FILTER.PAYMENT_STATUS.LABEL]: {
    title: FILTER.PAYMENT_STATUS.TITLE,
    label: FILTER.PAYMENT_STATUS.LABEL,
    value: FILTER.PAYMENT_STATUS.VALUE,
    ...baseConfig,
  },
  [FILTER.COLLECTION_TYPE.LABEL]: {
    title: FILTER.COLLECTION_TYPE.TITLE,
    label: FILTER.COLLECTION_TYPE.LABEL,
    value: FILTER.COLLECTION_TYPE.VALUE,
    ...baseConfig,
  },
  [FILTER.AMOUNT.LABEL]: {
    title: FILTER.AMOUNT.TITLE,
    label: FILTER.AMOUNT.LABEL,
    value: FILTER.AMOUNT.VALUE,
    ...baseConfig,
  },
  [FILTER.BANK.LABEL]: {
    title: FILTER.BANK.TITLE,
    label: FILTER.BANK.LABEL,
    value: FILTER.BANK.VALUE,
    ...baseConfig,
  },
  [FILTER.OUTSTANDINGS_STATUS.LABEL]: {
    title: FILTER.OUTSTANDINGS_STATUS.TITLE,
    label: FILTER.OUTSTANDINGS_STATUS.LABEL,
    value: FILTER.OUTSTANDINGS_STATUS.VALUE,
  },
  [FILTER.OSR_STATUS.LABEL]: {
    title: FILTER.OSR_STATUS.TITLE,
    label: FILTER.OSR_STATUS.LABEL,
    value: FILTER.OSR_STATUS.VALUE,
    ...baseConfig,
  },
  [FILTER.REQUESTS_STATUS.LABEL]: {
    title: FILTER.REQUESTS_STATUS.TITLE,
    label: FILTER.REQUESTS_STATUS.LABEL,
    value: FILTER.REQUESTS_STATUS.VALUE,
    ...baseConfig,
  },
  [FILTER.MERCHANDISE_DEVICE.LABEL]: {
    title: FILTER.MERCHANDISE_DEVICE.TITLE,
    label: FILTER.MERCHANDISE_DEVICE.LABEL,
    value: FILTER.MERCHANDISE_DEVICE.VALUE,
    ...baseConfig,
  },
  [FILTER.POWER_SKU.LABEL]: {
    title: FILTER.POWER_SKU.TITLE,
    label: FILTER.POWER_SKU.LABEL,
    value: FILTER.POWER_SKU.VALUE,
    ...baseConfig,
  },
  [FILTER.COLLECTOR.LABEL]: {
    title: FILTER.COLLECTOR.TITLE,
    label: FILTER.COLLECTOR.LABEL,
    value: FILTER.COLLECTOR.VALUE,
    ...baseConfig,
  },
  [FILTER.USER_GROUP.LABEL]: {
    title: FILTER.USER_GROUP.TITLE,
    label: FILTER.USER_GROUP.LABEL,
    value: FILTER.USER_GROUP.VALUE,
    ...baseConfig,
  },
};

const getFilterMenu = filterGroups => {
  const filterConfig = {};
  for (let count = 0; count < filterGroups.length; count += 1) {
    const LABELName = filterGroups[count];

    if (config[LABELName]) {
      filterConfig[LABELName] = { ...config[LABELName] };
    }
  }

  return filterConfig;
};

const getFilterConfig = filterList => {
  const instance = {};
  const labelList = filterList.map(element => element.LABEL);
  const menu = getFilterMenu(labelList);
  filterList.forEach(element => (instance[element.VALUE] = {}));
  return {
    menu,
    instance,
  };
};

const generateFilterValue = fieldValue => {
  const keysLIst = Object.keys(fieldValue);

  // {distributor_id: [1, 2, 3 ]}

  const filterObject = {};
  keysLIst.forEach(filterKey => {
    if (filterKey !== 'userOption') {
      const baseFilter = fieldValue[filterKey];
      filterObject[baseFilter.value] = baseFilter.selectedIdList || [];
    }
  });

  return filterObject;
};

export { has, config, getFilterMenu, getFilterConfig, generateFilterValue };
