import { FORM_CONFIG } from '../../../../../data/enums/config';
import EVENT_OPERATION from '../../../../../data/enums/EventOperation';
import { PROGRAM } from '../../../../../data/enums/Permission';
import { INCENTIVE, salesForce } from '../../../../../data/enums/Route';
import { getSBDDynamicTitleFromSetting } from '../../../../configuration/sbd/config';
import { getCurrentMonth, getCurrentYear } from '../../List/DataMapper/helperFunction';

export const defaultStatusFilterData = [
  {
    column: 'active',
    value: ['true'],
  },
];

export const initialDialog = {
  type: '',
  element: null,
};

export const TABLE_CONFIG = {
  CREATE: 'TABLE_CREATE',
  UPDATE: 'TABLE_UPDATE',
  DELETE: 'TABLE_DELETE',
  BULK_DELETE: 'BULK_DELETE',
};
export const formConfig = {
  [EVENT_OPERATION.CREATE]: {
    [FORM_CONFIG.TITLE]: 'Create',
    validationRequired: false,
  },
  [EVENT_OPERATION.UPDATE]: {
    [FORM_CONFIG.TITLE]: 'Update',
    validationRequired: false,
  },
  [TABLE_CONFIG.CREATE]: {
    [FORM_CONFIG.TITLE]: 'Add KPI',
    buttonName: 'Add',
    validationRequired: false,
  },
  [TABLE_CONFIG.UPDATE]: {
    [FORM_CONFIG.TITLE]: 'Update KPI',
    buttonName: 'Update',
    validationRequired: false,
  },
  [TABLE_CONFIG.DELETE]: {
    [FORM_CONFIG.TITLE]: 'Delete KPI',
    validationRequired: false,
  },
  [TABLE_CONFIG.BULK_DELETE]: {
    [FORM_CONFIG.TITLE]: 'Bulk Delete KPI',
    validationRequired: false,
  },
};

export const initialDialogItems = {
  measure: null,
  rangeFromValueType: null,
  rangeFromValueCompare: null,
  rangeFromValue: null,
  rangeToValueType: null,
  rangeToValueCompare: null,
  rangeToValue: null,
  amount: null,
  itemId: null,
};
export const initialSummaryObj = {
  userGroup: null,
  month: { month: getCurrentMonth(), year: getCurrentYear() },
};
const incentive = {
  id: 1,
  title: PROGRAM.INCENTIVE,
  path: `/${INCENTIVE}`,
  active: true,
};
export const breadCrumb = [salesForce, incentive];
export const ERR_VALIDATION = {
  LENGTH_ERR: 'At least one KPI is required.',
};

export const rangeValueCompareListFrom = [
  {
    id: 1,
    value: 'GREATER_THAN',
    title: '>',
  },
  {
    id: 3,
    value: 'GREATER_THAN_OR_EQUAL',
    title: '>=',
  },
  {
    id: 5,
    value: 'EQUAL',
    title: '=',
  },
];

export const rangeValueCompareListTo = [
  {
    id: 2,
    value: 'LESS_THAN',
    title: '<',
  },
  {
    id: 4,
    value: 'LESS_THAN_OR_EQUAL',
    title: '<=',
  },
  {
    id: 5,
    value: 'EQUAL',
    title: '=',
  },
  {
    id: 1,
    value: 'GREATER_THAN',
    title: '>',
  },
  {
    id: 3,
    value: 'GREATER_THAN_OR_EQUAL',
    title: '>=',
  },
];
export const rangeValueTypeList = [
  { id: 1, type: 'in %', label: '%', value: 'PERCENTAGE' },
  { id: 2, type: 'in Rs', label: 'Rs', value: 'NRS' },
];

export const getMeasureList = () => {
  const SBDTitle = getSBDDynamicTitleFromSetting();
  const measureList = [
    { id: 1, title: 'Value Target', value: 'Value Target' },
    { id: 2, title: SBDTitle ?? 'SBD', value: 'SBD' },
    { id: 3, title: 'Perfect Call', value: 'Perfect Call' },
    { id: 4, title: 'Productivity', value: 'Productivity' },
    { id: 5, title: 'Active Coverage', value: 'Active Coverage' },
  ];
  return measureList;
};
