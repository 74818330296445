import { compose } from 'recompose';
import withRequestTracker from '../../../../utils/composition/withRequestTracker';
import {
    DOWNLOAD_USER_REPORT,
    mutation,
    query,
    UPDATE_USERS
} from './Repository/Incentive.remote';
import Incentive from './View';
import withAlert from '../../../../utils/composition/withAlert';

const ComposedIncentive = compose(
    UPDATE_USERS,
    DOWNLOAD_USER_REPORT,
    withRequestTracker({
        query,
        mutation,
    }),
)(Incentive);

export default withAlert()(ComposedIncentive)
