import { FC } from 'react';
import styled from 'styled-components';
import { Button, Icon, Menu } from '..';

const Header = (
  <Button
    iconName="ellipsis-v"
    onClick={e => {
      e.preventDefault();
    }}
  />
);

const Options = ({ menuConfig, data }) => {
  return (
    <Menu header={Header}>
      <div className="filter-content text-left">
        {menuConfig.map(menu => (
          <ul
            key={`${menu.title}__${menu.icon}`}
            onClick={e => {
              e.preventDefault();
              menu.onClick(data);
            }}
          >
            {menu.permission && (
              <li>
                <OptionTitle>
                  {menu.icon && <Icon iconName={menu.icon} />}
                  {menu.title}
                </OptionTitle>
              </li>
            )}
          </ul>
        ))}
      </div>
    </Menu>
  );
};

const OptionTitle = styled.span`
  .icon {
    margin-right: 1.2rem;
  }
`;
export default Options;
