import { compose } from 'recompose';
import Incentive from './Incentive.view';
import withRequestTracker from '../../../../../utils/composition/withRequestTracker';
import { query, mutation, DELETE_INCENTIVE } from '../Repository/Incentive.remote';

const ComposedDetails = compose(
  DELETE_INCENTIVE,
  withRequestTracker({
    query,
    mutation,
  }),
)(Incentive);

export default ComposedDetails;
