import { FORM_CONFIG } from '../../../../../data/enums/config';
import EVENT_OPERATION from '../../../../../data/enums/EventOperation';
import { PROGRAM } from '../../../../../data/enums/Permission';
import { salesForce } from '../../../../../data/enums/Route';
import { getCurrentDay, getStartOfCurrentMonth } from '../../../../../utils/date';

export const initialDialog = {
  element: {
    active: null,
    id: null,
    name: '',
  },
  type: '',
};

export const defaultStatusFilterData = [
  {
    column: 'active',
    value: ['true'],
  },
];

export const formConfig = {
  [EVENT_OPERATION.DELETE]: {
    [FORM_CONFIG.TITLE]: `Delete ${PROGRAM.INCENTIVE}`,
  },
  [EVENT_OPERATION.BULK_DELETE]: {
    [FORM_CONFIG.TITLE]: `Multiple Delete ${PROGRAM.INCENTIVE}`,
  },
};

export const buttonConfig = {
  id: 1,
  name: '',
  button: [
    {
      name: '',
      identity: '',
      iconName: '',
      buttonProps: { iconName: 'trash' },
    },
  ],
  selectAll: true,
};

export const breadCrumb = [salesForce];

export const defaultDate = {
  start: getStartOfCurrentMonth(),
  end: getCurrentDay(),
};
