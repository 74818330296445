const customerMapper = element => ({
  id: element.id ? element.id || null : element.customerId || null,
  name: element.name ? element.name || '' : element.title || '',
  address: element.address || '',
  panNumber: element.panNumber ? element.panNumber || null : element.panNo || null,
  distributorId: element.distributorId || null,
});

const remarksList = [
  { remark: 'Outlet Closed' },
  { remark: 'No Cash Available' },
  { remark: 'Authorised Person NA' },
  { remark: 'Wrong Order' },
  { remark: 'Issue in Invoice' },
  { remark: 'Outlet Not Found' },
  { remark: 'Restricted Area' },
  { remark: 'Damaged' },
  { remark: 'Expired' },
];

export { customerMapper, remarksList };
