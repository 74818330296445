import { compose } from 'recompose';
import Details from './Details.view';
import { query, mutation, CREATE_INCENTIVE, UPDATE_INCENTIVE } from '../Repository/Details.remote';
import withRequestTracker from '../../../../../utils/composition/withRequestTracker';

const ComposedDetails = compose(
  CREATE_INCENTIVE,
  UPDATE_INCENTIVE,
  withRequestTracker({
    query,
    mutation,
  }),
)(Details);

export default ComposedDetails;
