import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { PanelStyled } from '../../../common/configuration';
import PageHeader from '../../../base/PageHeader';
import { title, breadCrumbConfig } from './config';
import Received from './received';
import { DOWNLOAD_DOMAIN } from '../../../../data/enums/GraphQL';
import withErrorAndLoadingScreen from '../../../../utils/composition/withLoading';
import { dataProps, paginationProps } from '../../../../data/enums/Component';
import { PERMISSION_OBJ } from '../../../../data/enums/Permission';

const propTypes = {
  pending: dataProps.value,
  receive: dataProps.value,
  tableConfig: PropTypes.arrayOf(Object).isRequired,
  pagination: paginationProps.value,
  onPageChange: PropTypes.func,
  permission: PropTypes.instanceOf(Object),
  handleDownloadClick: PropTypes.func.isRequired,
  onButtonClick: PropTypes.func,
  activeTab: PropTypes.number,
  onTabChange: PropTypes.func,
  columnSelectChange: PropTypes.func,
  handleFilterChange: PropTypes.func,
  queryParameters: PropTypes.instanceOf(Object),
  basePaginationService: PropTypes.instanceOf(Object),
};

const defaultProps = {
  activeTab: 0,
  pending: dataProps.defaultValue,
  onPageChange: () => null,
  receive: dataProps.defaultValue,
  pagination: paginationProps.defaultValue,
  permission: PERMISSION_OBJ,
  onButtonClick: () => null,
  onTabChange: () => null,
  columnSelectChange: () => null,
  handleFilterChange: () => null,
  queryParameters: {},
  basePaginationService: {},
};

const View = ({ ...props }) => {
  const {
    tableConfig,
    columnSelectChange,
    receive,
    queryParameters,
    pagination,
    handleFilterChange,
    basePaginationService,
    handleDownloadClick,
    permission,
    filterMenu,
  } = props;
  const tablewithAction = [
    ...tableConfig,
    {
      id: 14,
      label: 'actionHolder',
      title: '',
      show: true,
    },
  ];
  return (
    <Fragment>
      <div className="section-header">
        <PanelStyled>
          <PageHeader
            breadCrumb={breadCrumbConfig}
            config={{
              title,
              create: false,
              download: true,
              filter: true,
              upload: false,
              date: true,
              search: true,
              columSelect: true,
            }}
            downloadConfig={{
              domain: DOWNLOAD_DOMAIN.RECEIPT,
            }}
            filter={{
              date: queryParameters.date,
              menuList: filterMenu,
              onFilterChange: handleFilterChange,
            }}
            labelConfig={tableConfig}
            queryParameters={queryParameters}
            columnSelectChange={columnSelectChange}
            resetFilter={basePaginationService.resetFilter}
            clearSearchText={basePaginationService.clearSearchText}
            handleSearchChange={basePaginationService.handleSearchInputChange}
            handleDateRangeChange={basePaginationService.handleDateRangeChange}
            handleDownloadClick={handleDownloadClick}
          />
        </PanelStyled>
      </div>
      <div className="section-content table-present">
        <div className="create-ui-inner tab-less-height" style={{ padding: '0' }}>
          <Received
            tableConfig={tableConfig}
            receiveds={receive}
            pagination={pagination}
            permission={permission}
            onPageChange={basePaginationService.onPageChange}
          />
        </div>
      </div>
    </Fragment>
  );
};
View.propTypes = propTypes;

View.defaultProps = defaultProps;

export default withErrorAndLoadingScreen(View);
