import { refGenerator } from '../../../../../utils';
import { STATEFUL_ENTITIES } from '../../../../../data/enums/GraphQL';
import { EVENT_OPERATION } from '../../../../../data/enums/EventOperation';
import { MESSAGE_EVENT_OPERATION } from '../../../../../data/enums/SuccessMessage';
import { CONFIRMATION_CONFIG, FORM_CONFIG } from '../../../../../data/enums/config';
import { convertDateToString, getTodaysDate, getTomorrowDate } from '../../../../../utils/date';

const title = 'SBD';

const formMapper = element => ({
  id: element.id || '',
  title: element.title || '',
  end: element.end || convertDateToString(getTomorrowDate()),
  start: element.start || getTodaysDate(),
  active: element.active || true,
});

const requiredList = ['title', 'start', 'end'];

const formConfig = {
  [EVENT_OPERATION.CREATE]: {
    [FORM_CONFIG.MAPPER]: element => formMapper(element || {}),
    [FORM_CONFIG.TITLE]: 'Create',
    [FORM_CONFIG.REFS_OBJ]: refGenerator([...requiredList]),
    [FORM_CONFIG.VALIDATION_REQUIRED]: true,
  },
  [EVENT_OPERATION.UPDATE]: {
    [FORM_CONFIG.MAPPER]: element => formMapper(element || {}),
    [FORM_CONFIG.TITLE]: 'Edit',
    [FORM_CONFIG.REFS_OBJ]: refGenerator([...requiredList]),
    [FORM_CONFIG.VALIDATION_REQUIRED]: true,
  },
  [EVENT_OPERATION.REPLICATE]: {
    [FORM_CONFIG.MAPPER]: element => formMapper(element || {}),
    [FORM_CONFIG.TITLE]: 'Replicate',
    [FORM_CONFIG.REFS_OBJ]: refGenerator([...requiredList]),
    [FORM_CONFIG.VALIDATION_REQUIRED]: true,
  },

  ...CONFIRMATION_CONFIG,
};

const crudRequestConfig = {
  [EVENT_OPERATION.CREATE]: {
    objectMapper: data => {
      const { id, ...modifiedData } = data;
      return { input: modifiedData };
    },
    responseName: 'createSBD',
    message: `${title} ${MESSAGE_EVENT_OPERATION.CREATE}`,
  },
  [EVENT_OPERATION.UPDATE]: {
    objectMapper: data => {
      const { id, ...modifiedData } = data;
      return { input: modifiedData, id: data.id };
    },
    responseName: 'updateSBD',
    message: `${title} ${MESSAGE_EVENT_OPERATION.UPDATE}`,
  },
  [EVENT_OPERATION.REPLICATE]: {
    objectMapper: data => {
      const { id, ...modifiedData } = data;
      return { input: modifiedData, id: data.id };
    },
    responseName: 'replicateSbd',
    message: `${title} replicated successfully`,
  },
  [EVENT_OPERATION.DELETE]: {
    objectMapper: data => ({
      ids: [data.id],
      type: STATEFUL_ENTITIES.SBD,
      active: false,
    }),
    responseName: 'toggleState',
    message: `${title} ${MESSAGE_EVENT_OPERATION.DELETE}`,
  },
  [EVENT_OPERATION.BULK_DELETE]: {
    objectMapper: data => ({
      ids: [data.id],
      type: STATEFUL_ENTITIES.SBD,
      active: false,
    }),
    responseName: 'toggleState',
    message: `${title} ${MESSAGE_EVENT_OPERATION.BULK_DELETE}`,
  },
};

export { formConfig, crudRequestConfig };
