import { Col, Row } from 'react-flexbox-grid'
import { Controller } from 'react-hook-form'
import CustomSelect from '../../NewComponent/Select'
import styled from 'styled-components'
import { MonthPicker } from '../../../../../v4/components'

export const SummaryView = ({ formParam, handleSave, list, setSummaryObj, classes }) => {
  const { handleSubmit, control, errors, reset, clearErrors, watch } = formParam;
  const { userGroupList } = list || {}
  return (
    <SummaryStyled className={classes}>
      <form
        noValidate
        onSubmit={handleSubmit((data: any) => {
          handleSave(data);
        })}
        id="hook-form">
        <Row>
          <Col lg={3}>
            <Controller
              control={control}
              name="userGroup"
              render={({ field: { onChange, value } }) => (
                <>
                  <CustomSelect
                    labelContent="User Group"
                    placeholder="Select"
                    value={value}
                    options={userGroupList}
                    name="measure"
                    getOptionValue={({ id }) => id}
                    getOptionLabel={({ title }) => title}
                    onChange={(val: any) => {
                      onChange(val);
                      setSummaryObj((prevState) => ({
                        ...prevState,
                        userGroup: val,
                      }));
                    }}
                    requiredIcon
                    errorMessage={errors?.userGroup && `${errors?.userGroup.message}`}
                    isError={errors?.userGroup}
                    menuPortalTarget={true && document.querySelector("body")}
                  />
                </>
              )}
            />
          </Col>
          <Col lg={3} className="mb-24">
            <Controller
              control={control}
              name={`month`}
              render={({ field: { onChange, value } }) => (
                <div className="month-year">
                  <label>Month <span className="required">*</span></label>
                  <MonthPicker value={value} onSelect={(val: any) => {
                    onChange(val);
                    setSummaryObj((prevState) => ({
                      ...prevState,
                      month: val,
                    }));
                  }} />
                </div>
              )}
            />
          </Col>
        </Row>
      </form>
    </SummaryStyled>
  )
}

const SummaryStyled = styled.div`
padding: 32px  48px 0 48px ;
input{height:40px;font-size:16px;}
.month-year{
    label{
      display: inline-block;
    margin-bottom: 4px;
    color: #272833;
    font-size: 14px;
    font-weight: 600;
    .required{
      color: red;
    }
    }
    &>div{
      border: 1px solid #e7e7ed;
    background: #f1f2f5;
    min-width: 0;
    padding: 7px 16px;
    display: block;
    width: 100%;
    font-size: 16px;
    line-height: 1.5;
    color: #030913;
    border-radius: 4px;
    .month-view{
      padding:0;
      display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    }
    .ic-dropdown{
      display:none;
    }
  }
  }
  .mb-24{
    margin-bottom:24px !important;
  }
`